import React from "react";

interface BudgetAmountDisplayProps {
  amount_left: number;
  total_allocated: number;
  formatCurrency: (amount: number) => string;
}

const BudgetAmountDisplay: React.FC<BudgetAmountDisplayProps> = ({
  amount_left,
  total_allocated,
  formatCurrency,
}) => (
  <div className="mt-2 text-muted" style={{ fontSize: "0.85em" }}>
    <span>Available Budget: </span>
    <span
      style={{
        color: amount_left > 0 ? "green" : "red",
        fontWeight: "bold",
      }}
    >
      {formatCurrency(amount_left)}
    </span>
    <span> of </span>
    <span>{formatCurrency(total_allocated)}</span>
  </div>
);

export default BudgetAmountDisplay;
