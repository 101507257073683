interface Config {
  apiEndpoint: string;
  access_token: string;
  refresh_token: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  login_code: string;
  profile_img: string;
  stripe_customer_id: string;
  is_paid: string;
}

const config: Config = {
  apiEndpoint:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION || ""
      : process.env.REACT_APP_API_URL_DEVELOPMENT || "",
  access_token: "access_token",
  refresh_token: "refresh_token",
  user_id: "user_id",
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
  login_code: "login_code",
  profile_img: "profile_img",
  stripe_customer_id: "stripe_customer_id",
  is_paid: "is_paid",
};

export default config;
