import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import LoaderView from "../../../reusable/loader/loaderView";
import { fetchPalByPalId } from "../../../../services/palz/palzManagement";
import { set } from "lodash";
import {
  fetchCurrentExpensesByPalId,
  fetchExpensesByPalId,
} from "../../../../services/expenses/expenseServiceManagement";
import ExpenseGrid from "../../../reusable/cards/expense/expenseGrid";
import BudgetByCategoryChart from "./sub/budget/budgetByCategoryChart";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../../services/helpers/colorService";
import ExpenseBreakdownCard from "../../../reusable/cards/dashboard/expenseBreakdownCard";
import BasicStatCard from "./sub/basicStatCard";
import ExpensesByCategoryChart from "./sub/expensesByCategoryChart";
import PalCoCard from "./sub/palCoCard";
import PalzExpenseSection from "./sub/completeSection/palzExpenseSection";
import { fetchLatestBudgetByUserId } from "../../../../services/budgets/budgetServiceManagement";
import BudgetBreakdownCard from "../../../reusable/cards/dashboard/BudgetBreakdownCard";
import BudgetCardGrid from "../../../reusable/cards/budget/budgetCardGrid";
import PalzBudgetSection from "./sub/completeSection/palzBudgetSection";
import BudgetGoalFundsGrid from "../../../reusable/cards/budgetGoal/sub/budgetGoalFundsGrid";
import BudgetGoalsGrid from "../../../reusable/cards/budgetGoal/budgetGoalsGrid";
import { fetchBudgetGoalsByPalId } from "../../../../services/budgetGoal/budgetGoalManagement";
import BudgetGoalContributionCard from "../../../reusable/cards/dashboard/budgetGoalContributionCard";
import { BudgetGoal } from "../../../../types/budgetGoal";
import { usePageTitle } from "utils/hooks/usePageTitle";
import { ExpenseModel } from "../../../../types/expenses/expenseTypes";

import {
  PalzDetailProps,
  PalDetail,
  BudgetBreakdown,
  BudgetCategory,
  LottieOptions,
  ExtendedExpenseModel,
} from "./types";

import empty_expenses_animation from "../../../reusable/animations/lottie/empty_expenses_animation.json";
import no_budget_animation from "../../../reusable/animations/lottie/no_budget.json";
import budget_goal_animation from "../../../reusable/animations/lottie/budget_goal_animation.json";
import friends_animation from "../../../reusable/animations/lottie/friends_animation.json";
import NotAllowedOverlay from "../../../reusable/subscription/notAllowed/notAllowedOverlay";
import BudgetGoalDonutChart from "../../../reusable/cards/budgetGoal/sub/budgetGoalDonutChart";
import PalzBudgetGoalSection from "./sub/completeSection/palzBudgetGoalSection";

//TODO:
//1. Convert to TypeScript
//2. Break down the components into smaller components to make it more manageable
//3. Enhance the Interface and speed up the loading process
//4. Give the ability to comment on each budget goal

// Add CategoryMap type definition
type CategoryMap = Record<string, number>;

const PalzDetail: React.FC<PalzDetailProps> = ({
  isDarkMode,
}): React.ReactElement => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExpenses, setIsLoadingExpenses] = useState<boolean>(false);
  const [isLoadingBudget, setIsLoadingBudget] = useState<boolean>(false);
  const [isLoadingGoals, setIsLoadingGoals] = useState<boolean>(false);
  const { palId } = useParams<{ palId: string }>();
  const pageTitle = "Pal";
  const pageTitleConfig = {
    title: pageTitle,
    description: "Palz Detail",
  };
  usePageTitle(pageTitleConfig);

  const [palzDetail, setPalzDetail] = useState<PalDetail | null>(null);

  //expenseChartData
  const [expenseChartData, setExpenseChartData] = useState<
    ChartData<"doughnut">
  >({
    labels: [],
    datasets: [
      {
        label: "Expense Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#2a2a2a" : "#ffffff",
        borderWidth: 3,
        hoverBackgroundColor: predefinedColors.map((color) =>
          darkenColor(color, 0.1)
        ),
      },
    ],
  });

  const [isBudgetLoading, setIsBudgetLoading] = useState<boolean>(true);

  //lottie

  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emptyBudgetGoalLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //
  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //primary expenses
  const [
    primaryUserExpensesAndTransactions,
    setPrimaryUserExpensesAndTransactions,
  ] = useState<ExtendedExpenseModel[]>([]);

  const [
    secondaryUserExpensesAndTransactions,
    setSecondaryUserExpensesAndTransactions,
  ] = useState<ExtendedExpenseModel[]>([]);

  const [
    combinedUserExpensesAndTransactions,
    setCombinedUserExpensesAndTransactions,
  ] = useState<ExtendedExpenseModel[]>([]);

  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [expensesByCategory, setExpensesByCategory] = useState<CategoryMap>({});
  const [recurringExpenses, setRecurringExpenses] = useState<number>(0);
  const [nonRecurringExpenses, setNonRecurringExpenses] = useState<number>(0);
  const [expensesByRequestor, setExpensesByRequestor] = useState<number>(0);
  const [expensesByPal, setExpensesByPal] = useState<number>(0);

  //TODO show the budget breakdown for the current
  const [primaryUserBudgetBreakdown, setPrimaryUserBudgetBreakdown] =
    useState<BudgetBreakdown | null>(null);

  const [secondaryUserBudgetBreakdown, setSecondaryUserBudgetBreakdown] =
    useState<BudgetBreakdown | null>(null);

  const [combinedUserBudgetBreakdown, setCombinedUserBudgetBreakdown] =
    useState<BudgetBreakdown | null>(null);

  //budgetChartData

  const [budgetExpenseChartData, setBudgetExpenseChartData] = useState<
    ChartData<"doughnut">
  >({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ),
      },
    ],
  });

  const [budgetByCategory, setBudgetByCategory] = useState<CategoryMap>({});
  const [totalBudgetCategories, setTotalBudgetCategories] = useState<number>(0);
  const [totalBudgetCategoriesLeft, setTotalBudgetCategoriesLeft] =
    useState<number>(0);
  const [totalBudgetCategoriesSpent, setTotalBudgetCategoriesSpent] =
    useState<number>(0);
  const [totalBudgetCategoryByRequestor, setTotalBudgetCategoryByRequestor] =
    useState<number>(0);
  const [totalBudgetCategoryByPal, setTotalBudgetCategoryByPal] =
    useState<number>(0);

  //budget goal
  const [primaryBudgetGoals, setPrimaryBudgetGoals] = useState<BudgetGoal[]>(
    []
  );
  const [secondaryBudgetGoals, setSecondaryBudgetGoals] = useState<
    BudgetGoal[]
  >([]);
  const [combinedBudgetGoals, setCombinedBudgetGoals] = useState<BudgetGoal[]>(
    []
  );

  const [totalBudgetGoalCategories, setTotalBudgetGoalCategories] =
    useState<number>(0);
  const [totalBudgetGoalCategoriesLeft, setTotalBudgetGoalCategoriesLeft] =
    useState<number>(0);
  const [
    totalBudgetGoalCategoriesAchieved,
    setTotalBudgetGoalCategoriesAchieved,
  ] = useState<number>(0);
  const [
    totalBudgetGoalCategoryByRequestor,
    setTotalBudgetGoalCategoryByRequestor,
  ] = useState<number>(0);
  const [totalBudgetGoalCategoryByPal, setTotalBudgetGoalCategoryByPal] =
    useState<number>(0);

  const [budgetGoalChartData, setBudgetGoalChartData] = useState<
    ChartData<"doughnut">
  >({
    labels: [],
    datasets: [
      {
        label: "Budget Goal Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: predefinedColors.map((color) =>
          darkenColor(color, 0.1)
        ),
      },
    ],
  });

  useEffect(() => {
    //fetch the palz detail
    fetchPalzDetail();
  }, []);

  const fetchPalzDetail = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const palzDetail = await fetchPalByPalId(palId);
      setPalzDetail(palzDetail);
      setIsLoading(false);
      if (palzDetail) {
        accessControl(palzDetail);
      }
    } catch (error) {
      console.error("Failed to fetch pal details:", error);
      setIsLoading(false);
    }
  };

  const accessControl = async (palzDetail: PalDetail): Promise<void> => {
    if (palzDetail.has_access && palzDetail.secondary_pal_accepted_request) {
      if (palzDetail.access_right?.can_view_expense) {
        setIsLoadingExpenses(true);
        getPrimaryAndSecondaryUserExpenses(palzDetail);
      }

      if (palzDetail.access_right?.can_view_budget) {
        setIsLoadingBudget(true);
        getPrimaryAndSecondaryUserBudgetBreakdown(palzDetail);
      }

      if (palzDetail.access_right?.can_view_budget_goal) {
        setIsLoadingGoals(true);
        getPrimaryAndSecondaryUserBudgetGoal(palzDetail);
      }
    }
  };

  const getPrimaryAndSecondaryUserExpenses = async (
    palDetail: PalDetail
  ): Promise<void> => {
    try {
      let primaryUserExpensesAndTransactions: ExpenseModel[] = [];
      let secondaryUserExpensesAndTransactions: ExpenseModel[] = [];

      try {
        primaryUserExpensesAndTransactions = await fetchCurrentExpensesByPalId(
          palDetail.primary_pal_id
        );
        setPrimaryUserExpensesAndTransactions(
          primaryUserExpensesAndTransactions as ExtendedExpenseModel[]
        );
      } catch (error) {
        console.error("Failed to fetch expenses for primary pal:", error);
      }

      if (palDetail.secondary_pal_id) {
        try {
          secondaryUserExpensesAndTransactions =
            await fetchCurrentExpensesByPalId(palDetail.secondary_pal_id);
          setSecondaryUserExpensesAndTransactions(
            secondaryUserExpensesAndTransactions as ExtendedExpenseModel[]
          );
        } catch (error) {
          console.error("Failed to fetch expenses for secondary pal:", error);
        }
      }

      const combinedUserExpensesAndTransactions = [
        ...primaryUserExpensesAndTransactions,
        ...secondaryUserExpensesAndTransactions,
      ].map((expense: ExpenseModel): ExtendedExpenseModel => {
        if (palDetail.primary_pal_id === expense.user_pal_id) {
          return {
            ...expense,
            requestor_first_name: palDetail.requestor_first_name,
            requestor_last_name: palDetail.requestor_last_name,
            requestor_profile_image: palDetail.requestor_profile_image,
          };
        } else if (
          palDetail.secondary_pal_id &&
          palDetail.secondary_pal_id === expense.user_pal_id
        ) {
          return {
            ...expense,
            pal_first_name: palDetail.pal_first_name,
            pal_last_name: palDetail.pal_last_name,
            pal_profile_image: palDetail.pal_profile_image,
          };
        }
        return expense as ExtendedExpenseModel;
      });

      setCombinedUserExpensesAndTransactions(
        combinedUserExpensesAndTransactions
      );
      await getLatestExpensesAndTransactionsChartData(
        combinedUserExpensesAndTransactions
      );
      setOtherExpenseSummary(combinedUserExpensesAndTransactions, palDetail);
    } catch (error) {
      console.error("Error in getPrimaryAndSecondaryUserExpenses:", error);
    } finally {
      setIsLoadingExpenses(false);
    }
  };

  const getLatestExpensesAndTransactionsChartData = async (
    expensesAndTransactions: ExtendedExpenseModel[]
  ): Promise<void> => {
    // Group expenses by their actual expense names first
    const expenseGroups = expensesAndTransactions.reduce((groups, expense) => {
      const name = expense.expense_name || "Uncategorized";
      if (!groups[name]) {
        groups[name] = 0;
      }
      groups[name] += expense.expense_amt || 0;
      return groups;
    }, {} as Record<string, number>);

    // Convert to array and sort by amount
    const sortedExpenses = Object.entries(expenseGroups).sort(
      ([, a], [, b]) => b - a
    );

    // Take top 6 expenses and group the rest as "Others" if there are more than 7
    let labels: string[] = [];
    let data: number[] = [];

    if (sortedExpenses.length > 7) {
      const topExpenses = sortedExpenses.slice(0, 6);
      const otherExpenses = sortedExpenses.slice(6);

      labels = [...topExpenses.map(([name]) => name), "Others"];
      data = [
        ...topExpenses.map(([, amount]) => amount),
        otherExpenses.reduce((sum, [, amount]) => sum + amount, 0),
      ];
    } else {
      labels = sortedExpenses.map(([name]) => name);
      data = sortedExpenses.map(([, amount]) => amount);
    }

    const backgroundColor = labels.map(
      (_, index) => predefinedColors[index % predefinedColors.length]
    );
    const hoverColors = backgroundColor.map((color) => darkenColor(color, 0.1));

    setExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: [
        {
          label: "Expense Breakdown",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: isDarkMode ? "#2a2a2a" : "#ffffff",
          borderWidth: 3,
          hoverBackgroundColor: hoverColors,
        },
      ],
    }));
  };

  const setOtherExpenseSummary = (
    combinedUserExpensesAndTransactions: ExtendedExpenseModel[],
    palDetail: PalDetail
  ): void => {
    const totalExpenses = combinedUserExpensesAndTransactions.reduce(
      (total, expense) => total + (expense.expense_amt || 0),
      0
    );
    setTotalExpenses(totalExpenses);

    const totalTransactions = combinedUserExpensesAndTransactions.length;
    setTotalTransactions(totalTransactions);

    // Create a map of budget category IDs to their names
    const categoryNameMap = new Map<string, string>();
    if (combinedUserBudgetBreakdown?.budget_categories) {
      combinedUserBudgetBreakdown.budget_categories.forEach((category) => {
        categoryNameMap.set(
          category.budget_category_id,
          category.category_name
        );
      });
    }

    // Group expenses by category using actual category names
    const expensesByCategory = combinedUserExpensesAndTransactions.reduce<
      Record<string, number>
    >((categories, expense) => {
      let categoryName = expense.expense_name || "Uncategorized";
      if (expense.budget_category_id) {
        categoryName =
          categoryNameMap.get(expense.budget_category_id) || categoryName;
      }
      return {
        ...categories,
        [categoryName]:
          (categories[categoryName] || 0) + (expense.expense_amt || 0),
      };
    }, {});

    // Sort categories by amount
    const sortedCategories = Object.entries(expensesByCategory)
      .sort(([, a], [, b]) => b - a)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {}
      );

    setExpensesByCategory(sortedCategories);

    const recurringExpenses = combinedUserExpensesAndTransactions
      .filter((expense) => expense.is_re_occuring)
      .reduce((total, expense) => total + (expense.expense_amt || 0), 0);
    setRecurringExpenses(recurringExpenses);

    const nonRecurringExpenses = combinedUserExpensesAndTransactions
      .filter((expense) => !expense.is_re_occuring)
      .reduce((total, expense) => total + (expense.expense_amt || 0), 0);
    setNonRecurringExpenses(nonRecurringExpenses);

    calculateAndSetExpenses(combinedUserExpensesAndTransactions, palDetail);
  };

  function calculateAndSetExpenses(
    combinedUserExpensesAndTransactions: ExtendedExpenseModel[],
    palDetail: PalDetail
  ): void {
    const expensesByRequestor = calculateTotalExpensesByUser(
      combinedUserExpensesAndTransactions,
      palDetail,
      false
    );
    setExpensesByRequestor(expensesByRequestor);

    const expensesByPal = calculateTotalExpensesByUser(
      combinedUserExpensesAndTransactions,
      palDetail,
      true
    );
    setExpensesByPal(expensesByPal);
  }

  function calculateTotalExpensesByUser(
    expenses: ExtendedExpenseModel[],
    palDetail: PalDetail,
    isPal: boolean
  ): number {
    return expenses
      .filter((expense) =>
        isPal
          ? expense.pal_first_name === palDetail.pal_first_name &&
            expense.pal_last_name === palDetail.pal_last_name
          : expense.requestor_first_name === palDetail.requestor_first_name &&
            expense.requestor_last_name === palDetail.requestor_last_name
      )
      .reduce((total, expense) => total + (expense.expense_amt || 0), 0);
  }

  const getPrimaryAndSecondaryUserBudgetBreakdown = async (
    palDetail: PalDetail
  ): Promise<void> => {
    try {
      let primaryUserBudgetBreakdown: BudgetBreakdown | null = null;
      let secondaryUserBudgetBreakdown: BudgetBreakdown | null = null;

      try {
        primaryUserBudgetBreakdown = await fetchLatestBudgetByUserId(
          palDetail.primary_pal_id
        );
        if (primaryUserBudgetBreakdown) {
          primaryUserBudgetBreakdown.budget_categories =
            primaryUserBudgetBreakdown.budget_categories.map((category) => ({
              ...category,
              requestor_first_name: palDetail.requestor_first_name,
              requestor_last_name: palDetail.requestor_last_name,
              requestor_profile_image: palDetail.requestor_profile_image,
            }));
          setPrimaryUserBudgetBreakdown(primaryUserBudgetBreakdown);
        }
      } catch (error) {
        console.error("Failed to fetch budget for primary user:", error);
      }

      try {
        secondaryUserBudgetBreakdown = await fetchLatestBudgetByUserId(
          palDetail.secondary_pal_id
        );
        if (secondaryUserBudgetBreakdown) {
          secondaryUserBudgetBreakdown.budget_categories =
            secondaryUserBudgetBreakdown.budget_categories.map((category) => ({
              ...category,
              pal_first_name: palDetail.pal_first_name,
              pal_last_name: palDetail.pal_last_name,
              pal_profile_image: palDetail.pal_profile_image,
            }));
          setSecondaryUserBudgetBreakdown(secondaryUserBudgetBreakdown);
        }
      } catch (error) {
        console.error("Failed to fetch budget for secondary user:", error);
      }

      if (primaryUserBudgetBreakdown && secondaryUserBudgetBreakdown) {
        const allCategories = [
          ...primaryUserBudgetBreakdown.budget_categories,
          ...secondaryUserBudgetBreakdown.budget_categories,
        ];

        const combinedCategories = allCategories.reduce<BudgetCategory[]>(
          (acc, currentCategory) => {
            const existingCategory = acc.find(
              (category) =>
                category.category_name.trim().toLowerCase() ===
                currentCategory.category_name.trim().toLowerCase()
            );

            if (existingCategory) {
              return acc.map((category) =>
                category.category_name.trim().toLowerCase() ===
                existingCategory.category_name.trim().toLowerCase()
                  ? {
                      ...category,
                      total_allocated_for_property:
                        category.total_allocated_for_property +
                        currentCategory.total_allocated_for_property,
                      amount_left:
                        category.amount_left + currentCategory.amount_left,
                    }
                  : category
              );
            }

            return [...acc, currentCategory];
          },
          []
        );

        const combinedUserBudgetBreakdown: BudgetBreakdown = {
          ...primaryUserBudgetBreakdown,
          budget_categories: combinedCategories,
        };

        setCombinedUserBudgetBreakdown(combinedUserBudgetBreakdown);
        await getLatestBudgetBreakdownChartData(combinedUserBudgetBreakdown);
        setOtherBudgetSummary(combinedUserBudgetBreakdown, palDetail);
      }
    } catch (error) {
      console.error(
        "Error in getPrimaryAndSecondaryUserBudgetBreakdown:",
        error
      );
    } finally {
      setIsLoadingBudget(false);
    }
  };

  const getLatestBudgetBreakdownChartData = async (
    budgetBreakdown: BudgetBreakdown
  ): Promise<void> => {
    const labels = budgetBreakdown.budget_categories.map(
      (category) => category.category_name
    );
    const data = budgetBreakdown.budget_categories.map(
      (category) => category.total_allocated_for_property
    );

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map((color) =>
      color.replace("0.2", "1")
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    setBudgetExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: hoverColors,
      })),
    }));
  };

  const setOtherBudgetSummary = (
    combinedUserBudgetBreakdown: BudgetBreakdown,
    palDetail: PalDetail
  ): void => {
    const totalBudgetCategories =
      combinedUserBudgetBreakdown.budget_categories.reduce(
        (total: number, category: BudgetCategory) =>
          total + Number(category.total_allocated_for_property),
        0
      );
    setTotalBudgetCategories(totalBudgetCategories);

    const budgetByCategory =
      combinedUserBudgetBreakdown.budget_categories.reduce<CategoryMap>(
        (acc, category) => {
          const categoryName = category.category_name;
          return {
            ...acc,
            [categoryName]:
              (acc[categoryName] || 0) +
              Number(category.total_allocated_for_property),
          };
        },
        {}
      );
    setBudgetByCategory(budgetByCategory);

    const totalBudgetCategoriesLeft =
      combinedUserBudgetBreakdown.budget_categories.reduce(
        (total: number, category: BudgetCategory) =>
          total + Number(category.amount_left),
        0
      );
    setTotalBudgetCategoriesLeft(totalBudgetCategoriesLeft);

    const totalBudgetCategoriesSpent =
      combinedUserBudgetBreakdown.budget_categories.reduce(
        (total: number, category: BudgetCategory) =>
          total +
          Number(category.total_allocated_for_property) -
          Number(category.amount_left),
        0
      );
    setTotalBudgetCategoriesSpent(totalBudgetCategoriesSpent);

    calculateAndSetBudgets(combinedUserBudgetBreakdown, palDetail);
  };

  function calculateAndSetBudgets(
    combinedUserBudgetBreakdown: BudgetBreakdown,
    palDetail: PalDetail
  ): void {
    const totalBudgetCategoryByRequestor = calculateTotalBudgetByUser(
      combinedUserBudgetBreakdown.budget_categories,
      palDetail,
      false
    );
    if (totalBudgetCategoryByRequestor !== 0) {
      setTotalBudgetCategoryByRequestor(totalBudgetCategoryByRequestor);
    }

    const totalBudgetCategoryByPal = calculateTotalBudgetByUser(
      combinedUserBudgetBreakdown.budget_categories,
      palDetail,
      true
    );
    if (totalBudgetCategoryByPal !== 0) {
      setTotalBudgetCategoryByPal(totalBudgetCategoryByPal);
    }
  }

  function calculateTotalBudgetByUser(
    budgetCategories: BudgetCategory[],
    userDetail: PalDetail,
    isPal: boolean
  ): number {
    return budgetCategories
      .filter((category) =>
        isPal
          ? category.pal_first_name === userDetail.pal_first_name &&
            category.pal_last_name === userDetail.pal_last_name
          : category.requestor_first_name === userDetail.requestor_first_name &&
            category.requestor_last_name === userDetail.requestor_last_name
      )
      .reduce(
        (total: number, category: BudgetCategory) =>
          total + Number(category.total_allocated_for_property),
        0
      );
  }

  const getPrimaryAndSecondaryUserBudgetGoal = async (
    palDetail: PalDetail
  ): Promise<void> => {
    try {
      let primaryBudgetGoals: BudgetGoal[] = [];
      let secondaryBudgetGoals: BudgetGoal[] = [];

      try {
        primaryBudgetGoals = await fetchBudgetGoalsByPalId(
          palDetail.primary_pal_id
        );
        setPrimaryBudgetGoals(primaryBudgetGoals);
      } catch (error) {
        console.error("Failed to fetch budget goals for primary pal:", error);
      }

      if (palDetail.secondary_pal_id) {
        try {
          secondaryBudgetGoals = await fetchBudgetGoalsByPalId(
            palDetail.secondary_pal_id
          );
          setSecondaryBudgetGoals(secondaryBudgetGoals);
        } catch (error) {
          console.error(
            "Failed to fetch budget goals for secondary pal:",
            error
          );
        }
      }

      const combinedBudgetGoals = [
        ...primaryBudgetGoals.map((goal) => ({
          ...goal,
          pal_first_name: palDetail.requestor_first_name,
          pal_last_name: palDetail.requestor_last_name,
        })),
        ...secondaryBudgetGoals.map((goal) => ({
          ...goal,
          pal_first_name: palDetail.pal_first_name,
          pal_last_name: palDetail.pal_last_name,
        })),
      ];

      setCombinedBudgetGoals(combinedBudgetGoals);
      await getLatestBudgetGoalChartData(combinedBudgetGoals);
      setOtherBudgetGoalSummary(combinedBudgetGoals, palDetail);
    } catch (error) {
      console.error("Error in getPrimaryAndSecondaryUserBudgetGoal:", error);
    } finally {
      setIsLoadingGoals(false);
    }
  };

  const getLatestBudgetGoalChartData = async (
    budgetGoals: BudgetGoal[]
  ): Promise<void> => {
    // Sort goals by amount in descending order
    const sortedGoals = [...budgetGoals].sort(
      (a, b) => b.budget_goal_sum_total - a.budget_goal_sum_total
    );

    const labels = sortedGoals.map((goal) => goal.goal_name);
    const data = sortedGoals.map((goal) => goal.budget_goal_sum_total);

    // Calculate percentages for each goal
    const percentages = sortedGoals.map((goal) =>
      ((goal.current_amt_saved / goal.budget_goal_sum_total) * 100).toFixed(1)
    );

    // Format labels to include amount and percentage
    const formattedLabels = labels.map(
      (label, index) =>
        `${label} ($${sortedGoals[
          index
        ].current_amt_saved.toLocaleString()} / $${sortedGoals[
          index
        ].budget_goal_sum_total.toLocaleString()} - ${percentages[index]}%)`
    );

    const backgroundColor = labels.map(
      (_, index) => predefinedColors[index % predefinedColors.length]
    );
    const hoverColors = backgroundColor.map((color) => darkenColor(color, 0.1));

    setBudgetGoalChartData({
      labels: formattedLabels,
      datasets: [
        {
          label: "Budget Goal Breakdown",
          data: data,
          backgroundColor: backgroundColor,
          borderColor: isDarkMode ? "#6c757d" : "white",
          borderWidth: 3,
          hoverBackgroundColor: hoverColors,
        },
      ],
    });
  };

  const setOtherBudgetGoalSummary = (
    combinedBudgetGoals: BudgetGoal[],
    palDetail: PalDetail
  ): void => {
    const totalBudgetGoalCategories = combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.budget_goal_sum_total),
      0
    );
    setTotalBudgetGoalCategories(totalBudgetGoalCategories);

    const totalBudgetGoalCategoriesLeft = combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.total_left),
      0
    );
    setTotalBudgetGoalCategoriesLeft(totalBudgetGoalCategoriesLeft);

    const totalBudgetGoalCategoriesAchieved = combinedBudgetGoals.reduce(
      (total, goal) => total + Number(goal.current_amt_saved),
      0
    );
    setTotalBudgetGoalCategoriesAchieved(totalBudgetGoalCategoriesAchieved);

    const totalBudgetGoalCategoryByRequestor = calculateTotalBudgetGoalByUser(
      combinedBudgetGoals,
      palDetail,
      false
    );
    setTotalBudgetGoalCategoryByRequestor(totalBudgetGoalCategoryByRequestor);

    const totalBudgetGoalCategoryByPal = calculateTotalBudgetGoalByUser(
      combinedBudgetGoals,
      palDetail,
      true
    );
    setTotalBudgetGoalCategoryByPal(totalBudgetGoalCategoryByPal);
  };

  const calculateTotalBudgetGoalByUser = (
    budgetGoals: BudgetGoal[],
    palDetail: PalDetail,
    isPal: boolean
  ): number => {
    return budgetGoals
      .filter((goal) =>
        isPal
          ? goal.pal_first_name === palDetail.pal_first_name &&
            goal.pal_last_name === palDetail.pal_last_name
          : goal.requestor_first_name === palDetail.requestor_first_name &&
            goal.requestor_last_name === palDetail.requestor_last_name
      )
      .reduce((total, goal) => total + Number(goal.budget_goal_sum_total), 0);
  };

  //show the chart for the expenses combined for the primary and secondary palz (have option to see individual charts)
  return (
    <React.Fragment>
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div
        className="container-fluid"
        data-bs-theme={isDarkMode ? "dark" : "light"}
      >
        {isLoading ? (
          <LoaderView />
        ) : (
          <>
            {palzDetail && (
              <div className="row mb-4">
                <div className="col-xl-12">
                  <PalCoCard key={palzDetail.pal_id} pal={palzDetail} />
                </div>
              </div>
            )}

            {palzDetail?.has_access &&
            palzDetail.secondary_pal_accepted_request ? (
              <NotAllowedOverlay message="You need to be a paid user to access this feature">
                <div className="card shadow-sm border-0">
                  <div className="card-body p-0">
                    <Tabs
                      defaultActiveKey="expenses"
                      id="justify-tab-example"
                      className="mb-0 nav-pills nav-justified border-bottom"
                    >
                      <Tab
                        eventKey="expenses"
                        title={
                          <span>
                            <i className="bx bx-money-withdraw me-1"></i>
                            Expenses
                          </span>
                        }
                        className="nav-link"
                      >
                        <div
                          className={`p-4 ${
                            isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
                          }`}
                        >
                          <div className="tw-space-y-4">
                            <PalzExpenseSection
                              lottieAnimation={emptyExpensesLottieOptions}
                              expenseChartData={expenseChartData}
                              isDarkMode={isDarkMode}
                              expensesByCategory={expensesByCategory}
                              totalExpenses={totalExpenses}
                              totalTransactions={totalTransactions}
                              recurringExpenses={recurringExpenses}
                              nonRecurringExpenses={nonRecurringExpenses}
                              expensesByRequestor={expensesByRequestor}
                              expensesByPal={expensesByPal}
                              combinedUserExpensesAndTransactions={
                                combinedUserExpensesAndTransactions
                              }
                              isBudgetLoading={isLoadingBudget}
                              isExpensesLoading={isLoadingExpenses}
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="budget"
                        title={
                          <span>
                            <i className="bx bx-wallet me-1"></i>
                            Budget
                          </span>
                        }
                        className="nav-link"
                      >
                        <div
                          className={`p-4 ${
                            isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
                          }`}
                        >
                          <div className="tw-space-y-4">
                            <PalzBudgetSection
                              lottieAnimation={emptyBudgetLottieOptions}
                              budgetExpenseChartData={budgetExpenseChartData}
                              isDarkMode={isDarkMode}
                              budgetByCategory={budgetByCategory}
                              totalBudgetCategories={totalBudgetCategories}
                              totalBudgetCategoriesLeft={
                                totalBudgetCategoriesLeft
                              }
                              totalBudgetCategoriesSpent={
                                totalBudgetCategoriesSpent
                              }
                              totalBudgetCategoryByRequestor={
                                totalBudgetCategoryByRequestor
                              }
                              totalBudgetCategoryByPal={
                                totalBudgetCategoryByPal
                              }
                              combinedUserBudgetBreakdown={
                                combinedUserBudgetBreakdown
                              }
                              isLoading={isLoadingBudget}
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="budgetGoal"
                        title={
                          <span>
                            <i className="bx bx-target-lock me-1"></i>
                            Goals
                          </span>
                        }
                        className="nav-link"
                      >
                        <div
                          className={`p-4 ${
                            isDarkMode ? "tw-bg-gray-900" : "tw-bg-gray-50"
                          }`}
                        >
                          <PalzBudgetGoalSection
                            lottieAnimation={emptyBudgetGoalLottieOptions}
                            budgetGoalChartData={budgetGoalChartData}
                            isDarkMode={isDarkMode}
                            budgetGoals={combinedBudgetGoals}
                            totalBudgetGoalCategories={
                              totalBudgetGoalCategories
                            }
                            totalBudgetGoalCategoriesLeft={
                              totalBudgetGoalCategoriesLeft
                            }
                            totalBudgetGoalCategoriesAchieved={
                              totalBudgetGoalCategoriesAchieved
                            }
                            isLoading={isLoadingGoals}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </NotAllowedOverlay>
            ) : palzDetail?.pal_id ? (
              <div
                className={`card shadow-sm border-0 ${
                  isDarkMode ? "tw-bg-gray-800" : "tw-bg-white"
                }`}
              >
                <div className="card-body">
                  <div className="alert alert-danger mb-0" role="alert">
                    <h4 className="alert-heading">Access Denied</h4>
                    <p className="mb-0">
                      You do not have access to view the details of this Pal.
                      Please contact the Pal to request access.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default PalzDetail;
