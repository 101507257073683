import React from "react";
import { ChartData } from "chart.js";
import { BudgetGoal } from "../../../../../../types/budgetGoal";
import LoaderView from "../../../../../reusable/loader/loaderView";
import BudgetGoalDonutChart from "../../../../../reusable/cards/budgetGoal/sub/budgetGoalDonutChart";
import BudgetGoalContributionCard from "../../../../../reusable/cards/dashboard/budgetGoalContributionCard";
import BasicStatCard from "../../sub/basicStatCard";
import BudgetGoalsGrid from "../../../../../reusable/cards/budgetGoal/budgetGoalsGrid";
import { LottieOptions } from "../../types";

interface PalzBudgetGoalSectionProps {
  lottieAnimation: LottieOptions;
  budgetGoalChartData: ChartData<"doughnut">;
  isDarkMode: boolean;
  budgetGoals: BudgetGoal[];
  totalBudgetGoalCategories: number;
  totalBudgetGoalCategoriesLeft: number;
  totalBudgetGoalCategoriesAchieved: number;
  isLoading: boolean;
}

const PalzBudgetGoalSection: React.FC<PalzBudgetGoalSectionProps> = ({
  lottieAnimation,
  budgetGoalChartData,
  isDarkMode,
  budgetGoals,
  totalBudgetGoalCategories,
  totalBudgetGoalCategoriesLeft,
  totalBudgetGoalCategoriesAchieved,
  isLoading,
}) => {
  return (
    <div className="tw-space-y-4">
      {isLoading ? (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex justify-content-center align-items-center p-4">
              <LoaderView />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-xl-3">
              <div className="card card-height-100">
                <div className="card-body">
                  <BudgetGoalDonutChart
                    budgetGoalChartData={budgetGoalChartData}
                    isDarkMode={isDarkMode}
                    budgetGoals={budgetGoals}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card card-height-100">
                <div className="card-body">
                  <BudgetGoalContributionCard
                    lottieAnimation={lottieAnimation}
                    budgetGoals={budgetGoals}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="row">
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Budget Goal Categories"
                    percentage={0}
                    total={totalBudgetGoalCategories}
                    icon="bx bx-wallet text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Categories Left"
                    percentage={0}
                    total={totalBudgetGoalCategoriesLeft}
                    icon="bx bx-transfer text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Categories Achieved"
                    percentage={0}
                    total={totalBudgetGoalCategoriesAchieved}
                    icon="bx bx-receipt text-primary"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {budgetGoals &&
              budgetGoals?.length > 0 &&
              budgetGoals[0]?.goal_name && (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <BudgetGoalsGrid budgetGoals={budgetGoals || []} />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PalzBudgetGoalSection;
