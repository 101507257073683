import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Joi from "joi-browser";
import MultiLineInput from "../../../reusable/form/multilineinput";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import {
  fetchLatestBudget,
  updateAmountLeft,
} from "../../../../services/budgets/budgetServiceManagement";
import { getIconUrl, iconUrlMap } from "../../../../constants/iconUrlMap";
import { set } from "lodash";
import Select from "react-select";
import config from "../../../../constants/config";
import { v4 as uuidv4 } from "uuid";
import {
  createExpenseWithTransactions,
  fetchAllExpensesForLoggedInUser,
} from "../../../../services/expenses/expenseServiceManagement";
import BudgetAllocationSelect, {
  BudgetSelectOption,
} from "../../../reusable/form/BudgetAllocationSelect";
import { devLog } from "utils/logger";
import MultiImageUploader from "../../../reusable/form/MultiImageUploader";
import { Badge, Button } from "react-bootstrap";

interface ReceiptUpload {
  base64_data?: string;
  url?: string;
  file_name?: string;
  file_size?: number;
  file_size_formatted?: string;
  file_type?: string;
  upload_time_stamp?: Date;
  unique_file_name?: string;
}

interface ExpenseCreate {
  expense_id?: string;
  day_due_each_month?: number;
  expense_name?: string;
  expense_amt?: number;
  expense_description?: string;
  has_insight?: boolean;
  user_pal_id?: string;
  record_time_stamp?: number;
  is_re_occuring?: boolean;
  budget_category_id?: string;
  currency_name?: string;
  currency_symbol?: string;
  receipt_uploads?: ReceiptUpload[];
  is_deleted?: boolean;
}

interface BudgetCategory {
  budget_id: string;
  budget_category_id: string;
  category_name: string;
  total_allocated_for_property: number;
  amount_left: number;
  category_icon?: string;
}

interface Budget {
  budget_categories: BudgetCategory[];
  budget_id: string;
  creator_profile: {
    creator_profile_id: string;
    email_address: string;
    profile_img: string;
    last_name: string;
    user_id: string;
  };
  email_address: string;
  first_name: string;
  is_active_budget: boolean;
  last_name: string;
  month: number;
  record_time_stamp: number;
  total_left: number;
  total_sum: number;
  user_id: string;
  year: number;
}

interface SelectOption {
  value: string;
  label: string;
  data?: ExpenseCreate;
  icon?: string;
  url?: string;
}

interface Props {
  isDarkMode: boolean;
}

interface FormErrors {
  [key: string]: string;
}

interface SchemaType {
  [key: string]: any;
}

const CreateNewExpense: React.FC<Props> = ({ isDarkMode }) => {
  const pageTitle = "Create New Expense";
  const [ignored, setIgnored] = useReducer((x: number) => x + 1, 0);

  const navigate = useNavigate();

  const [expenseData, setExpenseData] = useState<ExpenseCreate>({
    expense_id: "",
    day_due_each_month: 0,
    expense_name: "",
    expense_amt: 0,
    expense_description: "",
    has_insight: false,
    user_pal_id: "",
    record_time_stamp: 0,
    is_re_occuring: false,
    budget_category_id: "",
    currency_name: "",
    currency_symbol: "",
    receipt_uploads: [],
    is_deleted: false,
  });

  const [isReOcurring, setIsReOcurring] = useState<boolean>(
    expenseData.is_re_occuring || false
  );

  const [latestBudget, setLatestBudget] = useState<Budget | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [budgetAllocationSelectorOptions, setBudgetAllocationSelectorOptions] =
    useState<BudgetSelectOption[]>([]);
  const [selectedBudgetAllocatedOption, setSelectedBudgetAllocatedOption] =
    useState<BudgetSelectOption | null>(null);
  const [selectedOptionExpenseCategory, setSelectedOptionExpenseCategory] =
    useState<SelectOption | null>(null);

  const [existingExpenseOptions, setExistingExpenseOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedExpenseOption, setSelectedExpenseOption] =
    useState<SelectOption | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [base64Data, setBase64Data] = useState<
    Array<{ id: number; data: string }>
  >([]);
  const [imagesToUpload, setImagesToUpload] = useState<
    Array<{ base64_data: string; file_name: string }>
  >([]);
  const [attachmentsConfirmed, setAttachmentsConfirmed] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [itemToPreview, setItemToPreview] = useState<{
    base64_data: string;
    file_name: string;
  } | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema: SchemaType = {
    day_due_each_month: Joi.number().required().label("Day Due Each Month"),
    expense_amt: Joi.number().required().label("Expense Amount"),
    expense_description: Joi.string().required().label("Expense Description"),
    expense_name: Joi.string().required().label("Expense Name"),
    has_insight: Joi.boolean().optional().label("Has Insight"),
    is_re_occuring: Joi.boolean().optional().label("Is Re Occuring"),
    record_time_stamp: Joi.number().allow(0).label("Record Time Stamp"),
    user_pal_id: Joi.string().allow("").label("User Pal Id"),
    expense_id: Joi.string().allow("").label("Expense Id"),
    budget_category_id: Joi.string().optional().label("Budget Category"),
    currency_name: Joi.string().optional().label("Currency Name").allow(""), //optional
    currency_symbol: Joi.string().optional().label("Currency Symbol").allow(""), //optional
    receipt_uploads: Joi.array().optional().label("Receipt Uploads"), //optional
    is_deleted: Joi.boolean().optional().label("Is Deleted").default(false), //optional
    transactions: Joi.array().optional().label("Transactions").allow(null), //optional
    account_id: Joi.string().optional().label("Account Id").allow(""), //optional
    expense_category: Joi.string()
      .optional()
      .label("Expense Category")
      .allow(""), //optional
    expense_icon: Joi.string().optional().label("Expense Icon").allow(""), //optional
  };

  const validate = (): FormErrors | null => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(expenseData, schema, options);
    if (!error) return null;

    const errors: FormErrors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    devLog(errors);
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }): string | null => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) {
      for (const error in errors) {
        if (errors.hasOwnProperty(error)) {
          toast.error(errors[error]);
        }
      }
      return;
    }
    doSubmit();
  };

  const handleChange = (input: any) => {
    if (input?.currentTarget) {
      // Handle regular form inputs
      const { name, value, checked } = input.currentTarget;
      const errorMessage = validateProperty({ name, value });

      const newErrors = { ...errors };
      if (errorMessage) {
        newErrors[name] = errorMessage;
      } else {
        delete newErrors[name];
      }

      let newExpenseData = { ...expenseData };
      if (name === "is_re_occuring") {
        newExpenseData = {
          ...expenseData,
          is_re_occuring: checked,
          // Reset day_due_each_month when switching to non-recurring
          day_due_each_month: checked ? expenseData.day_due_each_month : 0,
        };
        setIsReOcurring(checked);
      } else {
        newExpenseData = {
          ...expenseData,
          [name]: value,
        };
      }

      setExpenseData(newExpenseData);
      setErrors(newErrors);
    } else {
      // Handle CreatableSelect change
      const selectedOption = input as SelectOption | null;
      setSelectedExpenseOption(selectedOption);

      if (selectedOption) {
        const newExpenseData = {
          ...expenseData,
          expense_name: selectedOption.value,
          // If this is an existing expense, copy its data
          ...(selectedOption.data || {}),
        };

        // Validate the expense amount from the selected option
        const newErrors = { ...errors };
        if (selectedOption.data?.expense_amt) {
          const errorMessage = validateProperty({
            name: "expense_amt",
            value: selectedOption.data.expense_amt,
          });
          if (errorMessage) {
            newErrors.expense_amt = errorMessage;
          } else {
            delete newErrors.expense_amt;
          }
        }

        setExpenseData(newExpenseData);
        setErrors(newErrors);
      } else {
        // Handle clearing the selection
        setExpenseData((prev) => ({
          ...prev,
          expense_name: "",
        }));
      }
    }
  };

  const handleBudgetAllocatedChange = (option: BudgetSelectOption | null) => {
    if (option) {
      setSelectedBudgetAllocatedOption(option);
      setExpenseData((prev) => ({
        ...prev,
        budget_category_id: option.value,
      }));
    }
  };

  const getLatestBudget = async () => {
    try {
      const budget = await fetchLatestBudget();
      if (budget) {
        console.log(budget);
        setLatestBudget(budget);
        mapSelectorOptions(budget.budget_categories);
      }
    } catch (error) {
      console.error("Error fetching latest budget:", error);
      toast.error("Failed to fetch latest budget");
    }
    setIsLoading(false);
  };

  const getAllExpenses = async () => {
    try {
      const expenses = await fetchAllExpensesForLoggedInUser();
      if (expenses) {
        const uniqueExpenses = expenses.reduce(
          (unique: ExpenseCreate[], expense: ExpenseCreate) => {
            if (!unique.find((e) => e.expense_name === expense.expense_name)) {
              unique.push(expense);
            }
            return unique;
          },
          []
        );

        // Find the matching budget category for each expense to get its icon
        const options = uniqueExpenses.map((expense: ExpenseCreate) => {
          const matchingCategory = latestBudget?.budget_categories.find(
            (cat) => cat.budget_category_id === expense.budget_category_id
          );

          return {
            value: expense.expense_name || "",
            label: expense.expense_name || "",
            data: expense,
            icon: matchingCategory?.category_icon || "BudgetExpense",
          };
        });
        setExistingExpenseOptions(options);
      }
    } catch (error) {
      console.error("Error fetching expenses:", error);
      toast.error("Failed to fetch existing expenses");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getLatestBudget();
  }, []);

  // Update expenses when latestBudget changes
  useEffect(() => {
    if (latestBudget) {
      getAllExpenses();
    }
  }, [latestBudget]);

  const lastValidationCheck = (): boolean => {
    if (!expenseData.expense_name) {
      toast.error("Please select an expense name");
      return false;
    }
    if (!latestBudget) {
      toast.error("No budget found. Please create a budget first");
      return false;
    }
    if (!selectedBudgetAllocatedOption) {
      toast.error("Please select a budget allocation");
      return false;
    }
    if (!expenseData.expense_amt || expenseData.expense_amt <= 0) {
      toast.error("Please enter a valid expense amount");
      return false;
    }
    return true;
  };

  const handlePreviewAttachment = (attachment: {
    base64_data: string;
    file_name: string;
  }) => {
    setItemToPreview(attachment);
    setShowPreviewModal(true);
  };

  const handleSelectionConfirm = async () => {
    console.log("handleSelectionConfirm called");
    console.log("Current state - selectedFiles:", selectedFiles);
    console.log("Current state - attachmentsConfirmed:", attachmentsConfirmed);

    if (selectedFiles.length > 0) {
      console.log("Setting attachmentsConfirmed to true");

      const uploads = base64Data.map((item, index) => ({
        base64_data: item.data,
        file_name: selectedFiles[index].name,
      }));

      await setImagesToUpload(uploads);
      await setAttachmentsConfirmed(true);

      console.log("State after update - attachmentsConfirmed:", true);
      console.log("State after update - imagesToUpload:", uploads);
    }
  };

  useEffect(() => {
    console.log("attachmentsConfirmed changed:", attachmentsConfirmed);
  }, [attachmentsConfirmed]);

  const doSubmit = async () => {
    if (!lastValidationCheck()) return;

    setIsSubmitting(true);
    try {
      const newExpense: ExpenseCreate = {
        ...expenseData,
        expense_id: uuidv4(),
        record_time_stamp: Date.now(),
        user_pal_id: localStorage.getItem(config.user_id) || "",
        budget_category_id: selectedBudgetAllocatedOption?.value,
        receipt_uploads: imagesToUpload,
      };

      const result = await createExpenseWithTransactions(newExpense);
      if (result) {
        toast.success("Expense created successfully");
        navigate("/currentExpenses");
      }
    } catch (error) {
      console.error("Error creating expense:", error);
      toast.error("Failed to create expense");
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const mapSelectorOptions = (budgetCategories: BudgetCategory[]) => {
    const options = budgetCategories.map((category) => ({
      value: category.budget_category_id,
      label: category.category_name,
      amount_left: category.amount_left,
      total_allocated: category.total_allocated_for_property,
      icon: category.category_icon || "BudgetExpense", // Use the category's icon
    }));
    setBudgetAllocationSelectorOptions(options);
  };

  const formatExpenseOptionLabel = ({ value, label, icon }: SelectOption) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img
        src={getIconUrl(icon || "BudgetExpense").url}
        alt={label}
        style={{ width: "20px", height: "20px" }}
      />
      <span>{label}</span>
    </div>
  );

  const getSelectStyles = (isDarkMode: boolean) => ({
    option: (base: any, state: any) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
      backgroundColor: isDarkMode
        ? state.isSelected
          ? "#0d6efd"
          : state.isFocused
          ? "#2c3034"
          : "#212529"
        : state.isSelected
        ? "#0d6efd"
        : state.isFocused
        ? "#f8f9fa"
        : "white",
      color: isDarkMode ? "white" : state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
    control: (base: any) => ({
      ...base,
      backgroundColor: isDarkMode ? "#212529" : "white",
      borderColor: isDarkMode ? "#495057" : "#ced4da",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: isDarkMode ? "#212529" : "white",
      border: isDarkMode ? "1px solid #495057" : base.border,
    }),
    input: (base: any) => ({
      ...base,
      color: isDarkMode ? "white" : "black",
    }),
    singleValue: (base: any) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      gap: "8px",
      color: isDarkMode ? "white" : "black",
    }),
    placeholder: (base: any) => ({
      ...base,
      color: "#6c757d",
    }),
  });

  const selectTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral20: isDarkMode ? "#495057" : "#ced4da",
      neutral30: isDarkMode ? "#495057" : "#ced4da",
    },
  });

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageLink="/currentExpenses"
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Create New Expense
                  </h4>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label>Expense Name</label>
                            <CreatableSelect<SelectOption>
                              name="expense_name"
                              options={existingExpenseOptions}
                              onChange={handleChange}
                              isClearable
                              value={selectedExpenseOption}
                              placeholder="Enter or select expense name"
                              formatOptionLabel={formatExpenseOptionLabel}
                              styles={getSelectStyles(isDarkMode)}
                              theme={selectTheme}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <BudgetAllocationSelect
                              value={selectedBudgetAllocatedOption}
                              onChange={handleBudgetAllocatedChange}
                              options={budgetAllocationSelectorOptions}
                              formatCurrency={formatCurrency}
                              isDarkMode={isDarkMode}
                              expense_amount={expenseData.expense_amt || 0}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="expense_amt"
                              label="Expense Amount"
                              type="number"
                              value={expenseData.expense_amt}
                              onChange={handleChange}
                              error={errors.expense_amt}
                              placeholder="Enter amount"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <label htmlFor="is_re_occuring">
                              Is Reoccurring Expense
                            </label>
                            <br />
                            <div className="form-check form-switch form-switch-right form-switch-md">
                              <label className="form-label text-muted">
                                {isReOcurring ? "Yes" : "No"}
                              </label>
                              <input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="is_re_occuring"
                                name="is_re_occuring"
                                checked={expenseData.is_re_occuring}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        {isReOcurring && (
                          <div className="col-xxl-3 col-md-6">
                            <div>
                              <label htmlFor="day_due_each_month">
                                Monthly Payment Date
                              </label>
                              <select
                                className="form-select"
                                name="day_due_each_month"
                                value={expenseData.day_due_each_month}
                                onChange={handleChange}
                              >
                                <option value="">
                                  When is this payment due?
                                </option>
                                {Array.from(
                                  { length: 31 },
                                  (_, i) => i + 1
                                ).map((day) => (
                                  <option key={day} value={day}>
                                    {day +
                                      (day === 1
                                        ? "st"
                                        : day === 2
                                        ? "nd"
                                        : day === 3
                                        ? "rd"
                                        : "th")}{" "}
                                    day of the month
                                  </option>
                                ))}
                              </select>
                              {errors.day_due_each_month && (
                                <div className="invalid-feedback d-block">
                                  {errors.day_due_each_month}
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="col-xxl-12 col-md-12">
                          <div>
                            <MultiLineInput
                              name="expense_description"
                              label="Expense Description"
                              value={expenseData.expense_description}
                              onChange={handleChange}
                              error={errors.expense_description}
                              placeholder="Enter description"
                              labelClass=""
                              numOfRows={3}
                              isRequired={true}
                              iconClassName=""
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="col-xxl-12 col-md-12">
                          <div>
                            <label>Transaction Receipt(s)</label>
                            <MultiImageUploader
                              selectedFiles={selectedFiles}
                              setSelectedFiles={setSelectedFiles}
                              base64Data={base64Data}
                              setBase64Data={setBase64Data}
                              imagesToUpload={imagesToUpload}
                              setImagesToUpload={setImagesToUpload}
                              uploadBtnText="Confirm Selection"
                              onConfirm={handleSelectionConfirm}
                            />
                            {attachmentsConfirmed &&
                              imagesToUpload?.length > 0 && (
                                <div className="mt-3">
                                  <div className="d-flex flex-wrap gap-2">
                                    {(imagesToUpload || []).map(
                                      (file, index) => (
                                        <Badge
                                          key={index}
                                          bg="success"
                                          className="d-flex align-items-center gap-2 p-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handlePreviewAttachment(file)
                                          }
                                        >
                                          <i className="ri-file-line"></i>
                                          {file.file_name.length > 20
                                            ? `${file.file_name.substring(
                                                0,
                                                20
                                              )}...`
                                            : file.file_name}
                                        </Badge>
                                      )
                                    )}
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="ms-2"
                                      onClick={() => {
                                        setAttachmentsConfirmed(false);
                                        setImagesToUpload([]);
                                      }}
                                    >
                                      <i className="ri-refresh-line me-1"></i>
                                      Change Selection
                                    </Button>
                                  </div>
                                </div>
                              )}
                            {!attachmentsConfirmed &&
                              selectedFiles.length > 0 && (
                                <div className="text-danger mt-1">
                                  <small>
                                    Please confirm your selection using the
                                    "Confirm Selection" button above
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => navigate("/currentExpenses")}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!!validate() || isSubmitting}
                          className="btn btn-primary d-flex align-items-center gap-2"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Creating...
                            </>
                          ) : (
                            "Create Expense"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewExpense;
