export const getMonthName = (monthNumber: number): string => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthNumber - 1]; // Subtract 1 because arrays are 0-indexed
};

export function formatDateString(dateString: string) {
  let dateObject = new Date(dateString);
  let formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return formattedDate;
}

export function formatDateTime(dateString: string) {
  let dateObject = new Date(dateString);
  let formattedDateTime = dateObject.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return formattedDateTime;
}

export function formatDate(date: Date) {
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

//datetime with timezone
export function formatDateTimeWithTimezone(
  dateString: string,
  timezone: string
) {
  let dateObject = new Date(dateString);
  return dateObject.toLocaleString("en-US", {
    timeZoneName: "short",
    timeZone: timezone,
  });
}

//convert from float to date
export function convertFloatToDate(floatDate: number) {
  return new Date(floatDate);
}

//convert from date to float
export function convertDateToFloat(date: Date) {
  return date.getTime();
}
