import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  ExpenseModel,
  ExpenseUpdate,
} from "../../../../types/expenses/expenseTypes";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import { getIconUrl } from "../../../../constants/iconUrlMap";
import LoaderView from "../../../reusable/loader/loaderView";
import {
  fetchExpenseById,
  updateExpense,
  deleteExpense,
} from "../../../../services/expenses/expenseServiceManagement";
import { toast } from "react-toastify";
import {
  fetchBudgetCategoryById,
  fetchLatestBudget,
} from "services/budgets/budgetServiceManagement";
import { Modal } from "react-bootstrap";
import { devLog } from "utils/logger";
import config from "../../../../constants/config";
import Input from "../../../reusable/form/Input";
import MultiLineInput from "../../../reusable/form/multilineinput";
import BudgetAllocationSelect, {
  BudgetSelectOption,
} from "../../../reusable/form/BudgetAllocationSelect";
import Joi from "joi-browser";
import { convertFloatToDate, formatDateTime } from "utils/dateUtils";

interface ExpenseDetailProps {
  isDarkMode: boolean;
}

interface FormErrors {
  [key: string]: string;
}

interface SchemaType {
  [key: string]: any;
}

interface BudgetCategory {
  budget_id: string;
  budget_category_id: string;
  category_name: string;
  total_allocated_for_property: number;
  amount_left: number;
  category_icon?: string;
}

const ExpenseDetail: React.FC<ExpenseDetailProps> = ({ isDarkMode }) => {
  const { expenseId } = useParams();
  const [expense, setExpense] = useState<ExpenseModel | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryName, setCategoryName] = useState<string>("Uncategorized");
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    type: string;
  } | null>(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editedExpense, setEditedExpense] = useState<ExpenseModel | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const [budgetAllocationSelectorOptions, setBudgetAllocationSelectorOptions] =
    useState<BudgetSelectOption[]>([]);
  const [selectedBudgetAllocatedOption, setSelectedBudgetAllocatedOption] =
    useState<BudgetSelectOption | null>(null);
  const navigate = useNavigate();

  const schema: SchemaType = {
    day_due_each_month: Joi.number().required().label("Day Due Each Month"),
    expense_amt: Joi.number().required().label("Expense Amount"),
    expense_description: Joi.string().required().label("Expense Description"),
    expense_name: Joi.string().required().label("Expense Name"),
    has_insight: Joi.boolean().optional().label("Has Insight"),
    is_re_occuring: Joi.boolean().optional().label("Is Re Occuring"),
    record_time_stamp: Joi.number().allow(0).label("Record Time Stamp"),
    user_pal_id: Joi.string().required().label("User Pal Id"),
    expense_id: Joi.string().allow("").label("Expense Id"),
    budget_category_id: Joi.string().optional().label("Budget Category"),
    currency_name: Joi.string().optional().label("Currency Name").allow(""),
    currency_symbol: Joi.string().optional().label("Currency Symbol").allow(""),
    receipt_uploads: Joi.array().optional().label("Receipt Uploads"),
    is_deleted: Joi.boolean().optional().label("Is Deleted").default(false),
  };

  useEffect(() => {
    const fetchExpenseDetails = async () => {
      try {
        setIsLoading(true);
        if (!expenseId) {
          toast.error("No expense ID provided");
          return;
        }
        const data = await fetchExpenseById(expenseId);
        setExpense(data);
        devLog("data", data);
        // Fetch category name if category ID exists
        if (data.budget_category_id) {
          try {
            const categoryData = await fetchBudgetCategoryById(
              data.budget_category_id
            );
            setCategoryName(categoryData.category_name);
          } catch (error) {
            console.error("Error fetching budget category:", error);
            setCategoryName("Unknown Category");
          }
        }
      } catch (error) {
        console.error("Error fetching expense details:", error);
        toast.error("Failed to load expense details");
      } finally {
        setIsLoading(false);
      }
    };

    if (expenseId) {
      fetchExpenseDetails();
    }
  }, [expenseId]);

  useEffect(() => {
    const fetchBudgetCategories = async () => {
      try {
        const budget = await fetchLatestBudget();
        if (budget) {
          const options = budget.budget_categories.map(
            (category: BudgetCategory) => ({
              value: category.budget_category_id,
              label: category.category_name,
              amount_left: category.amount_left,
              total_allocated: category.total_allocated_for_property,
              icon: category.category_icon || "BudgetExpense",
            })
          );
          setBudgetAllocationSelectorOptions(options);

          // Set the current budget category if expense exists
          if (expense?.budget_category_id) {
            const currentCategory = options.find(
              (opt: BudgetSelectOption) =>
                opt.value === expense.budget_category_id
            );
            if (currentCategory) {
              setSelectedBudgetAllocatedOption(currentCategory);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching budget categories:", error);
        toast.error("Failed to fetch budget categories");
      }
    };

    fetchBudgetCategories();
  }, [expense]);

  const validate = (): FormErrors | null => {
    const options = { abortEarly: false };
    if (!editedExpense) return null;

    const { error } = Joi.validate(editedExpense, schema, options);
    if (!error) return null;

    const errors: FormErrors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }): string | null => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleEditClick = () => {
    if (expense) {
      const expenseWithUserId = {
        ...expense,
        user_pal_id:
          expense.user_pal_id || localStorage.getItem(config.user_id) || "",
      };
      setEditedExpense(expenseWithUserId);
      setIsEditing(true);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (!editedExpense) return;

    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    const errorMessage = validateProperty({ name, value: newValue });
    const newErrors = { ...errors };
    if (errorMessage) {
      newErrors[name] = errorMessage;
    } else {
      delete newErrors[name];
    }

    setEditedExpense({
      ...editedExpense,
      [name]: newValue,
    });
    setErrors(newErrors);
  };

  const handleBudgetAllocatedChange = (option: BudgetSelectOption | null) => {
    if (option && editedExpense) {
      setSelectedBudgetAllocatedOption(option);
      setEditedExpense({
        ...editedExpense,
        budget_category_id: option.value,
      });
    }
  };

  const handleSaveEdit = async () => {
    if (!editedExpense || !expenseId) {
      toast.error("Missing expense data");
      return;
    }

    const errors = validate();
    if (errors) {
      setErrors(errors);
      for (const error in errors) {
        if (errors.hasOwnProperty(error)) {
          toast.error(errors[error]);
        }
      }
      return;
    }

    try {
      const updatedExpense = await updateExpense(editedExpense);
      setExpense(updatedExpense);
      setIsEditing(false);
      toast.success("Expense updated successfully");
    } catch (error) {
      console.error("Error updating expense:", error);
      toast.error("Failed to update expense");
    }
  };

  const handleDelete = async () => {
    if (!expense?.expense_id) return;

    try {
      await deleteExpense(expense.expense_id);
      toast.success("Expense deleted successfully");
      navigate("/currentExpenses");
    } catch (error) {
      console.error("Error deleting expense:", error);
      toast.error("Failed to delete expense");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const formatCurrency = (amount: number = 0): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  if (isLoading) {
    return <LoaderView />;
  }

  if (!expense) {
    return (
      <div className="container-fluid">
        <PageTitleBox
          pageTitle="Expense Details"
          isDarkMode={isDarkMode}
          previousPageLink="/currentExpenses"
          previousPageTitle="Back to Expenses"
        />
        <div className="alert alert-info">Expense not found</div>
      </div>
    );
  }

  return (
    <div
      className="container-fluid"
      data-bs-theme={isDarkMode ? "dark" : "light"}
    >
      <PageTitleBox
        pageTitle="Expense Details"
        isDarkMode={isDarkMode}
        previousPageTitle="Back to Expenses"
        previousPageLink="/currentExpenses"
      />

      <div className="row g-3">
        {/* Left Column - Main Expense Info */}
        <div className="col-lg-9">
          <div className="card h-100">
            <div className="card-body">
              {/* Header with Icon and Amount */}
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={getIconUrl(expense.expense_icon || "").url}
                    alt="expense icon"
                    className="rounded-circle"
                    width="48"
                    height="48"
                  />
                  <div className="ms-3">
                    <h4 className="mb-0">{expense.expense_name}</h4>
                    <p className="text-muted mb-0">
                      {expense.is_re_occuring
                        ? "Recurring Expense"
                        : "One-time Expense"}
                    </p>
                  </div>
                </div>
                <div className="text-end">
                  <h2 className="mb-0" style={{ color: "var(--vz-danger)" }}>
                    {formatCurrency(expense.expense_amt)}
                  </h2>
                  {expense.day_due_each_month !== 0 && (
                    <small className="text-muted">
                      Due: {expense.day_due_each_month} of each month
                    </small>
                  )}
                </div>
              </div>

              {/* Description */}
              {expense.expense_description && (
                <div className="mb-4">
                  <h5 className="card-title">Description</h5>
                  <p className="card-text">{expense.expense_description}</p>
                </div>
              )}

              {/* Details Grid */}
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="p-3 border rounded bg-light-subtle">
                    <h6 className="mb-2">Category</h6>
                    <div className="d-flex align-items-center">
                      <i className="ri-folder-line me-2"></i>
                      <span>{categoryName}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-3 border rounded bg-light-subtle">
                    <h6 className="mb-2">Created On</h6>
                    <div className="d-flex align-items-center">
                      <i className="ri-calendar-line me-2"></i>
                      <span>
                        {expense.updated_time_stamp
                          ? formatDateTime(
                              expense.updated_time_stamp.toString()
                            )
                          : expense.record_time_stamp &&
                            convertFloatToDate(
                              expense.record_time_stamp
                            ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Receipts Section */}
              {expense?.receipt_uploads &&
                expense.receipt_uploads.length > 0 && (
                  <div className="mt-4">
                    <h5 className="card-title">Receipts</h5>
                    <div className="row g-3">
                      {expense.receipt_uploads.map((receipt, index) => {
                        const isPDF = receipt.file_type
                          ?.toLowerCase()
                          .includes("pdf");
                        return (
                          <div key={index} className="col-md-3 col-sm-4 col-6">
                            <div
                              onClick={() => {
                                if (receipt.url) {
                                  setSelectedFile({
                                    url: receipt.url,
                                    type: receipt.file_type || "",
                                  });
                                  setShowFileModal(true);
                                }
                              }}
                              className="d-block cursor-pointer"
                              style={{ cursor: "pointer" }}
                            >
                              {isPDF ? (
                                <div className="p-3 border rounded bg-light-subtle text-center">
                                  <i
                                    className="ri-file-pdf-line"
                                    style={{
                                      fontSize: "2rem",
                                      color: "#dc3545",
                                    }}
                                  ></i>
                                  <p className="mb-0 mt-2 small text-truncate">
                                    {receipt.file_name ||
                                      `Receipt ${index + 1}`}
                                  </p>
                                </div>
                              ) : (
                                <img
                                  src={receipt.url}
                                  alt={`Receipt ${index + 1}`}
                                  className="img-fluid rounded shadow-sm"
                                  style={{
                                    objectFit: "cover",
                                    height: "160px",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* Right Column - Actions */}
        <div className="col-lg-3">
          <div className="card sticky-top" style={{ top: "1rem" }}>
            <div className="card-body">
              <h5 className="card-title mb-3">Actions</h5>
              <div className="d-grid gap-2">
                <Link
                  to={`/expense/edit/${expenseId}`}
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                >
                  <i className="ri-edit-line me-2"></i> Edit Expense
                </Link>
                <button
                  className="btn btn-danger d-flex align-items-center justify-content-center"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <i className="ri-delete-bin-line me-2"></i> Delete Expense
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal
        show={isEditing}
        onHide={() => setIsEditing(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editedExpense && (
            <div className="row gy-4">
              <div className="col-md-6">
                <Input
                  name="expense_name"
                  label="Expense Name"
                  value={editedExpense.expense_name || ""}
                  onChange={handleInputChange}
                  error={errors.expense_name}
                  type="text"
                  placeholder="Enter expense name"
                  labelClass=""
                  isFocused={false}
                />
              </div>

              <div className="col-md-6">
                <Input
                  name="expense_amt"
                  label="Expense Amount"
                  value={editedExpense.expense_amt || 0}
                  onChange={handleInputChange}
                  error={errors.expense_amt}
                  type="number"
                  placeholder="Enter amount"
                  labelClass=""
                  isFocused={false}
                />
              </div>

              <div className="col-md-12">
                <BudgetAllocationSelect
                  value={selectedBudgetAllocatedOption}
                  onChange={handleBudgetAllocatedChange}
                  options={budgetAllocationSelectorOptions}
                  formatCurrency={formatCurrency}
                  isDarkMode={isDarkMode}
                  expense_amount={editedExpense.expense_amt || 0}
                />
              </div>

              <div className="col-md-6">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="is_re_occuring"
                    name="is_re_occuring"
                    checked={editedExpense.is_re_occuring || false}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="is_re_occuring">
                    Recurring Expense
                  </label>
                </div>
              </div>

              {editedExpense.is_re_occuring && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="day_due_each_month">
                      Monthly Payment Date
                    </label>
                    <select
                      className="form-select"
                      name="day_due_each_month"
                      value={editedExpense.day_due_each_month || 0}
                      onChange={handleInputChange}
                    >
                      <option value="">When is this payment due?</option>
                      {Array.from({ length: 31 }, (_, i) => i + 1).map(
                        (day) => (
                          <option key={day} value={day}>
                            {day +
                              (day === 1
                                ? "st"
                                : day === 2
                                ? "nd"
                                : day === 3
                                ? "rd"
                                : "th")}{" "}
                            day of the month
                          </option>
                        )
                      )}
                    </select>
                    {errors.day_due_each_month && (
                      <div className="invalid-feedback d-block">
                        {errors.day_due_each_month}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <MultiLineInput
                  name="expense_description"
                  label="Expense Description"
                  value={editedExpense.expense_description || ""}
                  onChange={handleInputChange}
                  error={errors.expense_description}
                  placeholder="Enter description"
                  labelClass=""
                  numOfRows={3}
                  isRequired={true}
                  iconClassName=""
                  type="text"
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSaveEdit}
            disabled={!!validate()}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this expense?</p>
          <div className="alert alert-warning">
            <i className="ri-alert-line me-2"></i>
            This action cannot be undone.
          </div>
          {expense?.is_re_occuring && (
            <div className="alert alert-info">
              <i className="ri-information-line me-2"></i>
              This is a recurring expense. Deleting it will stop future
              payments.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleDelete}>
            Delete Expense
          </button>
        </Modal.Footer>
      </Modal>

      {/* File Preview Modal */}
      <Modal
        show={showFileModal}
        onHide={() => setShowFileModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Receipt{" "}
            {selectedFile?.type?.toLowerCase().includes("pdf")
              ? "PDF"
              : "Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {selectedFile &&
            (selectedFile.type.toLowerCase().includes("pdf") ? (
              <iframe
                src={selectedFile.url}
                style={{ width: "100%", height: "85vh", border: "none" }}
                title="PDF Viewer"
              />
            ) : (
              <img
                src={selectedFile.url}
                alt="Receipt"
                className="img-fluid w-100"
                style={{ maxHeight: "85vh", objectFit: "contain" }}
              />
            ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ExpenseDetail;
