import React from "react";
import { base64ImageString } from "../../../../../constants/images/defaultImgs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { respondToPalRequest } from "../../../../../services/palz/palzManagement";
import { PalDetail } from "../types";

interface PalCoCardProps {
  pal: PalDetail;
}

interface PalResponseDetail {
  pal_id: string;
  secondary_pal_accepted_request: boolean;
  secondary_pal_id: string;
  date_link_created: string;
  has_access: boolean;
}

const PalCoCard: React.FC<PalCoCardProps> = ({ pal }): JSX.Element => {
  const navigate = useNavigate();

  const handleReject = async (): Promise<void> => {
    const palResponseDetail: PalResponseDetail = {
      pal_id: pal.pal_id,
      secondary_pal_accepted_request: false,
      secondary_pal_id: "",
      date_link_created: "",
      has_access: false,
    };

    try {
      const response = await respondToPalRequest(palResponseDetail);
      if (!response.isError) {
        toast.success("Pal request rejected");
        navigate("/approvedPalz");
      } else {
        toast.error("Error rejecting pal request");
      }
    } catch (error) {
      console.error("Error in handleReject:", error);
      toast.error("An unexpected error occurred");
    }
  };

  const formatDate = (date: number | Date | string): string => {
    try {
      let dateObject: Date;

      if (typeof date === "number") {
        dateObject = new Date(date * 1000);
      } else if (typeof date === "string") {
        dateObject = new Date(date);
      } else {
        dateObject = date;
      }

      if (dateObject instanceof Date && !isNaN(dateObject.getTime())) {
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return dateObject.toLocaleDateString(undefined, options);
      }

      return "Invalid Date";
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const renderProfileImage = (
    imageData: string | undefined,
    altText: string
  ): JSX.Element => (
    <img
      src={
        imageData
          ? `data:image/jpeg;base64,${imageData}`
          : `data:image/jpeg;base64,${base64ImageString}`
      }
      alt={altText}
      className="rounded-circle"
      style={{ width: "35px", height: "40px" }}
    />
  );

  return (
    <div className="card px-4 p-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column align-items-center">
          {renderProfileImage(pal.pal_profile_image, "Pal Profile")}
          <p className="mb-0">{`${pal.pal_first_name} ${pal.pal_last_name}`}</p>
        </div>

        <div className="block text-center">
          <p className="mb-0">Palz since</p>
          <p className="mb-0">{formatDate(pal.date_link_created)}</p>

          {pal.has_access && (
            <button
              onClick={handleReject}
              className="btn btn-danger btn-sm mt-2"
              type="button"
            >
              <i className="mdi mdi-link-variant me-1" />
              Unlink
            </button>
          )}
        </div>

        <div className="d-flex flex-column align-items-center">
          {renderProfileImage(pal.requestor_profile_image, "Requestor Profile")}
          <p className="mb-0">{`${pal.requestor_first_name} ${pal.requestor_last_name}`}</p>
        </div>
      </div>
    </div>
  );
};

export default PalCoCard;
