import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const BudgetBreakdownCard = ({
  budgetExpenseChartData,
  isDarkMode,
  lottieAnimation,
}) => {
  const [showAllLegends, setShowAllLegends] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);

  // Check if we have many legend items
  const hasManyItems =
    budgetExpenseChartData &&
    budgetExpenseChartData.labels &&
    budgetExpenseChartData.labels.length > 8;

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "65%",
    radius: "100%",
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 12,
          padding: 10,
          color: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
          font: {
            size: 12,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
        maxItems: showAllLegends ? 100 : 8,
        maxHeight: showAllLegends ? 200 : 80,
        onClick: function (e, legendItem, legend) {
          // Keep default behavior
          const index = legendItem.index;
          const ci = legend.chart;

          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }
        },
      },
    },
  };

  const fullScreenChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        ...chartOptions.plugins.legend,
        position: "right",
        labels: {
          ...chartOptions.plugins.legend.labels,
          font: {
            size: 14,
          },
          padding: 20,
        },
        maxHeight: "100%",
      },
    },
  };

  return (
    <>
      <div className="card card-height-50 shadow-sm">
        <div className="card-header align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Monthly Budget Breakdown
          </h4>
          <div className="flex-shrink-0 d-flex align-items-center">
            {hasManyItems && (
              <button
                className="btn btn-sm btn-outline-secondary me-2"
                onClick={() => setShowAllLegends(!showAllLegends)}
              >
                {showAllLegends ? "Show Less" : "View All"}
              </button>
            )}
            <Button
              variant="light"
              size="sm"
              className="btn-icon"
              onClick={() => setShowFullScreen(true)}
              title="Full Screen"
            >
              <i className="bx bx-fullscreen fs-4"></i>
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div
            className="compact-chart-container"
            style={{
              maxHeight: showAllLegends && hasManyItems ? "450px" : "350px",
            }}
          >
            {budgetExpenseChartData &&
            budgetExpenseChartData.labels &&
            budgetExpenseChartData.labels.length > 0 ? (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div
                  className="compact-chart"
                  style={{
                    width: "100%",
                    height: showAllLegends && hasManyItems ? "220px" : "280px",
                    margin: "0 auto",
                  }}
                >
                  <Doughnut
                    data={budgetExpenseChartData}
                    options={chartOptions}
                  />
                </div>
              </div>
            ) : (
              <div className="row compact-animation">
                <Lottie options={lottieAnimation} height={250} width={250} />
                <div className="text-center">
                  <h3>No Budget Found for the current Period.</h3>
                  <span>An active budget is required.</span>
                </div>
                <div className="mt-3 text-center">
                  <Link to={"/createNewBudget"} className="btn btn-success">
                    Create new Budget
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showFullScreen}
        onHide={() => setShowFullScreen(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Budget Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "70vh", position: "relative" }}>
            <Doughnut
              data={budgetExpenseChartData}
              options={fullScreenChartOptions}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BudgetBreakdownCard;
