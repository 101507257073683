import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Calendar } from "rsuite";
import { Modal, Badge, Button } from "react-bootstrap";
import {
  ExpenseModel,
  CalendarItem,
} from "../../../../types/expenses/expenseTypes";
//import 'rsuite/Calendar/styles/index.css';

interface ExpenseCompactCalendarViewProps {
  expensesAndTransactions: ExpenseModel[];
}

const ExpenseCompactCalendarView: React.FC<ExpenseCompactCalendarViewProps> = ({
  expensesAndTransactions,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalItems, setModalItems] = useState<CalendarItem[]>([]);
  const [modalDate, setModalDate] = useState<string>("");
  const date = new Date();
  const month = date.toLocaleString("default", {
    month: "long",
  });
  const year = date.getFullYear();

  function getTodoList(date: Date): CalendarItem[] {
    const day = date.getDate();
    const todaysExpenses = expensesAndTransactions.filter(
      (item) => item.day_due_each_month === day
    );

    // Create a map to store unique expenses by name
    const uniqueExpenses = new Map<string, CalendarItem>();

    todaysExpenses.forEach((item) => {
      const existingExpense = uniqueExpenses.get(item.expense_name || "");
      if (existingExpense) {
        // If expense with same name exists, update the amount
        existingExpense.expenseAmt += item.expense_amt || 0;
      } else {
        // Add new unique expense
        uniqueExpenses.set(item.expense_name || "", {
          expenseId: item.expense_id || "",
          dayDueEachMonth: `${item.day_due_each_month}`,
          expenseName: item.expense_name || "",
          expenseAmt: item.expense_amt || 0,
          expenseDescription: item.expense_description || "",
          budgetCategoryId: item.budget_category_id,
        });
      }
    });

    return Array.from(uniqueExpenses.values());
  }

  function renderCell(date: Date) {
    const list = getTodoList(date);
    if (list.length) {
      // Use the number of unique expenses to determine dot count
      let dotCount = Math.min(list.length, 3); // Cap at 3 dots

      return (
        <div
          className="calendar-cell-content"
          onClick={() => {
            setModalItems(list);
            setModalDate(`${date.getDate()} ${month} ${year}`);
            setShowModal(true);
          }}
        >
          <div className="expense-indicator">
            {[...Array(dotCount)].map((_, index) => (
              <div key={index} className="expense-dot"></div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }

  const handleClose = () => setShowModal(false);

  return (
    <div className="">
      <div className=" p-2">
        <Calendar
          compact
          bordered
          renderCell={renderCell}
          className="compact-calendar"
        />

        <Modal show={showModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton className="bg-info-subtle">
            <Modal.Title>
              <i className="ri-calendar-event-line me-2"></i>
              Expenses for {modalDate}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalItems.length > 0 ? (
              <div className="expense-list">
                {modalItems.map((item, index) => (
                  <div key={index} className="expense-item">
                    <div className="expense-header">
                      <h6 className="expense-title">{item.expenseName}</h6>
                      <span className="expense-amount-badge">
                        ${item.expenseAmt.toFixed(2)}
                      </span>
                    </div>
                    {item.expenseDescription && (
                      <p className="expense-description">
                        {item.expenseDescription}
                      </p>
                    )}
                    {item.budgetCategoryId && (
                      <div className="text-end">
                        <Link
                          to={`/expense/${item.expenseId}`}
                          className="btn btn-sm btn-outline-primary"
                        >
                          <i className="ri-eye-line me-1"></i>
                          View Details
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-4">
                <i className="ri-calendar-todo-line fs-2 text-muted"></i>
                <p className="text-muted mt-2">No expenses for this date.</p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>

      <style>
        {`
          .compact-calendar {
            --rs-calendar-cell-size: 45px;
            --rs-calendar-cell-padding: 2px;
          }

          .expense-cell-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;
          }

          .expense-indicator {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
            padding-bottom: 4px;
          }

          .expense-dot {
            width: 4px;
            height: 4px;
            background-color: #3498db;
            border-radius: 50%;
          }

          /* Calendar cell structure */
          .rs-calendar-table-cell {
            height: 45px !important;
            padding: 0 !important;
            position: relative;
          }

          .rs-calendar-table-cell-content {
            height: 100% !important;
            padding: 0 !important;
            cursor: pointer;
          }

          .calendar-cell-content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 100%;
            position: relative;
            padding-top: 2px;
          }

          /* Date number positioning */
          .rs-calendar-table-cell-day {
            position: relative;
            z-index: 1;
            margin-bottom: 14px;
          }

          /* Hover effect - only on the specific cell */
          .rs-calendar-table-cell-content:hover {
            background-color: rgba(52, 152, 219, 0.1);
          }

          .expense-amount {
            font-size: 0.7rem;
            color: #2c3e50;
            font-weight: 500;
          }

          .expense-list {
            max-height: 400px;
            overflow-y: auto;
          }

          .expense-item {
            padding: 16px;
            border-bottom: 1px solid #eee;
            transition: background-color 0.2s;
          }

          .expense-item:last-child {
            border-bottom: none;
          }

          .expense-item:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }

          .expense-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }

          .expense-title {
            margin: 0;
            color: #2c3e50;
            font-weight: 600;
            font-size: 1rem;
          }

          .expense-amount-badge {
            padding: 4px 8px;
            background-color: #e8f5e9;
            color: #2e7d32;
            border-radius: 4px;
            font-weight: 500;
            font-size: 0.875rem;
          }

          .expense-description {
            color: #7f8c8d;
            font-size: 0.875rem;
            margin: 0 0 12px 0;
          }
        `}
      </style>
    </div>
  );
};

export default ExpenseCompactCalendarView;
