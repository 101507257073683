import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerControlProps {
  name: string;
  label?: string;
  selected: Date;
  onChange: (date: Date | null) => void;
  isDarkMode: boolean;
  error?: string;
  required?: boolean;
  placeholder?: string;
  dateFormat?: string;
}

const DatePickerControl: React.FC<DatePickerControlProps> = ({
  name,
  label,
  selected,
  onChange,
  isDarkMode,
  error,
  required = false,
  placeholder = "Select date...",
  dateFormat = "MM/dd/yyyy",
}) => {
  return (
    <div className="col-12">
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <DatePicker
        name={name}
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        className="form-control"
        wrapperClassName="w-100"
        placeholderText={placeholder}
        popperClassName={isDarkMode ? "react-datepicker-dark" : ""}
        popperPlacement="bottom-start"
        customInput={
          <input
            className="form-control"
            style={{
              backgroundColor: isDarkMode ? "#31363c" : "#fff",
              color: isDarkMode ? "#e9ecef" : "inherit",
              border: `1px solid ${isDarkMode ? "#3c3f42" : "#ced4da"}`,
              height: "38px",
              width: "100%",
              borderRadius: "0.25rem",
              padding: "0.375rem 0.75rem",
            }}
          />
        }
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
      <style>
        {`
        .react-datepicker-wrapper {
          display: block;
          width: 100%;
        }
        .react-datepicker__input-container {
          display: block;
          width: 100%;
        }
        .react-datepicker-dark .react-datepicker {
          background-color: #292e33;
          border-color: #3c3f42;
          font-family: inherit;
          font-size: 0.875rem;
        }
        .react-datepicker-dark .react-datepicker__header {
          background-color: #31363c;
          border-bottom-color: #3c3f42;
          padding: 0.5rem;
        }
        .react-datepicker-dark .react-datepicker__day {
          color: #e9ecef;
          width: 2rem;
          line-height: 2rem;
          margin: 0.166rem;
        }
        .react-datepicker-dark .react-datepicker__day:hover {
          background-color: #405189;
        }
        .react-datepicker-dark .react-datepicker__day--selected,
        .react-datepicker-dark .react-datepicker__day--keyboard-selected {
          background-color: #405189;
          color: #fff;
        }
        .react-datepicker-dark .react-datepicker__day-name {
          color: #adb5bd;
          width: 2rem;
          line-height: 2rem;
          margin: 0.166rem;
        }
        .react-datepicker-dark .react-datepicker__current-month {
          color: #e9ecef;
          font-weight: 500;
          font-size: 0.875rem;
          padding: 0.25rem 0;
        }
        .react-datepicker-dark .react-datepicker__navigation-icon::before {
          border-color: #adb5bd;
        }
        .react-datepicker-dark .react-datepicker__navigation:hover *::before {
          border-color: #e9ecef;
        }
        `}
      </style>
    </div>
  );
};

export default DatePickerControl;
