import React from "react";
import Select from "react-select";
import { getIconUrl, iconUrlMap } from "../../../constants/iconUrlMap";

interface IconOption {
  value: string;
  label: string;
  url: string;
}

interface IconSelectorProps {
  name: string;
  label?: string;
  value: IconOption | null;
  onChange: (selectedOption: IconOption | null) => void;
  isDarkMode: boolean;
  error?: string;
  required?: boolean;
}

const IconSelector: React.FC<IconSelectorProps> = ({
  name,
  label = "Select Icon",
  value,
  onChange,
  isDarkMode,
  error,
  required = false,
}) => {
  const iconSelectOptions = Object.entries(iconUrlMap).map(([key, data]) => ({
    value: key,
    label: data.val,
    url: data.url,
  }));

  const capitalize = (str: string): string => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Format for the dropdown options (grid items)
  const formatOptionLabel = ({ url }: IconOption) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={url} alt="" style={{ width: "24px", height: "24px" }} />
    </div>
  );

  return (
    <div className="col-12">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        options={iconSelectOptions}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: isDarkMode
              ? "#31363c"
              : baseStyles.backgroundColor,
            borderColor: isDarkMode ? "#3c3f42" : baseStyles.borderColor,
            boxShadow: state.isFocused
              ? isDarkMode
                ? "0 0 0 1px #405189"
                : baseStyles.boxShadow
              : "none",
            "&:hover": {
              borderColor: isDarkMode ? "#4a4d50" : baseStyles.borderColor,
            },
            height: "38px",
            minHeight: "38px",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: isDarkMode
              ? "#292e33"
              : baseStyles.backgroundColor,
            borderColor: isDarkMode ? "#3c3f42" : baseStyles.borderColor,
            maxHeight: "250px",
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            padding: "8px",
            maxHeight: "220px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: isDarkMode
                ? "rgba(255, 255, 255, 0.05)"
                : "rgba(0, 0, 0, 0.05)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: isDarkMode
                ? "rgba(255, 255, 255, 0.2)"
                : "rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              "&:hover": {
                background: isDarkMode
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.3)",
              },
            },
          }),
          option: (baseStyles, { isFocused, isSelected }) => ({
            ...baseStyles,
            backgroundColor: isSelected
              ? isDarkMode
                ? "#405189"
                : "#e6f0ff"
              : isFocused
              ? isDarkMode
                ? "#2f343a"
                : "#f0f0f0"
              : isDarkMode
              ? "#292e33"
              : baseStyles.backgroundColor,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
            ":active": {
              backgroundColor: isDarkMode
                ? "#405189"
                : baseStyles[":active"]?.backgroundColor,
            },
            padding: "4px",
            margin: "2px",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#adb5bd" : baseStyles.color,
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#adb5bd" : baseStyles.color,
            "&:hover": {
              color: isDarkMode ? "#e9ecef" : "#6c757d",
            },
            padding: "4px",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0 8px",
            height: "36px",
            display: "flex",
            alignItems: "center",
          }),
        }}
        isSearchable={false}
        components={{
          SingleValue: ({ children, ...props }) => {
            const { data } = props;
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  padding: "0",
                }}
              >
                <img
                  src={data.url}
                  alt={data.label}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: isDarkMode ? "#e9ecef" : "inherit",
                  }}
                >
                  {capitalize(data.value.split(/(?=[A-Z])/).join(" "))}
                </span>
              </div>
            );
          },
        }}
        placeholder="Select an icon..."
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

export default IconSelector;
