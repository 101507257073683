import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { getIconUrl, iconUrlMap } from "../../../../constants/iconUrlMap";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { budgetGoalCategories } from "../../../../constants/budgetGoal/budgetGoalCategories";
import config from "../../../../constants/config";
import { createBudgetGoal } from "../../../../services/budgetGoal/budgetGoalManagement";
import LoaderView from "../../../reusable/loader/loaderView";
import IconSelector from "../../../reusable/form/IconSelector";
import {
  BudgetGoal,
  BudgetGoalCreate,
  GoalCreatorProfile,
} from "../../../../types/budgetGoal";
import SelectControl from "../../../reusable/form/SelectControl";
import DatePickerControl from "../../../reusable/form/DatePickerControl";

interface CreateNewBudgetGoalProps {
  isDarkMode: boolean;
}

interface FormData {
  goal_date: Date;
  ai_insight_msg: string;
  bank_account_id: string;
  budget_goal_id: string;
  budget_goal_sum_total: number;
  current_amt_saved: number;
  goal_category_icon: string;
  goal_creator_profile: Partial<GoalCreatorProfile>;
  goal_name: string;
  goal_type: string;
  has_achieved_goal: boolean;
  is_deleted: boolean;
  month: number;
  pals_working_together_towards_goal: string[];
  record_time_stamp: Date;
  target_end_date_raw_date: Date;
  total_left: number;
  user_id: string;
  year: number;
  [key: string]: any;
}

interface IconOption {
  value: string;
  label: string;
  url: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface Errors {
  [key: string]: string;
}

interface InputProps {
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
  labelClass?: string;
  isFocused?: boolean;
  value: any;
  onChange: (input: any) => void;
  type?: string;
  error?: string;
}

const CreateNewBudgetGoal: React.FC<CreateNewBudgetGoalProps> = ({
  isDarkMode,
}) => {
  const pageTitle = "Create New Budget Goal";
  const navigate = useNavigate();
  const [budgetGoalFormData, setBudgetGoalFormData] = useState<FormData>({
    goal_date: new Date(),
    ai_insight_msg: "",
    bank_account_id: "",
    budget_goal_id: "",
    budget_goal_sum_total: 0,
    current_amt_saved: 0,
    goal_category_icon: "",
    goal_creator_profile: {},
    goal_name: "",
    goal_type: "",
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: new Date(),
    target_end_date_raw_date: new Date(),
    total_left: 0,
    user_id: "",
    year: 0,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [iconSelectOptions, setIconSelectOptions] = useState<IconOption[]>([]);
  const [selectedOptionBudgetGoalType, setSelectedOptionBudgetGoalType] =
    useState<SelectOption | null>(null);
  const [selectedOptionBudgetGoalIcon, setSelectedOptionBudgetGoalIcon] =
    useState<IconOption | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema = {
    goal_date: Joi.date().optional().allow("").label("Goal Date"),
    ai_insight_msg: Joi.string()
      .optional()
      .allow("")
      .label("AI Insight Message"),
    bank_account_id: Joi.string().optional().allow("").label("Bank Account ID"),
    budget_goal_id: Joi.string().optional().allow("").label("Budget Goal ID"),
    budget_goal_sum_total: Joi.number()
      .required()
      .label("Budget Goal Sum Total"),
    current_amt_saved: Joi.number().required().label("Current Amount Saved"),
    goal_category_icon: Joi.string()
      .optional()
      .allow("")
      .label("Goal Category Icon"),
    goal_creator_profile: Joi.object()
      .optional()
      .allow("")
      .label("Goal Creator Profile"),
    goal_name: Joi.string().required().label("Goal Name"),
    goal_type: Joi.string().optional().allow("").label("Goal Type"),
    has_achieved_goal: Joi.boolean()
      .optional()
      .allow("")
      .label("Has Achieved Goal"),
    is_deleted: Joi.boolean().optional().allow("").label("Is Deleted"),
    month: Joi.number().required().label("Month"),
    pals_working_together_towards_goal: Joi.array()
      .optional()
      .allow("")
      .label("Pals Working Together Towards Goal"),
    record_time_stamp: Joi.date()
      .optional()
      .allow("")
      .label("Record Time Stamp"),
    target_end_date_raw_date: Joi.date()
      .optional()
      .allow("")
      .label("Target End Date Raw Date"),
    total_left: Joi.number().optional().allow("").label("Total Left"),
    user_id: Joi.string().optional().allow("").label("User ID"),
    year: Joi.number().required().label("Year"),
  };

  const getIconOptions = () => {
    const options = Object.entries(iconUrlMap).map(([key, data]) => ({
      value: key,
      label: data.val,
      url: data.url,
    }));
    setIconSelectOptions(options);
  };

  const validate = (): Errors | null => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFormData, schema, options);
    if (!error) return null;

    const errors: Errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: keyof FormData;
    value: any;
  }): string | null => {
    if (!(name in schema)) return null;
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name as keyof typeof schema] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validate();

    if (errors) {
      Object.values(errors).forEach((error) => {
        toast.error(error);
      });
      setErrors(errors);
      return;
    }

    doSubmit();
  };

  const isFormDataKey = (key: string): boolean => {
    return key in budgetGoalFormData;
  };

  const handleChange = (input: any) => {
    const currentErrors = { ...errors };
    let name = "";
    let value: any;

    if (input?.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else if (input?.value) {
      if (typeof input.value === "string" && !input.label) {
        name = "goal_date";
        value = input.value;

        const date = new Date(value);
        setBudgetGoalFormData((prev) => ({
          ...prev,
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          target_end_date_raw_date: date,
        }));
        return;
      } else if (input.label) {
        name = "goal_category_icon";
        value = input.value;
        setSelectedOptionBudgetGoalIcon(input);
      }
    } else if (input?.name === "goal_date") {
      // Handle direct date changes from DatePicker
      const date = input.value;
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_date: date,
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        target_end_date_raw_date: date,
      }));
      return;
    }

    if (!name || !isFormDataKey(name)) return;

    const errorMessage = validateProperty({
      name: name as keyof FormData,
      value,
    });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    setBudgetGoalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors(currentErrors);
  };

  const handleDateChange = (date: Date | null) => {
    const selectedDate = date || new Date();
    setBudgetGoalFormData((prev) => ({
      ...prev,
      goal_date: selectedDate,
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      target_end_date_raw_date: selectedDate,
    }));
  };

  const doSubmit = async () => {
    try {
      setIsSubmitting(true);
      const userId = localStorage.getItem(config.user_id) || "";

      const submissionData: BudgetGoalCreate = {
        budget_goal_id: uuidv4(),
        bank_account_id: "",
        user_id: userId,
        pals_working_together_towards_goal: [],
        year: Number(budgetGoalFormData.year),
        month: Number(budgetGoalFormData.month),
        target_end_date_raw_date: new Date(
          budgetGoalFormData.target_end_date_raw_date
        ),
        budget_goal_sum_total: Number(budgetGoalFormData.budget_goal_sum_total),
        total_left:
          Number(budgetGoalFormData.budget_goal_sum_total) -
          Number(budgetGoalFormData.current_amt_saved),
        current_amt_saved: Number(budgetGoalFormData.current_amt_saved),
        goal_category_icon: budgetGoalFormData.goal_category_icon,
        goal_type: budgetGoalFormData.goal_type,
        ai_insight_msg: "",
        record_time_stamp: new Date(),
        goal_name: budgetGoalFormData.goal_name,
        has_achieved_goal: false,
        is_deleted: false,
      };

      const response = await createBudgetGoal(submissionData);
      if (response) {
        setIsLoading(false);
        toast.success("Budget Goal created successfully");
        navigate("/allBudgetGoals");
      } else {
        setIsLoading(false);
        toast.error("Failed to create Budget Goal");
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBudgetGoalChangeType = (option: SelectOption | null) => {
    setSelectedOptionBudgetGoalType(option);
    if (option) {
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_type: option.value,
      }));
    }
  };

  const capitalize = (str: string): string => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatOptionLabel = ({ value, label, url }: IconOption) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img src={url} alt={label} style={{ width: "20px", height: "20px" }} />
      <span>{capitalize(value.split(/(?=[A-Z])/).join(" "))}</span>
    </div>
  );

  const selectedIconOption = iconSelectOptions.find(
    (option) => option.value === budgetGoalFormData.goal_category_icon
  );

  const handleIconChange = (selectedOption: any) => {
    if (selectedOption) {
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_category_icon: selectedOption.value,
      }));
      setSelectedOptionBudgetGoalIcon(selectedOption);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getIconOptions();
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageLink="/allBudgetGoals"
            previousPageTitle="All Budget Goals"
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    New Budget Goal
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="goal_name"
                              label="Goal Name"
                              value={budgetGoalFormData.goal_name}
                              onChange={handleChange}
                              error={errors.goal_name}
                              type="text"
                              placeholder="Enter goal name"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <SelectControl
                              name="goal_type"
                              label="Goal Type"
                              value={selectedOptionBudgetGoalType}
                              onChange={handleBudgetGoalChangeType}
                              options={budgetGoalCategories}
                              isDarkMode={isDarkMode}
                              error={errors.goal_type}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="budget_goal_sum_total"
                              label="Budget Goal Sum Total"
                              type="number"
                              value={budgetGoalFormData.budget_goal_sum_total}
                              onChange={handleChange}
                              error={errors.budget_goal_sum_total}
                              placeholder="Enter target amount"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="current_amt_saved"
                              label="Amount Already Saved"
                              type="number"
                              value={budgetGoalFormData.current_amt_saved}
                              onChange={handleChange}
                              error={errors.current_amt_saved}
                              placeholder="Enter amount saved"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <IconSelector
                              name="goal_category_icon"
                              label="Goal Icon"
                              value={selectedOptionBudgetGoalIcon}
                              onChange={handleIconChange}
                              isDarkMode={isDarkMode}
                              error={errors.goal_category_icon}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <DatePickerControl
                              name="goal_date"
                              label="Select Goal Deadline"
                              selected={budgetGoalFormData.goal_date}
                              onChange={handleDateChange}
                              isDarkMode={isDarkMode}
                              error={errors.target_end_date_raw_date}
                              required={true}
                              placeholder="Select deadline"
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => navigate("/allBudgetGoals")}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!!validate() || isSubmitting}
                          className="btn btn-primary d-flex align-items-center gap-2"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Creating...
                            </>
                          ) : (
                            "Create Goal"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateNewBudgetGoal;
