import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoaderView from "../../reusable/loader/loaderView";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../services/helpers/colorService";
import {
  fetchBudgetGoalById,
  fetchBudgetGoalFundsByBudgetGoalId,
  fetchBudgetGoalsByUserId,
} from "../../../services/budgetGoal/budgetGoalManagement";
import BudgetGoalContributionCard from "../../reusable/cards/dashboard/budgetGoalContributionCard";
import BudgetGoalsGrid from "../../reusable/cards/budgetGoal/budgetGoalsGrid";
import BudgetGoalFundsGrid from "../../reusable/cards/budgetGoal/sub/budgetGoalFundsGrid";
import GoalFundsBarChart from "../../reusable/cards/budgetGoal/sub/goalFundsBarChart";
import BudgetGoalCard from "../../reusable/cards/budgetGoal/sub/budgetGoalCard";
import { BudgetGoal, BudgetGoalFunds } from "../../../types/budgetGoal";
import { devLog } from "utils/logger";
import PageTitleBox from "components/reusable/portal/general/pageTitleBox";

interface BudgetGoalDetailProps {
  isDarkMode: boolean;
}
//TODO: next is adding funds then endsure the goals show on the other screens then adding receipts and showing them then push changes to prod
const BudgetGoalDetail: React.FC<BudgetGoalDetailProps> = ({ isDarkMode }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pageTitle = "Budget Goal";
  const previousPageTitle = "Budget Goals";
  const previousPageLink = "/allBudgetGoals";

  const { budgetGoalId } = useParams<{ budgetGoalId: string }>();

  const [progress, setProgress] = useState<number>(0);
  const [amountLeft, setAmountLeft] = useState<number>(0);
  const [barColor, setBarColor] = useState<string>("bg-secondary");
  const [budgetGoal, setBudgetGoal] = useState<BudgetGoal | null>(null);
  const [budgetGoalFunds, setBudgetGoalFunds] = useState<BudgetGoalFunds[]>([]);

  const getBudgetGoal = async (): Promise<void> => {
    try {
      if (!budgetGoalId) return;

      const budgetGoal = await fetchBudgetGoalById(budgetGoalId);
      setBudgetGoal(budgetGoal);

      const budgetGoalFunds = await fetchBudgetGoalFundsByBudgetGoalId(
        budgetGoalId
      );
      if (budgetGoalFunds.length > 0) {
        devLog("Budget goal funds:", budgetGoalFunds);
        setBudgetGoalFunds(budgetGoalFunds);
      }

      setGoalStats(budgetGoal);
    } catch (error) {
      devLog("Error fetching budget goal:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setGoalStats = (goal: BudgetGoal): void => {
    const amountBudgeted = goal.budget_goal_sum_total || 0;
    const amountSpent = goal.current_amt_saved || 0;
    const calculatedAmountLeft = amountBudgeted - amountSpent;
    const calculatedProgress =
      amountBudgeted !== 0 ? (amountSpent / amountBudgeted) * 100 : 0;

    setProgress(calculatedProgress);
    setAmountLeft(calculatedAmountLeft);

    let calculatedBarColor = "bg-secondary";
    if (calculatedProgress >= 95) {
      calculatedBarColor = "bg-danger";
    } else if (calculatedProgress >= 80) {
      calculatedBarColor = "bg-warning";
    } else {
      calculatedBarColor = "bg-success";
    }

    setBarColor(calculatedBarColor);
  };

  useEffect(() => {
    setIsLoading(true);
    getBudgetGoal();
  }, [budgetGoalId]);

  if (!budgetGoal) {
    return isLoading ? <LoaderView /> : null;
  }

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <div className="row px-4">
            <PageTitleBox
              pageTitle={pageTitle}
              previousPageTitle={previousPageTitle}
              previousPageLink={previousPageLink}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className="row">
            <div className="col-xl-4">
              <GoalFundsBarChart
                funds={budgetGoalFunds}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-height-100">
                    <div className="card-body">
                      <BudgetGoalCard
                        goal={budgetGoal}
                        amountLeft={amountLeft}
                        progress={progress}
                        barColor={barColor}
                        isModifyMode={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
        {budgetGoalFunds && budgetGoalFunds.length > 0 && (
          <div className="row">
            <BudgetGoalFundsGrid budgetGoalFunds={budgetGoalFunds} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BudgetGoalDetail;
