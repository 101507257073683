import React, { useState, useEffect } from "react";
import { createCustomerSession } from "../../../services/subscription/subscriptionManagementService";
import config from "../../../constants/config";
import PageTitleBox from "../../reusable/portal/general/pageTitleBox";

const Paywall = ({ isDarkMode }) => {
  const pageTitle = "Subscription";
  const stripeCustomerId = localStorage.getItem(config.stripe_customer_id);
  const email = localStorage.getItem(config.email);
  const [clientDetail, setClientDetail] = useState({
    client_secret: "",
  });

  useEffect(() => {
    startCustomerSession();
  }, []);

  const startCustomerSession = async () => {
    const customerSession = await createCustomerSession();
    //console.log(customerSession);
    setClientDetail(customerSession);
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox pageTitle={pageTitle} />
          {/* end page title */}
          <div className="row">
            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              customer-session-client-secret={clientDetail.client_secret}
            ></stripe-pricing-table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Paywall;
