import React from "react";
import { CategoryMap } from "../../types";
import LoaderView from "../../../../../reusable/loader/loaderView";

interface BudgetByCategoryChartProps {
  budgetByCategory: CategoryMap;
  isLoading?: boolean;
}

// Type for the sorted budget entries
type SortedBudgetEntry = [string, number];

const BudgetByCategoryChart: React.FC<BudgetByCategoryChartProps> = ({
  budgetByCategory,
  isLoading = false,
}) => {
  // Convert the budgetByCategory object to an array and sort it in descending order
  const sortedBudget: SortedBudgetEntry[] = Object.entries(
    budgetByCategory
  ).sort((a, b) => b[1] - a[1]);

  // Calculate the total budget for the progress bar
  const totalBudget: number = sortedBudget.reduce(
    (total, [, budget]) => total + budget,
    0
  );

  // Define color arrays outside of the render loops for better performance
  const bgColors = [
    "bg-primary",
    "bg-info",
    "bg-success",
    "bg-warning",
    "bg-danger",
  ];
  const textColors = [
    "text-primary",
    "text-info",
    "text-success",
    "text-warning",
    "text-danger",
  ];

  return (
    <div className="card card-height-100">
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Budget By Category</h4>
      </div>
      <div className="card-body">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <LoaderView />
          </div>
        ) : (
          <>
            <div className="row align-items-center">
              <div className="col-6">
                <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                  Total Budget
                </h6>
                <h4 className="mb-0">${Number(totalBudget).toFixed(2)}</h4>
              </div>
            </div>
            <div className="mt-3 pt-2">
              <div className="progress progress-lg rounded-pill">
                {sortedBudget.map(([category, budget], index) => {
                  const progress: number = (budget / totalBudget) * 100;
                  const barColor = bgColors[index % bgColors.length];

                  return (
                    <div
                      key={category}
                      className={`progress-bar ${barColor}`}
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  );
                })}
              </div>
            </div>
            <div className="mt-3 pt-2">
              {sortedBudget.map(([category, budget], index) => {
                const progress: number = (budget / totalBudget) * 100;
                const textColor = textColors[index % textColors.length];

                return (
                  <div className="d-flex mb-2" key={category}>
                    <div className="flex-grow-1">
                      <p className="text-truncate text-muted fs-14 mb-0">
                        <i
                          className={`mdi mdi-circle align-middle ${textColor} me-2`}
                        ></i>
                        {category.toLocaleUpperCase()}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0">{progress.toFixed(2)}%</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BudgetByCategoryChart;
