import React, { useState, useEffect } from "react";
import { Calendar, Badge } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import { fetchAllExpensesForLoggedInUser } from "../../../../services/expenses/expenseServiceManagement";
import LoaderView from "../../../reusable/loader/loaderView";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
  ExpenseModel,
  CalendarItem,
} from "../../../../types/expenses/expenseTypes";
import { useNavigate } from "react-router-dom";
const ExpenseCalendarView: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalItems, setModalItems] = useState<CalendarItem[]>([]);
  const date = new Date();
  const month = date.toLocaleString("default", {
    month: "long",
  });
  const year = date.getFullYear();

  const [expenses, setExpenses] = useState<ExpenseModel[]>([]);

  const getTheLatestExpenses = async () => {
    const fetchedExpenses = await fetchAllExpensesForLoggedInUser();
    setExpenses(fetchedExpenses);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getTheLatestExpenses();
  }, []);

  function getTodoList(date: Date, expenses: ExpenseModel[]): CalendarItem[] {
    const day = date.getDate();

    // Filter the expenses that are due on the given day
    const todaysExpenses = expenses.filter(
      (item) => item.day_due_each_month === day
    );

    // Create a map to store unique expenses by name
    const uniqueExpenses = new Map<string, CalendarItem>();

    todaysExpenses.forEach((item) => {
      const existingExpense = uniqueExpenses.get(item.expense_name || "");
      if (existingExpense) {
        // If expense with same name exists, update the amount
        existingExpense.expenseAmt += item.expense_amt || 0;
      } else {
        // Add new unique expense
        uniqueExpenses.set(item.expense_name || "", {
          expenseId: item.expense_id || "",
          dayDueEachMonth: `${item.day_due_each_month}`,
          expenseName: item.expense_name || "",
          expenseAmt: item.expense_amt || 0,
          expenseDescription: item.expense_description || "",
          budgetCategoryId: item.budget_category_id,
        });
      }
    });

    // Convert map values back to array
    return Array.from(uniqueExpenses.values());
  }

  function renderCell(date: Date) {
    const list = getTodoList(date, expenses);
    const displayList = list.filter((_, index) => index < 3);

    if (list.length) {
      const moreCount = list.length - displayList.length;

      return (
        <div className="calendar-cell-content">
          <div className="calendar-expense-list">
            {displayList.map((item, index) => (
              <div
                key={index}
                className="calendar-expense-item"
                onClick={() => navigate(`/expense/${item.expenseId}`)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="expense-badge"
                  style={{ margin: "auto 8px auto 0" }}
                />
                <div className="expense-info">
                  <div className="expense-name">{item.expenseName}</div>
                  <div className="expense-amount">
                    ${item.expenseAmt.toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            {moreCount > 0 && (
              <div className="calendar-more-item">
                <span className="more-count">+{moreCount} more</span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div className="row pt-4 px-5">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
            <div className="col-md-3">
              <h4 className="mb-sm-0">Expense Calendar</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-4 px-5">
        <div className="card card-h-100 shadow-sm">
          <div className="card-body">
            <Calendar
              bordered
              renderCell={renderCell}
              className="custom-calendar"
              size="lg"
            />
          </div>
        </div>
      </div>

      <style>
        {`
          .custom-calendar {
            --rs-calendar-cell-size: 120px;
            --rs-calendar-cell-padding: 8px;
          }
          
          .calendar-cell-content {
            height: 100%;
            padding: 4px;
            display: flex;
            flex-direction: column;
          }
          
          .calendar-expense-list {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-top: 2px;
          }
          
          .calendar-expense-item {
            display: flex;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: rgba(52, 152, 219, 0.05);
            text-decoration: none;
            color: inherit;
            transition: background-color 0.2s;
          }
          
          .calendar-expense-item:hover {
            background-color: rgba(52, 152, 219, 0.1);
            text-decoration: none;
          }
          
          .expense-badge {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--rs-blue-500);
            flex-shrink: 0;
          }

          .expense-info {
            flex: 1;
            min-width: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
          }
          
          .expense-name {
            font-size: 0.8rem;
            color: var(--rs-text-primary);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .expense-amount {
            font-size: 0.8rem;
            color: var(--rs-blue-600);
            white-space: nowrap;
            font-weight: 500;
          }
          
          .calendar-more-item {
            text-align: right;
            padding: 2px 4px;
          }
          
          .more-count {
            font-size: 0.75rem;
            color: var(--rs-text-secondary);
            font-weight: 500;
          }
          
          .expense-list {
            max-height: 400px;
            overflow-y: auto;
          }
          
          .expense-item {
            padding: 16px;
            border-bottom: 1px solid var(--rs-border-primary);
            transition: background-color 0.2s;
          }
          
          .expense-item:last-child {
            border-bottom: none;
          }
          
          .expense-item:hover {
            background-color: var(--rs-bg-active);
          }
          
          .expense-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }
          
          .expense-title {
            margin: 0;
            color: var(--rs-text-primary);
            font-weight: 600;
          }
          
          .expense-amount-badge {
            padding: 4px 8px;
            background-color: rgba(52, 152, 219, 0.1);
            color: var(--rs-blue-600);
            border-radius: 4px;
            font-weight: 600;
            font-size: 0.875rem;
          }
          
          .expense-description {
            color: var(--rs-text-secondary);
            font-size: 0.875rem;
            margin: 0 0 12px 0;
          }

          /* Calendar cell structure */
          .rs-calendar-table-cell {
            border: 1px solid var(--rs-border-secondary);
          }

          .rs-calendar-table-cell-content {
            height: 100%;
            transition: border-color 0.2s;
          }

          .rs-calendar-table-cell-content:hover {
            border: 2px solid var(--rs-blue-500);
          }

          .rs-calendar-table-cell-day {
            font-weight: 500;
            color: var(--rs-text-primary);
            padding: 4px 8px;
            font-size: 0.85rem;
            border-bottom: 1px solid var(--rs-border-secondary);
          }

          /* Today's date */
          .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
            border: 3px solid var(--rs-blue-500);
            background-color: rgba(52, 152, 219, 0.08);
          }

          .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
            color: var(--rs-blue-700);
            font-weight: 700;
            font-size: 0.9rem;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day::after {
            content: "Today";
            position: absolute;
            right: 8px;
            font-size: 0.75rem;
            color: var(--rs-blue-600);
            background-color: rgba(52, 152, 219, 0.1);
            padding: 2px 6px;
            border-radius: 4px;
          }

          /* Dark mode adjustments */
          [data-theme="dark"] .calendar-expense-item {
            background-color: rgba(52, 152, 219, 0.03);
          }

          [data-theme="dark"] .calendar-expense-item:hover {
            background-color: rgba(52, 152, 219, 0.08);
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default ExpenseCalendarView;
