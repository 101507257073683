import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { BudgetGoal } from "types/budgetGoal";
import { Modal, Button } from "react-bootstrap";

ChartJS.register(ArcElement, Tooltip, Legend);

interface BudgetGoalDonutChartProps {
  budgetGoalChartData: ChartData<"doughnut">;
  isDarkMode: boolean;
  budgetGoals: BudgetGoal[];
}

const BudgetGoalDonutChart: React.FC<BudgetGoalDonutChartProps> = ({
  budgetGoalChartData,
  isDarkMode,
  budgetGoals,
}) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [chartKey, setChartKey] = useState(0);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  // Calculate total goals and progress
  const totalGoalAmount = budgetGoals.reduce(
    (sum, goal) => sum + goal.budget_goal_sum_total,
    0
  );
  const totalSavedAmount = budgetGoals.reduce(
    (sum, goal) => sum + goal.current_amt_saved,
    0
  );
  const overallProgress =
    totalGoalAmount > 0
      ? ((totalSavedAmount / totalGoalAmount) * 100).toFixed(1)
      : "0";

  useEffect(() => {
    // Force chart re-render when data changes
    setChartKey((prev) => prev + 1);
  }, [budgetGoalChartData, isDarkMode]);

  useEffect(() => {
    // Setup resize observer for the chart container
    if (chartContainerRef.current) {
      resizeObserver.current = new ResizeObserver(() => {
        // Force chart re-render on container resize
        setChartKey((prev) => prev + 1);
      });

      resizeObserver.current.observe(chartContainerRef.current);
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "70%",
    radius: "90%",
    animation: {
      duration: 750,
    },
    plugins: {
      legend: {
        position: "right" as const,
        align: "center" as const,
        labels: {
          boxWidth: 12,
          padding: 15,
          color: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
          font: {
            size: 11,
          },
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      tooltip: {
        backgroundColor: isDarkMode
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(255, 255, 255, 0.9)",
        titleColor: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
        bodyColor: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
        borderColor: isDarkMode
          ? "rgba(255, 255, 255, 0.1)"
          : "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        padding: 10,
        displayColors: true,
        callbacks: {
          label: (context: { dataIndex: number }) => {
            const goal = budgetGoals[context.dataIndex];
            if (!goal) return "";
            const percentage = (
              (goal.current_amt_saved / goal.budget_goal_sum_total) *
              100
            ).toFixed(1);
            return `${
              goal.goal_name
            }: $${goal.current_amt_saved.toLocaleString()} / $${goal.budget_goal_sum_total.toLocaleString()} (${percentage}%)`;
          },
        },
      },
    },
  };

  const fullScreenChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        ...chartOptions.plugins.legend,
        labels: {
          ...chartOptions.plugins.legend.labels,
          font: {
            size: 14,
          },
          padding: 20,
        },
      },
    },
  };

  return (
    <>
      <div className="card card-height-100">
        <div className="card-header align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Budget Goals Progress</h4>
          <div className="flex-shrink-0">
            <div className="d-flex align-items-center">
              <div className="text-end me-3">
                <h5 className="mb-0">{overallProgress}% Complete</h5>
                <p className="text-muted mb-0">Overall Progress</p>
              </div>
              <Button
                variant="light"
                size="sm"
                className="btn-icon"
                onClick={() => setShowFullScreen(true)}
                title="Full Screen"
              >
                <i className="bx bx-fullscreen fs-4"></i>
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="compact-chart-container" ref={chartContainerRef}>
            <div className="d-flex flex-column align-items-center">
              <div
                className="compact-chart"
                style={{ width: "100%", height: "300px", position: "relative" }}
              >
                {budgetGoals.length > 0 &&
                budgetGoalChartData.datasets[0].data.length > 0 ? (
                  <Doughnut
                    key={chartKey}
                    data={budgetGoalChartData}
                    options={chartOptions}
                    redraw={true}
                  />
                ) : (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <p className="text-muted">No budget goals data available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showFullScreen}
        onHide={() => setShowFullScreen(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Budget Goals Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "70vh", position: "relative" }}>
            {budgetGoals.length > 0 &&
            budgetGoalChartData.datasets[0].data.length > 0 ? (
              <Doughnut
                key={`fullscreen-${chartKey}`}
                data={budgetGoalChartData}
                options={fullScreenChartOptions}
                redraw={true}
              />
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">
                <p className="text-muted">No budget goals data available</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center w-100">
            <h5 className="mb-0">{overallProgress}% Complete</h5>
            <p className="text-muted mb-0">Overall Progress</p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BudgetGoalDonutChart;
