import React, { useEffect, useState } from "react";
import LoaderView from "../../reusable/loader/loaderView";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../services/helpers/colorService";
import { fetchBudgetGoalsByUserId } from "../../../services/budgetGoal/budgetGoalManagement";
import BudgetGoalContributionCard from "../../reusable/cards/dashboard/budgetGoalContributionCard";
import BudgetGoalsGrid from "../../reusable/cards/budgetGoal/budgetGoalsGrid";
import budget_goal_animation from "../../reusable/animations/lottie/budget_goal_animation.json";
import NotAllowedOverlay from "../../reusable/subscription/notAllowed/notAllowedOverlay";
import { BudgetGoal } from "../../../types/budgetGoal";
import PageTitleBox from "components/reusable/portal/general/pageTitleBox";

interface AllBudgetGoalsProps {
  isDarkMode: boolean;
}

interface LottieOptions {
  loop: boolean;
  autoplay: boolean;
  animationData: any;
  rendererSettings: {
    preserveAspectRatio: string;
  };
}

const AllBudgetGoals: React.FC<AllBudgetGoalsProps> = ({ isDarkMode }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pageTitle = "All Budget Goals";

  const [budgetGoals, setBudgetGoals] = useState<BudgetGoal[]>([]);

  const emptyBudgetGoalLottieOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getBudgetGoals = async (): Promise<void> => {
    const budgetGoalsData = await fetchBudgetGoalsByUserId();
    if (budgetGoalsData) {
      setBudgetGoals(budgetGoalsData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getBudgetGoals();
  }, []);

  const handleBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    event.preventDefault();
    // your code here
  };

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageTitle="Dashboard"
            previousPageLink="/dashboard"
            isDarkMode={isDarkMode}
          />

          <div className="row">
            <div className="col-xl-4">
              <NotAllowedOverlay message="You need to be a paid user to access this feature">
                <BudgetGoalContributionCard
                  lottieAnimation={emptyBudgetGoalLottieOptions}
                  budgetGoals={budgetGoals}
                />
              </NotAllowedOverlay>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  {/* SpendingCard component placeholder */}
                </div>
                <div className="col-md-6">
                  {/* BudgetLeftCard component placeholder */}
                </div>
                <div className="col-md-6">
                  {/* TotalSpendCard component placeholder */}
                </div>
                <div className="col-md-6">
                  {/* SavingCard component placeholder */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <NotAllowedOverlay message="You need to be a paid user to access this feature">
              <BudgetGoalsGrid budgetGoals={budgetGoals} />
            </NotAllowedOverlay>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllBudgetGoals;
