import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { iconUrlMap } from "../../../../constants/iconUrlMap";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { budgetGoalCategories } from "../../../../constants/budgetGoal/budgetGoalCategories";
import config from "../../../../constants/config";
import {
  updateBudgetGoal,
  fetchBudgetGoalById,
} from "../../../../services/budgetGoal/budgetGoalManagement";
import LoaderView from "../../../reusable/loader/loaderView";
import IconSelector from "../../../reusable/form/IconSelector";
import {
  BudgetGoal,
  BudgetGoalUpdate,
  GoalCreatorProfile,
} from "../../../../types/budgetGoal";
import SelectControl from "../../../reusable/form/SelectControl";
import DatePickerControl from "../../../reusable/form/DatePickerControl";
import { devError, devLog } from "utils/logger";

interface EditBudgetGoalProps {
  isDarkMode: boolean;
}

interface FormData {
  goal_date: Date;
  ai_insight_msg: string;
  bank_account_id: string;
  budget_goal_id: string;
  budget_goal_sum_total: number;
  current_amt_saved: number;
  goal_category_icon: string;
  goal_creator_profile: Partial<GoalCreatorProfile>;
  goal_name: string;
  goal_type: string;
  has_achieved_goal: boolean;
  is_deleted: boolean;
  month: number;
  pals_working_together_towards_goal: string[];
  record_time_stamp: Date;
  target_end_date_raw_date: Date;
  total_left: number;
  user_id: string;
  year: number;
  [key: string]: any;
}

interface IconOption {
  value: string;
  label: string;
  url: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface Errors {
  [key: string]: string;
}

const EditBudgetGoal: React.FC<EditBudgetGoalProps> = ({ isDarkMode }) => {
  const pageTitle = "Edit Budget Goal";
  const { budgetGoalId } = useParams();
  const navigate = useNavigate();
  const [budgetGoalFormData, setBudgetGoalFormData] = useState<FormData>({
    goal_date: new Date(),
    ai_insight_msg: "",
    bank_account_id: "",
    budget_goal_id: "",
    budget_goal_sum_total: 0,
    current_amt_saved: 0,
    goal_category_icon: "",
    goal_creator_profile: {},
    goal_name: "",
    goal_type: "",
    has_achieved_goal: false,
    is_deleted: false,
    month: 0,
    pals_working_together_towards_goal: [],
    record_time_stamp: new Date(),
    target_end_date_raw_date: new Date(),
    total_left: 0,
    user_id: "",
    year: 0,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [iconSelectOptions, setIconSelectOptions] = useState<IconOption[]>([]);
  const [selectedOptionBudgetGoalType, setSelectedOptionBudgetGoalType] =
    useState<SelectOption | null>(null);
  const [selectedOptionBudgetGoalIcon, setSelectedOptionBudgetGoalIcon] =
    useState<IconOption | null>(null);
  const [budgetGoalLoaded, setBudgetGoalLoaded] = useState(false);
  const [iconOptionsLoaded, setIconOptionsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema = {
    goal_date: Joi.date().optional().allow("").label("Goal Date"),
    ai_insight_msg: Joi.string()
      .optional()
      .allow("")
      .label("AI Insight Message"),
    bank_account_id: Joi.string().optional().allow("").label("Bank Account ID"),
    budget_goal_id: Joi.string().optional().allow("").label("Budget Goal ID"),
    budget_goal_sum_total: Joi.number()
      .required()
      .label("Budget Goal Sum Total"),
    current_amt_saved: Joi.number().required().label("Current Amount Saved"),
    goal_category_icon: Joi.string()
      .optional()
      .allow("")
      .label("Goal Category Icon"),
    goal_creator_profile: Joi.object()
      .optional()
      .allow("")
      .label("Goal Creator Profile"),
    goal_name: Joi.string().required().label("Goal Name"),
    goal_type: Joi.string().optional().allow("").label("Goal Type"),
    has_achieved_goal: Joi.boolean()
      .optional()
      .allow("")
      .label("Has Achieved Goal"),
    is_deleted: Joi.boolean().optional().allow("").label("Is Deleted"),
    month: Joi.number().required().label("Month"),
    pals_working_together_towards_goal: Joi.array()
      .optional()
      .allow("")
      .label("Pals Working Together Towards Goal"),
    record_time_stamp: Joi.date()
      .optional()
      .allow("")
      .label("Record Time Stamp"),
    target_end_date_raw_date: Joi.date()
      .optional()
      .allow("")
      .label("Target End Date Raw Date"),
    total_left: Joi.number().optional().allow("").label("Total Left"),
    user_id: Joi.string().optional().allow("").label("User ID"),
    year: Joi.number().required().label("Year"),
    goal_images: Joi.array().optional().allow("").label("Goal Images"),

    created_at: Joi.date().optional().allow("").label("Created At"),
    last_updated: Joi.date().optional().allow("").label("Last Updated"),
  };

  const getIconOptions = () => {
    const options = Object.entries(iconUrlMap).map(([key, data]) => ({
      value: key,
      label: data.val,
      url: data.url,
    }));
    setIconSelectOptions(options);
    setIconOptionsLoaded(true);
  };

  const validate = (): Errors | null => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFormData, schema, options);
    if (!error) return null;

    const errors: Errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    devError("Errors: ", errors);
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: keyof FormData;
    value: any;
  }): string | null => {
    if (!(name in schema)) return null;
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name as keyof typeof schema] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validate();

    if (errors) {
      Object.values(errors).forEach((error) => {
        toast.error(error);
      });
      setErrors(errors);
      return;
    }

    doSubmit();
  };

  const isFormDataKey = (key: string): boolean => {
    return key in budgetGoalFormData;
  };

  const handleChange = (input: any) => {
    const currentErrors = { ...errors };
    let name = "";
    let value: any;

    if (input?.currentTarget) {
      name = input.currentTarget.name;
      value = input.currentTarget.value;
    } else if (input?.value) {
      if (typeof input.value === "string" && !input.label) {
        name = "goal_date";
        value = input.value;

        const date = new Date(value);
        setBudgetGoalFormData((prev) => ({
          ...prev,
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          target_end_date_raw_date: date,
        }));
        return;
      } else if (input.label) {
        name = "goal_category_icon";
        value = input.value;
        setSelectedOptionBudgetGoalIcon(input);
      }
    } else if (input?.name === "goal_date") {
      // Handle direct date changes from DatePicker
      const date = input.value;
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_date: date,
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        target_end_date_raw_date: date,
      }));
      return;
    }

    if (!name || !isFormDataKey(name)) return;

    const errorMessage = validateProperty({
      name: name as keyof FormData,
      value,
    });
    if (errorMessage) currentErrors[name] = errorMessage;
    else delete currentErrors[name];

    setBudgetGoalFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors(currentErrors);
  };

  const handleDateChange = (date: Date | null) => {
    const selectedDate = date || new Date();
    setBudgetGoalFormData((prev) => ({
      ...prev,
      goal_date: selectedDate,
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      target_end_date_raw_date: selectedDate,
    }));
  };

  const doSubmit = async () => {
    try {
      setIsSubmitting(true);
      const userId = localStorage.getItem(config.user_id) || "";

      const submissionData: BudgetGoalUpdate = {
        ...budgetGoalFormData,
        budget_goal_id: budgetGoalId || "",
        user_id: userId,
        total_left:
          Number(budgetGoalFormData.budget_goal_sum_total) -
          Number(budgetGoalFormData.current_amt_saved),
        record_time_stamp: new Date(),
        goal_images: budgetGoalFormData.goal_images,
      };

      // Remove the goal_date field as it's not part of the API model
      delete (submissionData as any).goal_date;

      const response = await updateBudgetGoal(submissionData);
      if (response) {
        setIsLoading(false);
        toast.success("Budget Goal updated successfully");
        navigate(`/budgetGoal/${budgetGoalId}`);
      } else {
        setIsLoading(false);
        toast.error("Failed to update Budget Goal");
      }
    } catch (error) {
      console.error("Error updating budget goal:", error);
      toast.error("Failed to update budget goal");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBudgetGoalChangeType = (option: SelectOption | null) => {
    setSelectedOptionBudgetGoalType(option);
    if (option) {
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_type: option.value,
      }));
    }
  };

  const handleIconChange = (selectedOption: any) => {
    if (selectedOption) {
      setBudgetGoalFormData((prev) => ({
        ...prev,
        goal_category_icon: selectedOption.value,
      }));
      setSelectedOptionBudgetGoalIcon(selectedOption);
    }
  };

  const getBudgetGoal = async () => {
    try {
      setIsLoading(true);
      const budgetGoal = await fetchBudgetGoalById(budgetGoalId || "");

      if (budgetGoal) {
        devLog("Budget Goal: ", budgetGoal);
        const goalDate = new Date(budgetGoal.target_end_date_raw_date);

        setBudgetGoalFormData({
          ...budgetGoal,
          goal_date: goalDate,
        });

        // Set the selected options for goal type
        const selectedTypeOption = budgetGoalCategories.find(
          (option) => option.value === budgetGoal.goal_type
        );
        setSelectedOptionBudgetGoalType(selectedTypeOption || null);
        setBudgetGoalLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching budget goal:", error);
      toast.error("Failed to load budget goal");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle icon selection after both budget goal and icon options are loaded
  useEffect(() => {
    if (
      budgetGoalLoaded &&
      iconOptionsLoaded &&
      budgetGoalFormData.goal_category_icon
    ) {
      const selectedIconOption = iconSelectOptions.find(
        (option) => option.value === budgetGoalFormData.goal_category_icon
      );
      setSelectedOptionBudgetGoalIcon(selectedIconOption || null);
    }
  }, [
    budgetGoalLoaded,
    iconOptionsLoaded,
    budgetGoalFormData.goal_category_icon,
    iconSelectOptions,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      getIconOptions();
      if (budgetGoalId) {
        getBudgetGoal();
      }
    } catch (error) {
      console.error("Error fetching budget goal:", error);
      toast.error("Failed to load budget goal");
    } finally {
      //setIsLoading(false);
    }
  }, [budgetGoalId]);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageLink={`/budgetGoal/${budgetGoalId}`}
            previousPageTitle="Budget Goal Details"
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Edit Budget Goal
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="goal_name"
                              label="Goal Name"
                              value={budgetGoalFormData.goal_name}
                              onChange={handleChange}
                              error={errors.goal_name}
                              type="text"
                              placeholder="Enter goal name"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <SelectControl
                              name="goal_type"
                              label="Goal Type"
                              value={selectedOptionBudgetGoalType}
                              onChange={handleBudgetGoalChangeType}
                              options={budgetGoalCategories}
                              isDarkMode={isDarkMode}
                              error={errors.goal_type}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="budget_goal_sum_total"
                              label="Budget Goal Sum Total"
                              type="number"
                              value={budgetGoalFormData.budget_goal_sum_total}
                              onChange={handleChange}
                              error={errors.budget_goal_sum_total}
                              placeholder="Enter target amount"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="current_amt_saved"
                              label="Amount Already Saved"
                              type="number"
                              value={budgetGoalFormData.current_amt_saved}
                              onChange={handleChange}
                              error={errors.current_amt_saved}
                              placeholder="Enter amount saved"
                              labelClass=""
                              isFocused={false}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <IconSelector
                              name="goal_category_icon"
                              label="Goal Icon"
                              value={selectedOptionBudgetGoalIcon}
                              onChange={handleIconChange}
                              isDarkMode={isDarkMode}
                              error={errors.goal_category_icon}
                            />
                          </div>
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <DatePickerControl
                              name="goal_date"
                              label="Select Goal Deadline"
                              selected={budgetGoalFormData.goal_date}
                              onChange={handleDateChange}
                              isDarkMode={isDarkMode}
                              error={errors.target_end_date_raw_date}
                              required={true}
                              placeholder="Select deadline"
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            navigate(`/budgetGoal/${budgetGoalId}`)
                          }
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!!validate() || isSubmitting}
                          className="btn btn-primary d-flex align-items-center gap-2"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Saving...
                            </>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditBudgetGoal;
