import React, { useEffect, useState } from "react";
import LoaderView from "../../../reusable/loader/loaderView";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import {
  predefinedColors,
  darkenColor,
} from "../../../../services/helpers/colorService";
import { fetchCurrentExpenses } from "../../../../services/expenses/expenseServiceManagement";
import ExpenseGrid from "../../../reusable/cards/expense/expenseGrid";
import empty_expenses_animation from "../../../reusable/animations/lottie/empty_expenses_animation.json";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import { ExpenseModel } from "../../../../types/expenses/expenseTypes";
import ExpenseBreakdownCard from "../../../reusable/cards/dashboard/expenseBreakdownCard";
import { Bar, Doughnut } from "react-chartjs-2";

interface CurrentExpensesProps {
  isDarkMode: boolean;
}

type ChartType = "pie" | "bar";

const CurrentExpenses: React.FC<CurrentExpensesProps> = ({ isDarkMode }) => {
  const pageTitle = "Current Expenses";
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<ExpenseModel[]>([]);
  const [chartType, setChartType] = useState<ChartType>("pie");

  //expenseChartData
  const [expenseChartData, setExpenseChartData] = useState<
    ChartData<ChartType>
  >({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: predefinedColors,
        borderWidth: 1,
      },
    ],
  });

  //lottie
  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLatestExpensesChartData = (expenses: ExpenseModel[]) => {
    // Sort expenses by amount in descending order
    const sortedExpenses = [...expenses].sort(
      (a, b) => (b.expense_amt || 0) - (a.expense_amt || 0)
    );

    // If there are more than 7 items, switch to bar chart and group smaller items
    const shouldUseBarChart = sortedExpenses.length > 7;
    setChartType(shouldUseBarChart ? "bar" : "pie");

    let processedExpenses;
    if (shouldUseBarChart) {
      // Take top 6 expenses and group the rest as "Others"
      const topExpenses = sortedExpenses.slice(0, 6);
      const otherExpenses = sortedExpenses.slice(6);
      const otherTotal = otherExpenses.reduce(
        (sum, exp) => sum + (exp.expense_amt || 0),
        0
      );

      processedExpenses = [
        ...topExpenses,
        {
          expense_name: "Others",
          expense_amt: otherTotal,
        },
      ];
    } else {
      processedExpenses = sortedExpenses;
    }

    const labels = processedExpenses.map(
      (expense) => expense.expense_name || ""
    );
    const data = processedExpenses.map((expense) => expense.expense_amt || 0);
    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    const newChartData: ChartData<ChartType> = {
      labels,
      datasets: [
        {
          label: "Budget Breakdown",
          data,
          backgroundColor: predefinedColors.slice(0, labels.length),
          borderColor: predefinedColors.slice(0, labels.length),
          borderWidth: 3,
          hoverBackgroundColor: hoverColors.slice(0, labels.length),
        },
      ],
    };

    setExpenseChartData(newChartData);
  };

  const getTheCurrentExpenses = async () => {
    try {
      const currentExpenses = await fetchCurrentExpenses();
      setExpenses(currentExpenses);

      if (currentExpenses && currentExpenses.length > 0) {
        getLatestExpensesChartData(currentExpenses);
      }
    } catch (error) {
      console.error("Error fetching expenses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getTheCurrentExpenses();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox pageTitle={pageTitle} previousPageLink="/dashboard" />
          <div className="row">
            <div className="col-xl-3">
              <ExpenseBreakdownCard
                lottieAnimation={emptyExpensesLottieOptions}
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {expenses && expenses.length > 0 && (
              <ExpenseGrid expenses={expenses} title="Current Expenses" />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CurrentExpenses;
