import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loader from "../../../styletheme/images/loading/loader.svg";

import profileImg from "../../../styletheme/images/users/multi-user.jpg";

import config from "../../../constants/config";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { get, set } from "lodash";
import DashboardMiniFactCard from "./sub/dashboardMiniFactCard";

import { fetchLatestBudget } from "../../../services/budgets/budgetServiceManagement";
import {
  fetchCurrentExpenses,
  fetchAllExpensesForLoggedInUser,
} from "../../../services/expenses/expenseServiceManagement";
import {
  fetchBudgetGoalsByUserId,
  fetchLatestEightBudgetGoalsByUserId,
} from "../../../services/budgetGoal/budgetGoalManagement";
import { fetchPalByPrimaryPalId } from "../../../services/palz/palzManagement";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getIconUrl } from "../../../constants/iconUrlMap";
import empty_expenses_animation from "../../reusable/animations/lottie/empty_expenses_animation.json";
import no_budget_animation from "../../reusable/animations/lottie/no_budget.json";
import budget_goal_animation from "../../reusable/animations/lottie/budget_goal_animation.json";
import friends_animation from "../../reusable/animations/lottie/friends_animation.json";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
  parseColor,
  darkenColor,
  colorForCategory,
  stringToHash,
} from "../../../services/helpers/colorService";

import ExpenseBreakdownCard from "../../reusable/cards/dashboard/expenseBreakdownCard";
import BudgetBreakdownCard from "../../reusable/cards/dashboard/BudgetBreakdownCard";
import BudgetGoalContributionCard from "../../reusable/cards/dashboard/budgetGoalContributionCard";
import UpgradeAlertCard from "../../reusable/cards/dashboard/upgradeAlertCard";
//'assets/images/user-illustarator-2.png'
import userIllustarator from "../../../styletheme/images/user-illustarator-2.png";
import unlockTruePotential from "../../../styletheme/images/unlock_true_potential.jpg";
import ApprovedPalCard from "../../reusable/cards/palz/approvedPalCard";
import BudgetCard from "../../reusable/cards/budget/budgetCard";
import RecentExpensesCard from "../../reusable/cards/expense/recentExpenseCard";
import BudgetGoalsCard from "../../reusable/cards/budgetGoal/budgetGoalsCard";
import LoaderView from "../../reusable/loader/loaderView";
import ExpenseCompactCalendarView from "../expenses/calendar/expenseCompactCalendarView";
import PageTitleBox from "../../reusable/portal/general/pageTitleBox";
import { useSubscription } from "../../../services/context/subscription/subscriptionContext";
import NotAllowedOverlay from "../../reusable/subscription/notAllowed/notAllowedOverlay";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Calendar } from "rsuite";
import { Badge } from "react-bootstrap";

import { usePageTitle } from "utils/hooks/usePageTitle";
import { getMonthName } from "utils/dateUtils";

import { Budget } from "../../../types/budget";
import { BudgetGoal } from "../../../types/budgetGoal";
import { ExpenseModel } from "../../../types/expenses/expenseTypes";
import { PalCoBudget } from "../../../types/budget";

interface DashboardProps {
  isDarkMode: boolean;
}

interface ViewingTourDetails {
  viewingDate: string;
  viewingTime: string;
  location: string;
}

const Dashboard: React.FC<DashboardProps> = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const pageTitle = "Dashboard";
  usePageTitle({ title: pageTitle });
  const isPaid = localStorage.getItem(config.is_paid);

  const { subscription } = useSubscription();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const [viewingTourDetails, setViewingTourDetails] =
    useState<ViewingTourDetails>({
      viewingDate: "",
      viewingTime: "",
      location: "",
    });

  const storedUserId = localStorage.getItem(config.user_id);

  const [latestBudget, setLatestBudget] = useState<Budget>({
    budget_categories: [],
    budget_id: "",
    creator_profile: {
      creator_profile_id: "",
      email_address: "",
      profile_img: "",
      last_name: "",
      user_id: "",
    },
    email_address: "",
    first_name: "",
    is_active_budget: false,
    last_name: "",
    month: 0,
    record_time_stamp: 0,
    total_left: 0,
    total_sum: 0,
    user_id: "",
    year: 0,
  });

  const [expensesAndTransactions, setExpensesAndTransactions] = useState<
    ExpenseModel[]
  >([]);
  const [fullExpensesAndTransactions, setFullExpensesAndTransactions] =
    useState<ExpenseModel[]>([]);
  const [expensesForCalendar, setExpensesForCalendar] = useState<
    ExpenseModel[]
  >([]);
  const [budgetGoals, setBudgetGoals] = useState<BudgetGoal[]>([]);
  const [allApprovedPalz, setAllApprovedPalz] = useState<PalCoBudget[]>([]);

  const [budgetExpenseChartData, setBudgetExpenseChartData] = useState<
    ChartData<"doughnut">
  >({
    labels: [],
    datasets: [
      {
        label: "Monthly Budget Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ),
      },
    ],
  });

  const [expenseChartData, setExpenseChartData] = useState<
    ChartData<"doughnut">
  >({
    labels: [],
    datasets: [
      {
        label: "Monthly Expenses Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: predefinedColors.map((color) =>
          darkenColor(color, 0.7)
        ),
      },
    ],
  });

  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emptyBudgetLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: no_budget_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emptyBudgetGoalLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: budget_goal_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLatestBudget = async (): Promise<void> => {
    const latestBudget = await fetchLatestBudget();
    setLatestBudget(latestBudget);

    if (latestBudget) {
      setIsLoading(false);
      getLatestBudgetAndChartData(latestBudget);
    } else {
      setIsLoading(false);
    }
  };

  const getLatestBudgetAndChartData = async (
    latestBudget: Budget
  ): Promise<void> => {
    const labels =
      latestBudget.budget_categories?.map(
        (category) => category.category_name
      ) || [];
    const data =
      latestBudget.budget_categories?.map(
        (category) => category.total_allocated_for_property
      ) || [];

    const backgroundColor = labels.map((category) =>
      dynamicallyGeneratedColor(category, isDarkMode)
    );
    const borderColor = backgroundColor.map((color) =>
      color.replace("0.2", "1")
    );

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    setBudgetExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: hoverColors,
      })),
    }));
  };

  const getLatestExpensesAndTransactionsChartData = async (
    expensesAndTransactions: ExpenseModel[]
  ): Promise<void> => {
    const expenses = Array.isArray(expensesAndTransactions)
      ? expensesAndTransactions
      : [];

    const labels = expenses.map((expense) => expense.expense_name || "");
    const data = expenses.map((expense) => expense.expense_amt || 0);

    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    setExpenseChartData((prevState) => ({
      ...prevState,
      labels: labels,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        data: data,
        backgroundColor: predefinedColors,
        borderColor: isDarkMode ? "#6c757d" : "white",
        borderWidth: 3,
        hoverBackgroundColor: hoverColors,
      })),
    }));
  };

  const getTheLatestExpenses = async (): Promise<void> => {
    try {
      const response = await fetchCurrentExpenses();
      const expenses = Array.isArray(response) ? response : [];

      setExpensesAndTransactions(expenses);

      if (expenses.length > 0) {
        setFullExpensesAndTransactions(expenses);
        getLatestExpensesAndTransactionsChartData(expenses);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching expenses:", error);
      setExpensesAndTransactions([]);
      setFullExpensesAndTransactions([]);
      setIsLoading(false);
    }
  };

  const getTheLatestExpensesAndTransactions = async (): Promise<void> => {
    const expenses = await fetchAllExpensesForLoggedInUser();

    if (expenses.length > 0) {
      setExpensesForCalendar(expenses);
    }
  };

  const getBudgetGoals = async (): Promise<void> => {
    const budgetGoals = await fetchLatestEightBudgetGoalsByUserId();
    setBudgetGoals(budgetGoals);
  };

  const getAllApprovedPalz = async (): Promise<void> => {
    const allApprovedPalz = await fetchPalByPrimaryPalId();
    setAllApprovedPalz(allApprovedPalz);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    getLatestBudget();
    getTheLatestExpenses();
    getTheLatestExpensesAndTransactions();
    getBudgetGoals();
    getAllApprovedPalz();

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          {/* start page title */}
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageLink="/"
            previousPageTitle="Home"
            isDarkMode={isDarkMode}
          />
          {/* end page title */}
          {/* Top row with charts */}
          <div className="row">
            {/* Budget Breakdown */}
            <div className="col-xl-3">
              <BudgetBreakdownCard
                lottieAnimation={emptyBudgetLottieOptions}
                budgetExpenseChartData={budgetExpenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className="col-xl-3">
              <ExpenseBreakdownCard
                lottieAnimation={emptyExpensesLottieOptions}
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            {/* Budget Goals */}
            <div className="col-xl-3">
              <NotAllowedOverlay message="You need to be a paid user to access this feature">
                <BudgetGoalContributionCard
                  lottieAnimation={emptyBudgetGoalLottieOptions}
                  budgetGoals={budgetGoals}
                />
              </NotAllowedOverlay>
            </div>
            {/* Approved Palz */}
            <div className="col-xxl-3">
              <div className="d-flex flex-column h-100">
                <div className="row h-25">
                  {isPaid == "false" && (
                    <UpgradeAlertCard
                      daysLeft={30}
                      upgradeLink="/paywall"
                      imageSrc={unlockTruePotential}
                    />
                  )}

                  {/* end col*/}

                  {allApprovedPalz &&
                  allApprovedPalz.length > 0 &&
                  allApprovedPalz[0].email_address ? (
                    <>
                      <NotAllowedOverlay message="You need to be a paid user to access this feature">
                        <div className="row">
                          {allApprovedPalz.map((pal) => (
                            <div
                              key={pal.pal_id}
                              className={`${
                                isPaid === "false" ? "col-6" : "col-12"
                              } mb-2`}
                            >
                              <ApprovedPalCard
                                pal={pal}
                                storedUserId={storedUserId}
                              />
                            </div>
                          ))}
                        </div>
                      </NotAllowedOverlay>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="row align-items-end">
                              <div className="col-sm-8">
                                <div className="p-3">
                                  <p className="fs-16 lh-base">
                                    No palz seen as yet 🥺, your{" "}
                                    <span className="fw-semibold">Palz</span>{" "}
                                    are on their way.
                                    <i className="mdi mdi-arrow-right"></i>
                                  </p>
                                  <div className="mt-3">
                                    <Link
                                      to={"/inviteNewPal"}
                                      className="btn btn-info"
                                    >
                                      Invite a Pal
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="px-3">
                                  <img
                                    src={userIllustarator}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* end row*/}
              </div>
            </div>{" "}
            {/* end col*/}
          </div>{" "}
          {/* end row*/}
          {/* Main content sections - Budget, Expense, Goals */}
          <div className="row mt-3">
            {/* Budget Section */}
            <div className="col-xl-3">
              <div className="card shadow-sm tw-min-h-[590px]">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getMonthName(latestBudget?.month)} {latestBudget?.year}{" "}
                    Budget
                  </h4>
                  <div className="flex-shrink-0">
                    <NavDropdown
                      title={`Sort by: Category Name`}
                      id="nav-dropdown"
                    >
                      <NavDropdown.Item>Category Name</NavDropdown.Item>
                      <NavDropdown.Item>Amount Budgeted</NavDropdown.Item>
                      <NavDropdown.Item>Amount Left</NavDropdown.Item>
                      <NavDropdown.Item>Amount Spent</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="card-body p-0" style={{ overflowY: "auto" }}>
                  {latestBudget?.budget_categories &&
                  latestBudget.budget_categories.length > 0 &&
                  latestBudget.budget_categories[0]?.category_name ? (
                    <ul className="list list-group list-group-flush mb-0">
                      {latestBudget.budget_categories.map((category, index) => {
                        const amountBudgeted =
                          category.total_allocated_for_property || 0;
                        const amountLeft = category.amount_left || 0;
                        const amountSpent = amountBudgeted - amountLeft;
                        const progress =
                          amountBudgeted !== 0
                            ? (amountSpent / amountBudgeted) * 100
                            : 0;

                        let barColor = "bg-secondary";
                        if (progress >= 95) {
                          barColor = "bg-danger";
                        } else if (progress >= 80) {
                          barColor = "bg-warning";
                        } else {
                          barColor = "bg-success";
                        }

                        return (
                          <li
                            key={index}
                            className="list-group-item"
                            data-id="01"
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-shrink-0 me-3">
                                <div>
                                  <img
                                    className="image avatar-xs rounded-circle"
                                    alt=""
                                    src={getIconUrl(category.category_icon).url}
                                  />
                                </div>
                              </div>

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="overflow-hidden">
                                    <h5 className="contact-name fs-13 mb-1">
                                      <Link
                                        to={`/budgetRelatedExpenses/${category.budget_category_id}`}
                                        className="link text-body"
                                      >
                                        {category.category_name}
                                      </Link>
                                    </h5>
                                  </div>

                                  <div className="flex-shrink-0 ms-2">
                                    <div
                                      className="fs-5 text"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      ${Number(amountSpent)?.toFixed(2)}
                                    </div>
                                  </div>
                                </div>

                                <div className="overflow-hidden">
                                  <div className="no-padding card-body ">
                                    <div className="d-flex justify-content-between ">
                                      <div>
                                        <h6 className="mb-0">
                                          <b className="text">
                                            ${Number(amountSpent)?.toFixed(2)}
                                          </b>{" "}
                                          Spent
                                        </h6>
                                      </div>
                                      <div>
                                        <h6 className="mb-0">
                                          ${Number(amountLeft)?.toFixed(2)} Left
                                        </h6>
                                      </div>
                                      <div>
                                        <h6 className="mb-0">
                                          ${Number(amountBudgeted).toFixed(2)}{" "}
                                          Budgeted
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="progress bg-secondary-subtle rounded-0">
                                    <div
                                      className={`progress-bar ${barColor}`}
                                      role="progressbar"
                                      style={{ width: `${progress}%` }}
                                      aria-valuenow={progress}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="text-center p-4">
                      <p>No budget categories found.</p>
                      <Link to="/createNewBudget" className="btn btn-success">
                        Create New Budget
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Expense Section */}
            <div className="col-xl-3">
              <div className="card shadow-sm tw-min-h-[590px]">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Recent Expenses
                  </h4>
                  <div className="flex-shrink-0">
                    <NavDropdown
                      title={`Sort by: Expense Name`}
                      id="nav-dropdown"
                    >
                      <NavDropdown.Item>Expense Name</NavDropdown.Item>
                      <NavDropdown.Item>Amount</NavDropdown.Item>
                      <NavDropdown.Item>Date</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="card-body">
                  <div className="search-box mb-3">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder="Search for expense..."
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                  <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                    {expensesAndTransactions &&
                    expensesAndTransactions.length > 0 ? (
                      <ul className="list-group list-group-flush">
                        {expensesAndTransactions.map((expense, index) => {
                          const expenseAmount = expense.expense_amt || 0;
                          return (
                            <li
                              key={index}
                              className="list-group-item py-3"
                              style={{ borderBottom: "1px solid #f0f0f0" }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <img
                                    src={
                                      getIconUrl(expense.expense_icon || "").url
                                    }
                                    alt=""
                                    className="avatar-xs rounded-circle"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="fs-14 mb-1">
                                    <Link
                                      to={`/expense/${expense.expense_id}`}
                                      className="text-body"
                                    >
                                      {expense.expense_name}
                                    </Link>
                                  </h6>
                                  <p className="text-muted mb-0">
                                    {expense.expense_description}
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  <span
                                    className={`badge ${
                                      expenseAmount < 0
                                        ? "bg-danger"
                                        : "bg-success"
                                    }`}
                                  >
                                    ${Math.abs(expenseAmount).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <div className="text-center">
                        <p>No expenses found.</p>
                        <Link
                          to="/createNewExpense"
                          className="btn btn-success"
                        >
                          Create New Expense
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Goals/Calendar Section */}
            <div className="col-xl-6">
              <div className="card shadow-sm" style={{ minHeight: "590px" }}>
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Calendar</h4>
                </div>
                <div className="card-body">
                  <ExpenseCompactCalendarView
                    expensesAndTransactions={expensesForCalendar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
