import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
} from "chart.js";
import { BudgetGoalFunds } from "../../../../../types/budgetGoal";
import {
  predefinedColors,
  dynamicallyGeneratedColor,
} from "../../../../../services/helpers/colorService";

Chart.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement);

interface GoalFundsBarChartProps {
  funds: BudgetGoalFunds[];
  isDarkMode: boolean;
}

const GoalFundsBarChart: React.FC<GoalFundsBarChartProps> = ({
  funds,
  isDarkMode,
}) => {
  const processAmount = (fund: BudgetGoalFunds) => {
    const amount = fund.fund_amt_added || fund.amount || 0;
    const isDecrease =
      fund.fund_source_name?.toLowerCase().includes("decrease") ||
      fund.source?.toLowerCase().includes("decrease");
    return isDecrease ? -Math.abs(amount) : amount;
  };

  // Calculate total funds
  const totalFunds = funds.reduce(
    (total: number, fund: BudgetGoalFunds) => total + processAmount(fund),
    0
  );

  // Get color for a fund source, with fallback
  const getFundColor = (fund: BudgetGoalFunds): string => {
    const isDecrease =
      fund.fund_source_name?.toLowerCase().includes("decrease") ||
      fund.source?.toLowerCase().includes("decrease");

    if (isDecrease) {
      return isDarkMode ? "rgba(255, 99, 132, 1)" : "rgba(220, 53, 69, 1)"; // Red for decreases
    }
    return isDarkMode ? "rgba(75, 192, 192, 1)" : "rgba(40, 167, 69, 1)"; // Green for increases
  };

  // Format the tooltip label
  const formatTooltipLabel = (fund: BudgetGoalFunds) => {
    const amount = processAmount(fund);
    const prefix = amount >= 0 ? "+" : "";
    return `${prefix}$${amount.toFixed(2)}`;
  };

  // Prepare the data
  const data = {
    labels: funds.map((fund) => {
      const date = fund.timestamp || fund.fund_date;
      return date
        ? new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        : "Unknown Date";
    }),
    datasets: [
      {
        label: `Total Funds: $${totalFunds.toFixed(2)}`,
        data: funds.map(processAmount),
        backgroundColor: funds.map(getFundColor),
        borderColor: funds.map(getFundColor),
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: isDarkMode ? "white" : "#6c757d",
        },
      },
      title: {
        display: true,
        text: "Funds Breakdown",
        color: isDarkMode ? "white" : "#6c757d",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const fund = funds[context.dataIndex];
            return formatTooltipLabel(fund);
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? "white" : "#6c757d",
        },
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        ticks: {
          color: isDarkMode ? "white" : "#6c757d",
          callback: (value) => {
            const prefix = Number(value) >= 0 ? "+" : "";
            return `${prefix}$${value}`;
          },
        },
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
      },
    },
  };

  // If no funds data, show a message
  if (!funds || funds.length === 0) {
    return (
      <div className="card card-height-100">
        <div className="card-header align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Funds Breakdown</h4>
        </div>
        <div className="card-body d-flex align-items-center justify-content-center">
          <p className="text-muted mb-0">No funds data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="card card-height-100">
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Funds Breakdown</h4>
      </div>
      <div className="card-body">
        <div style={{ width: "100%", height: "300px" }}>
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default GoalFundsBarChart;
