import React from "react";
import { CategoryMap } from "../types";
import LoaderView from "../../../../reusable/loader/loaderView";
import {
  predefinedColors,
  darkenColor,
} from "../../../../../services/helpers/colorService";

interface ExpensesByCategoryChartProps {
  expensesByCategory: CategoryMap;
  isLoading?: boolean;
}

type BarStyle = {
  background: string[];
  text: string[];
};

const ExpensesByCategoryChart: React.FC<ExpensesByCategoryChartProps> = ({
  expensesByCategory,
  isLoading = false,
}): JSX.Element => {
  // Convert the expensesByCategory object to an array and sort it in descending order
  const sortedExpenses: [string, number][] = Object.entries(
    expensesByCategory
  ).sort((a, b) => b[1] - a[1]);

  // Calculate the total expenses for the progress bar
  const totalExpenses: number = sortedExpenses.reduce(
    (total, [, expense]) => total + expense,
    0
  );

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div className="card card-height-100">
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Expenses By Category</h4>
      </div>
      <div className="card-body">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <LoaderView />
          </div>
        ) : (
          <>
            <div className="row align-items-center">
              <div className="col-6">
                <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">
                  Total Expenses
                </h6>
                <h4 className="mb-0">{formatCurrency(totalExpenses)}</h4>
              </div>
            </div>
            <div className="mt-3 pt-2">
              <div className="progress progress-lg rounded-pill">
                {sortedExpenses.map(([category, expense], index) => {
                  const progress: number = (expense / totalExpenses) * 100;
                  const backgroundColor =
                    predefinedColors[index % predefinedColors.length];

                  return (
                    <div
                      key={category}
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${progress}%`,
                        backgroundColor,
                      }}
                      aria-valuenow={progress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      title={`${category}: ${formatCurrency(
                        expense
                      )} (${progress.toFixed(1)}%)`}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-3 pt-2">
              {sortedExpenses.map(([category, expense], index) => {
                const progress: number = (expense / totalExpenses) * 100;
                const backgroundColor =
                  predefinedColors[index % predefinedColors.length];

                return (
                  <div className="d-flex mb-2" key={category}>
                    <div className="flex-grow-1">
                      <p className="text-truncate text-muted fs-14 mb-0">
                        <i
                          className="mdi mdi-circle align-middle me-2"
                          style={{ color: backgroundColor }}
                        />
                        {category}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0 text-nowrap">
                        {formatCurrency(expense)} ({progress.toFixed(1)}%)
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpensesByCategoryChart;
