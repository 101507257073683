import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoaderView from "../../../reusable/loader/loaderView";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { fetchExpensesByBudgetCategorySelectedId } from "../../../../services/expenses/expenseServiceManagement";
import ExpenseGrid from "../../../reusable/cards/expense/expenseGrid";
import { ExpenseModel } from "../../../../types/expenses/expenseTypes";
import ExpenseBreakdownCard from "../../../reusable/cards/dashboard/expenseBreakdownCard";
import {
  predefinedColors,
  darkenColor,
} from "../../../../services/helpers/colorService";
import empty_expenses_animation from "../../../reusable/animations/lottie/empty_expenses_animation.json";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

interface BudgetRelatedExpensesProps {
  isDarkMode: boolean;
}

const BudgetRelatedExpenses: React.FC<BudgetRelatedExpensesProps> = ({
  isDarkMode,
}) => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [budgetCategoryId] = useState<string>(categoryId || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<ExpenseModel[]>([]);

  // Chart data state
  const [expenseChartData, setExpenseChartData] = useState<ChartData<"pie">>({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: predefinedColors,
        borderWidth: 1,
      },
    ],
  });

  // Lottie animation options
  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getExpensesChartData = (expenses: ExpenseModel[]) => {
    const labels = expenses.map((expense) => expense.expense_name || "");
    const data = expenses.map((expense) => expense.expense_amt || 0);
    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    setExpenseChartData({
      labels,
      datasets: [
        {
          label: "Budget Breakdown",
          data,
          backgroundColor: predefinedColors.slice(0, expenses.length),
          borderColor: predefinedColors.slice(0, expenses.length),
          borderWidth: 3,
          hoverBackgroundColor: hoverColors.slice(0, expenses.length),
        },
      ],
    });
  };

  const fetchCategoryExpenses = async () => {
    try {
      setIsLoading(true);
      const categoryExpenses = await fetchExpensesByBudgetCategorySelectedId(
        budgetCategoryId
      );
      setExpenses(categoryExpenses);

      if (categoryExpenses && categoryExpenses.length > 0) {
        getExpensesChartData(categoryExpenses);
      }
    } catch (error) {
      console.error("Error fetching category expenses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (budgetCategoryId) {
      fetchCategoryExpenses();
    }
  }, [budgetCategoryId]);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              {!isLoading && expenses.length > 0 && (
                <ExpenseBreakdownCard
                  key={`chart-${expenses.length}-${isDarkMode}`}
                  lottieAnimation={emptyExpensesLottieOptions}
                  expenseChartData={expenseChartData}
                  isDarkMode={isDarkMode}
                />
              )}
              {!isLoading && expenses.length === 0 && (
                <div className="card">
                  <div className="card-body text-center">
                    <h5 className="card-title">No Expenses Found</h5>
                    <p className="card-text">
                      There are no expenses for this category yet.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {expenses && expenses.length > 0 && (
              <ExpenseGrid expenses={expenses} title="Category Expenses" />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BudgetRelatedExpenses;
