import React from "react";
import { getIconUrl } from "../../../../constants/iconUrlMap";

interface BudgetOptionLabelProps {
  label: string;
  amount_left: number;
  total_allocated: number;
  icon?: string;
  formatCurrency: (amount: number) => string;
}

const BudgetOptionLabel: React.FC<BudgetOptionLabelProps> = ({
  label,
  amount_left,
  total_allocated,
  icon,
  formatCurrency,
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <img
        src={getIconUrl(icon || "BudgetExpense").url}
        alt={label}
        style={{ width: "20px", height: "20px" }}
      />
      <span>{label}</span>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        fontSize: "0.85em",
      }}
    >
      <span
        style={{
          color: amount_left > 0 ? "green" : "red",
          fontWeight: "bold",
        }}
      >
        {formatCurrency(amount_left)}
      </span>
      <span style={{ color: "gray", fontSize: "0.85em" }}>
        of {formatCurrency(total_allocated)}
      </span>
    </div>
  </div>
);

export default BudgetOptionLabel;
