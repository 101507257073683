import React from "react";

interface BudgetPreviewProps {
  amount_left: number;
  total_allocated: number;
  expense_amount: number;
  formatCurrency: (amount: number) => string;
}

const BudgetPreview: React.FC<BudgetPreviewProps> = ({
  amount_left,
  total_allocated,
  expense_amount,
  formatCurrency,
}) => {
  const projected_amount = amount_left - (expense_amount || 0);
  const isOverBudget = projected_amount < 0;

  return (
    <div className="mt-2" style={{ fontSize: "0.85em" }}>
      <span>Projected Budget After Expense: </span>
      <span
        style={{
          color: isOverBudget ? "red" : "green",
          fontWeight: "bold",
        }}
      >
        {formatCurrency(projected_amount)}
      </span>
      <span> of </span>
      <span>{formatCurrency(total_allocated)}</span>
      {isOverBudget && (
        <div className="text-danger" style={{ fontSize: "0.85em" }}>
          Warning: This expense will put you over budget!
        </div>
      )}
    </div>
  );
};

export default BudgetPreview;
