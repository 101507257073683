import React, { useEffect, useState } from "react";
import LoaderView from "../../../reusable/loader/loaderView";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import {
  predefinedColors,
  darkenColor,
} from "../../../../services/helpers/colorService";
import { fetchAllExpensesForLoggedInUser } from "../../../../services/expenses/expenseServiceManagement";
import ExpenseGrid from "../../../reusable/cards/expense/expenseGrid";
import ExpenseBreakdownCard from "../../../reusable/cards/dashboard/expenseBreakdownCard";
import empty_expenses_animation from "../../../reusable/animations/lottie/empty_expenses_animation.json";
import { ExpenseModel } from "../../../../types/expenses/expenseTypes";
import { usePageTitle } from "utils/hooks/usePageTitle";

interface AllExpensesProps {
  isDarkMode: boolean;
}

const AllExpenses: React.FC<AllExpensesProps> = ({ isDarkMode }) => {
  usePageTitle({ title: "All Expenses" });
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<ExpenseModel[]>([]);

  // Chart data state
  const [expenseChartData, setExpenseChartData] = useState<
    ChartData<"pie", number[], string>
  >({
    labels: [],
    datasets: [
      {
        label: "Budget Breakdown",
        data: [],
        backgroundColor: predefinedColors,
        borderColor: predefinedColors,
        borderWidth: 1,
      },
    ],
  });

  // Lottie animation options
  const emptyExpensesLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: empty_expenses_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLatestExpensesChartData = (expenses: ExpenseModel[]) => {
    // Sort expenses by amount in descending order
    const sortedExpenses = [...expenses].sort(
      (a, b) => (b.expense_amt || 0) - (a.expense_amt || 0)
    );

    const labels = sortedExpenses.map((expense) => expense.expense_name || "");
    const data = sortedExpenses.map((expense) => expense.expense_amt || 0);
    const hoverColors = predefinedColors.map((color) =>
      darkenColor(color, 0.7)
    );

    setExpenseChartData({
      labels,
      datasets: [
        {
          label: "Budget Breakdown",
          data,
          backgroundColor: predefinedColors.slice(0, labels.length),
          borderColor: predefinedColors.slice(0, labels.length),
          borderWidth: 3,
          hoverBackgroundColor: hoverColors.slice(0, labels.length),
        },
      ],
    });
  };

  const getTheLatestExpenses = async () => {
    try {
      setIsLoading(true);
      const fetchedExpenses = await fetchAllExpensesForLoggedInUser();
      setExpenses(fetchedExpenses);

      if (fetchedExpenses && fetchedExpenses.length > 0) {
        getLatestExpensesChartData(fetchedExpenses);
      }
    } catch (error) {
      console.error("Error fetching expenses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTheLatestExpenses();
  }, []);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <div className="row px-4">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                <h4 className="mb-sm-0">All Expenses</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">All Expenses</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3">
              <ExpenseBreakdownCard
                lottieAnimation={emptyExpensesLottieOptions}
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
              />
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>

          <div className="row">
            {expenses && expenses.length > 0 && (
              <ExpenseGrid expenses={expenses} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllExpenses;
