import React from "react";
import Select, { components } from "react-select";

interface SelectOption {
  value: string;
  label: string;
}

interface SelectControlProps {
  name: string;
  label?: string;
  value: SelectOption | null;
  onChange: (selectedOption: SelectOption | null) => void;
  options: SelectOption[];
  isDarkMode: boolean;
  error?: string;
  required?: boolean;
  placeholder?: string;
}

const SelectControl: React.FC<SelectControlProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  isDarkMode,
  error,
  required = false,
  placeholder = "Select an option...",
}) => {
  return (
    <div className="col-12">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: isDarkMode
              ? "#31363c"
              : baseStyles.backgroundColor,
            borderColor: isDarkMode ? "#3c3f42" : baseStyles.borderColor,
            boxShadow: state.isFocused
              ? isDarkMode
                ? "0 0 0 1px #405189"
                : baseStyles.boxShadow
              : "none",
            "&:hover": {
              borderColor: isDarkMode ? "#4a4d50" : baseStyles.borderColor,
            },
            height: "38px",
            minHeight: "38px",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: isDarkMode
              ? "#292e33"
              : baseStyles.backgroundColor,
            borderColor: isDarkMode ? "#3c3f42" : baseStyles.borderColor,
          }),
          option: (baseStyles, { isFocused, isSelected }) => ({
            ...baseStyles,
            backgroundColor: isSelected
              ? isDarkMode
                ? "#405189"
                : "#e6f0ff"
              : isFocused
              ? isDarkMode
                ? "#2f343a"
                : "#f0f0f0"
              : isDarkMode
              ? "#292e33"
              : baseStyles.backgroundColor,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
            ":active": {
              backgroundColor: isDarkMode
                ? "#405189"
                : baseStyles[":active"]?.backgroundColor,
            },
            cursor: "pointer",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#e9ecef" : baseStyles.color,
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#adb5bd" : baseStyles.color,
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: isDarkMode ? "#adb5bd" : baseStyles.color,
            "&:hover": {
              color: isDarkMode ? "#e9ecef" : "#6c757d",
            },
            padding: "4px",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0 8px",
            height: "36px",
          }),
        }}
      />
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

export default SelectControl;
