import { Link } from "react-router-dom";
import config from "../../../../constants/config";
import React from "react";

const NotAllowedOverlay = ({ message, children }) => {
  const isPaid = localStorage.getItem(config.is_paid);
  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        {children}
        {isPaid == "false" && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "var(--vz-body-bg)",
              opacity: 0.85,
              color: "var(--vz-body-color)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center">
                    <p>{message}</p>
                    <Link
                      className="btn btn-success btn-rounded"
                      to={"/paywall"}
                    >
                      Upgrade Account!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default NotAllowedOverlay;
