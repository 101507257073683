import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchBudgetGoalById,
  createBudgetGoalFunds,
} from "../../../../services/budgetGoal/budgetGoalManagement";
import LoaderView from "../../../reusable/loader/loaderView";
import { BudgetGoal, BudgetGoalFunds } from "../../../../types/budgetGoal";

interface AddBudgetGoalFundsProps {
  isDarkMode: boolean;
}

interface FormData {
  fund_amt_added: number;
}

interface FormErrors {
  [key: string]: string;
}

const AddBudgetGoalFunds: React.FC<AddBudgetGoalFundsProps> = ({
  isDarkMode,
}) => {
  const pageTitle = "Budget Goal Funds";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { budgetGoalId } = useParams<{ budgetGoalId: string }>();
  const navigate = useNavigate();

  const [budgetGoal, setBudgetGoal] = useState<BudgetGoal>({
    budget_goal_id: "",
    bank_account_id: "",
    user_id: "",
    budget_goal_sum_total: 0,
    total_left: 0,
    current_amt_saved: 0,
    goal_category_icon: "",
    goal_type: "",
    record_time_stamp: new Date(),
    goal_name: "",
    has_achieved_goal: false,
  });

  const [budgetGoalFundsFormData, setBudgetGoalFundsFormData] =
    useState<FormData>({
      fund_amt_added: 0,
    });

  const [errors, setErrors] = useState<FormErrors>({});

  const schema = {
    fund_amt_added: Joi.number()
      .min(1)
      .required()
      .label("Amount of Funds Being Added"),
  };

  const validate = (): FormErrors | null => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetGoalFundsFormData, schema, options);
    if (!error) return null;

    const errors: FormErrors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }): string | null => {
    if (name !== "fund_amt_added") return null;

    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const validationErrors = validate();

    if (validationErrors) {
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      setErrors(validationErrors);
      return;
    }

    doSubmit();
  };

  const handleChange = (input: React.ChangeEvent<HTMLInputElement>): void => {
    const currentErrors = { ...errors };
    const { name, value } = input.currentTarget;

    const errorMessage = validateProperty({ name, value });
    if (errorMessage) {
      currentErrors[name] = errorMessage;
    } else {
      delete currentErrors[name];
    }

    setBudgetGoalFundsFormData((prevData) => ({
      ...prevData,
      [name]: Number(value),
    }));
    setErrors(currentErrors);
  };

  const doSubmit = async (): Promise<void> => {
    if (!budgetGoalId) {
      toast.error("Budget goal ID is missing");
      return;
    }

    const fund_date_seconds = Date.now();

    const dataToSend: BudgetGoalFunds = {
      budget_goal_fund_id: uuidv4(),
      budget_goal_id: budgetGoalId,
      fund_date: new Date(fund_date_seconds).toISOString(),
      fund_source_id: "",
      fund_source_name: "",
      fund_amt_added: budgetGoalFundsFormData.fund_amt_added,
    };

    const updateBudgetGoalResponse = await createBudgetGoalFunds(dataToSend);

    if (updateBudgetGoalResponse) {
      toast.success("Budget goal funds added successfully");
      navigate(`/budgetGoal/${budgetGoalId}`);
    } else {
      toast.error("Failed to add budget goal funds");
    }
  };

  const getBudgetGoal = async (): Promise<void> => {
    if (!budgetGoalId) return;

    const budgetGoalData = await fetchBudgetGoalById(budgetGoalId);
    if (budgetGoalData) {
      setBudgetGoal(budgetGoalData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getBudgetGoal();
  }, [budgetGoalId]);

  return (
    <React.Fragment>
      {isLoading && <LoaderView />}
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            previousPageLink={`/budgetGoal/${budgetGoalId}`}
          />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Add Funds to Budget Goal
                  </h4>
                  <div className="flex-shrink-0"></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              name="fund_amt_added"
                              label="Amount of Funds Being Added"
                              type="number"
                              value={budgetGoalFundsFormData.fund_amt_added}
                              onChange={handleChange}
                              error={errors.fund_amt_added}
                              placeholder="Enter amount"
                              labelClass="form-label"
                              isFocused={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        disabled={!!validate()}
                        className="btn btn-success"
                        type="submit"
                      >
                        Add Funds
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBudgetGoalFunds;
