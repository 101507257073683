///expense/fetch_expenses_and_transactions_by_user_pal_id

import http from "../general/httpService";
import api from "../../constants/config";
import config from "../../constants/config";

//TODO: need to migrate to V2
export async function fetchAllExpensesForLoggedInUser() {
  const userId = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    api.apiEndpoint +
    `/v2/expense/fetch_expenses_and_transactions_by_user_pal_id/${userId}`;
  const { data: expensesAndTransactions } = await http.get(fullApiEndpoint);
  return expensesAndTransactions;
}

export async function fetchExpensesByBudgetCategorySelectedId(
  budgetCategoryId
) {
  const fullApiEndpoint =
    api.apiEndpoint +
    `/v2/expense/fetch_expenses_by_budget_category_selected_id/${budgetCategoryId}`;
  const { data: transactions } = await http.get(fullApiEndpoint);
  return transactions;
}

///expenses/get_current_expenses
export async function fetchCurrentExpenses() {
  const userId = localStorage.getItem(config.user_id);
  const fullApiEndpoint =
    api.apiEndpoint + `/v2/expense/get_current_expenses/${userId}`;
  const { data: expenses } = await http.get(fullApiEndpoint);
  return expenses;
}

export async function fetchCurrentExpensesByPalId(userId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/v2/expense/get_current_expenses/${userId}`;
  const { data: expenses } = await http.get(fullApiEndpoint);
  return expenses;
}

//get it for primary or secondary palz
export async function fetchExpensesByPalId(userId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/v2/expense/get_expenses_by_user/${userId}`;
  const { data: expenses } = await http.get(fullApiEndpoint);
  return expenses;
}

//create
export async function createExpenseWithTransactions(expenseData) {
  const fullApiEndpoint = api.apiEndpoint + `/v2/expense/create_expense`;
  const { data: expense } = await http.post(fullApiEndpoint, expenseData);
  return expense;
}

// Fetch single expense by ID
export async function fetchExpenseById(expenseId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/v2/expense/get_expense_by_id/${expenseId}`;
  const { data: expense } = await http.get(fullApiEndpoint);
  return expense;
}

//update_expense
export async function updateExpense(expenseData) {
  const fullApiEndpoint = api.apiEndpoint + `/v2/expense/update_expense`;
  const { data: expense } = await http.put(fullApiEndpoint, expenseData);
  //if {"error": "user_pal_id is required for updating an expense"}
  if (expense.error) {
    throw new Error(expense.error);
  }
  return expense;
}

///delete_expense/{expense_id}
export async function deleteExpense(expenseId) {
  const fullApiEndpoint =
    api.apiEndpoint + `/v2/expense/delete_expense/${expenseId}`;
  const { data: deletedExpense } = await http.delete(fullApiEndpoint);
  return deletedExpense;
}
