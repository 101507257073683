import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./components/portal/authentication/login";
import Dashboard from "./components/portal/dashboard/dashboard";
import ProtectedRoute from "./components/portal/authentication/route/protectedroute";
/*
import {
	getCurrentUser,
	getCurrentUserProfileByEmail,
} from "./services/authentication/auth";
*/
import { ToastContainer } from "react-toastify";
import LogOut from "./components/portal/authentication/logout";
import NavigationBar from "./components/portal/navigation/navigationbar";
//import MyProperties from "./components/portal/properties/myproperties";
import config from "./constants/config";
//import PropertyDetail from "./components/portal/properties/propertydetail";
//import PropertyUnitDetail from "./components/portal/properties/units/propertunitdetail";

import {
  getCurrentUser,
  getCurrentUserProfileByEmail,
} from "./services/authentication/authManagementService";
import CurrentBudget from "./components/portal/budget/current/currentBudget";
import BudgetRelatedExpenses from "./components/portal/budget/expenses/budgetRelatedExpenses";
import PastBudgetGrid from "./components/portal/budget/past/pastBudgetGrid";
import BudgetDetail from "./components/portal/budget/detail/budgetDetail";
import AllExpenses from "./components/portal/expenses/past/allExpenses";
import CurrentExpenses from "./components/portal/expenses/current/currentExpenses";
import AllBudgetGoals from "./components/portal/budgetGoal/allBudgetGoals";
import ExpenseCalendarView from "./components/portal/expenses/calendar/expenseCalendarView";
import BudgetGoalDetail from "./components/portal/budgetGoal/budgetGoalDetail";
import ApprovedPalz from "./components/portal/palz/approvedPalz";
import PalzDetail from "./components/portal/palz/detail/palzDetail";
import CreateNewExpense from "./components/portal/expenses/create/createNewExpense";
import CreateNewBudget from "./components/portal/budget/create/createNewBudget";
import CreateNewBudgetGoal from "./components/portal/budgetGoal/create/createNewBudgetGoal";
import EditBudgetGoal from "./components/portal/budgetGoal/edit/editBudgetGoal";
import AddBudgetGoalFunds from "./components/portal/budgetGoal/funds/addBudgetGoalFunds";
import Signup from "./components/portal/authentication/signup";
import InvitePal from "./components/portal/palz/create/invitePal";
import { fetchAllPalRequestsByEmail } from "./services/palz/palzManagement";
import { usePalRequest } from "./services/context/palz/palRequestContext";
import { useSubscription } from "./services/context/subscription/subscriptionContext";
import PendingPalz from "./components/portal/palz/pending/pendingPalz";
import Paywall from "./components/portal/subscription/paywall";
import {
  getActiveSubscriptionsFromStripe,
  getLastPaymentStatus,
} from "./services/subscription/subscriptionManagementService";
import { set } from "lodash";
import { useSettings } from "./services/context/settings/settingsContext";
import Billing from "./components/portal/subscription/billing/billing";
import ManageSubscription from "./components/portal/subscription/manage/manageSubscription";
import { useThemeClasses } from "./utils/hooks/useThemeClasses";
import ExpenseDetail from "./components/portal/expenses/detail/ExpenseDetail";
import EditExpense from "./components/portal/expenses/edit/EditExpense";
//TODO: NEXT Edit existing budget and categories then transfer budgets and import expenses from bank
//TODO: importing expenses from bank
//TODO next  see pending pal (so you can see what failed and remove the request if you are the requestor), approve or reject the request,(launch), subscribe, restrict
//certain features to only paid users
//add lottie animations to the web portal to guide when its empty
//Public facing website theme: https://dsathemes.com/html/olmo_1.4/files/demo-14.html
//TODO: change this it should take you to the onboarding to set a new budget, then to set an expense set an config isOnboarding to handle this
//TODO: need to be able to tell if the person paid on the iOS if yes then enable the features
function App() {
  const { settings, setSettings } = useSettings();

  const isDarkMode = settings.isDarkMode;

  const { getThemeClasses } = useThemeClasses(isDarkMode);
  const [userToken, setUserToken] = useState("");
  //get and set the landlord profile (use redux to update this)
  const [userProfile, setUserProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    profileImg: "",
    stripeCustomerId: "",
  });

  const { palRequests, setPalRequests } = usePalRequest();
  const { subscription, setSubscription } = useSubscription();
  const [currentSubscriptionFromStripe, setCurrentSubscriptionFromStripe] =
    useState({
      id: "",
      object: "",
      application: "",
      application_fee_percent: "",
      automatic_tax: {
        enabled: true,
        liability: {
          type: "",
        },
      },
      billing_cycle_anchor: "",
      billing_cycle_anchor_config: "",
      billing_thresholds: "",
      cancel_at: "",
      cancel_at_period_end: false,
      canceled_at: "",
      cancellation_details: {
        comment: "",
        feedback: "",
        reason: "",
      },
      collection_method: "",
      created: "",
      currency: "",
      current_period_end: "",
      current_period_start: "",
      customer: "",
      days_until_due: "",
      default_payment_method: "",
      default_source: "",
      default_tax_rates: [],
      description: "",
      discount: "",
      discounts: [],
      ended_at: "",
      invoice_settings: {
        account_tax_ids: "",
        issuer: {
          type: "",
        },
      },
      items: {
        object: "",
        data: [
          {
            id: "",
            object: "",
            billing_thresholds: "",
            created: "",
            discounts: [],
            metadata: {},
            plan: {
              id: "",
              object: "",
              active: true,
              aggregate_usage: "",
              amount: "",
              amount_decimal: "",
              billing_scheme: "",
              created: "",
              currency: "",
              interval: "",
              interval_count: 1,
              livemode: false,
              metadata: {},
              meter: "",
              nickname: "",
              product: "",
              tiers_mode: "",
              transform_usage: "",
              trial_period_days: "",
              usage_type: "",
            },
            price: {
              id: "",
              object: "",
              active: true,
              billing_scheme: "",
              created: "",
              currency: "",
              custom_unit_amount: "",
              livemode: false,
              lookup_key: "",
              metadata: {},
              nickname: "",
              product: "",
              recurring: {
                aggregate_usage: "",
                interval: "",
                interval_count: 1,
                meter: "",
                trial_period_days: "",
                usage_type: "",
              },
              tax_behavior: "",
              tiers_mode: "",
              transform_quantity: "",
              type: "",
              unit_amount: "",
              unit_amount_decimal: "",
            },
            quantity: 1,
            subscription: "",
            tax_rates: [],
          },
        ],
        has_more: false,
        total_count: 1,
        url: "",
      },
      latest_invoice: "",
      livemode: false,
      metadata: {},
      next_pending_invoice_item_invoice: "",
      on_behalf_of: "",
      pause_collection: "",
      payment_settings: {
        payment_method_options: {
          acss_debit: "",
          bancontact: "",
          card: {
            network: "",
            request_three_d_secure: "automatic",
          },
          customer_balance: "",
          konbini: "",
          sepa_debit: "",
          us_bank_account: "",
        },
        payment_method_types: "",
        save_default_payment_method: "off",
      },
      pending_invoice_item_interval: "",
      pending_setup_intent: "",
      pending_update: "",
      plan: {
        id: "",
        object: "",
        active: true,
        aggregate_usage: "",
        amount: "",
        amount_decimal: "",
        billing_scheme: "",
        created: "",
        currency: "",
        interval: "",
        interval_count: 1,
        livemode: false,
        metadata: {},
        meter: "",
        nickname: "",
        product: "",
        tiers_mode: "",
        transform_usage: "",
        trial_period_days: "",
        usage_type: "",
      },
      quantity: 1,
      schedule: "",
      start_date: "",
      status: "active",
      test_clock: "",
      transfer_data: "",
      trial_end: "",
      trial_settings: {
        end_behavior: {
          missing_payment_method: "create_invoice",
        },
      },
      trial_start: "",
    });

  useEffect(() => {
    //dark mode start
    const theme = isDarkMode ? "dark" : "light";
    document.documentElement.setAttribute("data-bs-theme", theme);
    //dark mode end

    getUserToken();
  }, [isDarkMode]);

  const getUserToken = async () => {
    try {
      const userToken = await getCurrentUser();

      if (!userToken) return null;

      setUserToken({ userToken });

      //localStorage.setItem(config.access_token, userToken);
      //it means the person is logged in
      getLoggedInUserProfile();
    } catch (error) {
      console.error(`Error getting user token: ${error}`);
      // Handle the error appropriately here
    }
  };

  const getLoggedInUserProfile = async () => {
    const userProfile = await getCurrentUserProfileByEmail();

    if (!userProfile) return null;

    //set if userProfile.user_id not empty
    if (
      userProfile.userId !== "" &&
      userProfile.userId !== null &&
      userProfile.userId !== undefined
    ) {
      localStorage.setItem(config.user_id, userProfile.userId);
    }

    if (
      userProfile.firstName !== "" &&
      userProfile.firstName !== null &&
      userProfile.firstName !== undefined
    ) {
      localStorage.setItem(config.first_name, userProfile.firstName);
    }

    if (
      userProfile.lastName !== "" &&
      userProfile.lastName !== null &&
      userProfile.lastName !== undefined
    ) {
      localStorage.setItem(config.last_name, userProfile.lastName);
    }

    if (
      userProfile.profileImg !== "" &&
      userProfile.profileImg !== null &&
      userProfile.profileImg !== undefined
    ) {
      localStorage.setItem(config.profile_img, userProfile.profileImg);
    }

    if (
      userProfile.stripeCustomerId !== "" &&
      userProfile.stripeCustomerId !== null &&
      userProfile.stripeCustomerId !== undefined
    ) {
      localStorage.setItem(
        config.stripe_customer_id,
        userProfile.stripeCustomerId
      );
    }

    setUserProfile({
      userId: userProfile.userId,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      emailAddress: userProfile.emailAddress,
      profileImg: userProfile.profileImg,
    });

    //check the subscription
    getCurrentSubscription();
    //check the last payment status
    findLastPaymentStatus();
    //check if there is any pending pal request
    findAllPendingPalRequests();
  };

  const findLastPaymentStatus = async () => {
    const stripeCustomerId = localStorage.getItem(config.stripe_customer_id);
    const paymentStatus = await getLastPaymentStatus(stripeCustomerId);

    if (!paymentStatus) return null;

    //set the payment status
    //console.log('Payment Status');

    setSubscription(paymentStatus);

    //console.log(paymentStatus);
  };

  const getCurrentSubscription = async () => {
    //get the current subscription
    const currentSubscription = await getActiveSubscriptionsFromStripe();

    if (!currentSubscription) {
      //console.log('No Current Subscription');
      localStorage.setItem(config.is_paid, false);
      return null;
    }

    //console.log('Current Subscription');
    //console.log(currentSubscription);
    setCurrentSubscriptionFromStripe(currentSubscription);
    // Check if the subscription is active
    const isPaid =
      currentSubscription.status === "active" ||
      currentSubscription.status === "trialing";
    //console.log('Payment Status: ', isPaid);
    localStorage.setItem(config.is_paid, isPaid);

    // Get the next expiration date
    //can be used for ssetting reminders
    const nextExpirationDate = new Date(
      currentSubscription.current_period_end * 1000
    );
    //console.log('Next Expiration Date: ', nextExpirationDate);

    // Get the last payment date
    const lastPaymentDate = new Date(
      currentSubscription.current_period_start * 1000
    );
    //console.log('Last Payment Date: ', lastPaymentDate);

    //dont need to set it
  };

  const findAllPendingPalRequests = async () => {
    const palRequests = await fetchAllPalRequestsByEmail();
    //console.log('Pal Requests');
    //console.log(palRequests);

    if (!palRequests) return null;

    setPalRequests({
      palRequestCount: palRequests.length,
    });
  };

  /*
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };*/

  return (
    <React.Fragment>
      <ToastContainer />
      <div id="layout-wrapper">
        {/*<NavBar /> need a protected view only show the nav when logged in*/}
        <div className="navbar-header">
          <NavigationBar
            userProfile={userProfile}
            subscription={currentSubscriptionFromStripe}
          />
        </div>

        <div>
          <div className="layout-width">
            <div className="content-page">
              <Routes>
                {/*<Route path='/' element={<LoginPage />} /> */}
                <Route path="/" element={<LoginPage />}>
                  <Route path=":loginCode" element={<LoginPage />} />
                </Route>

                {/*<Route path='/signup' element={<Signup />} />*/}

                <Route path="/signup" element={<Signup />}>
                  <Route path=":inviteCode" element={<Signup />} />
                </Route>

                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <Dashboard isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/createNewBudget"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <CreateNewBudget isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/budgetRelatedExpenses"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <BudgetRelatedExpenses isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path=":categoryId"
                    element={<BudgetRelatedExpenses />}
                  />
                </Route>

                <Route
                  path="/budget"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <CurrentBudget
                        userProfile={userProfile}
                        isDarkMode={isDarkMode}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/pastBudgets"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <PastBudgetGrid isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/budget"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <BudgetDetail isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":budgetId" element={<BudgetDetail />} />
                </Route>

                <Route
                  path="/allBudgetGoals"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <AllBudgetGoals isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/budgetGoal"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <BudgetGoalDetail isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":budgetGoalId" element={<BudgetGoalDetail />} />
                </Route>

                <Route
                  path="/editBudgetGoal"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <EditBudgetGoal isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":budgetGoalId" element={<EditBudgetGoal />} />
                </Route>

                <Route
                  path="/addBudgetGoalFunds"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <AddBudgetGoalFunds isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path=":budgetGoalId"
                    element={<AddBudgetGoalFunds />}
                  />
                </Route>

                <Route
                  path="/expenseCalendar"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <ExpenseCalendarView isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/approvedPalz"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <ApprovedPalz isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/pendingPalz"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <PendingPalz isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />
                {/*TODO: Pending Palz (sent and received) */}
                <Route
                  path="/inviteNewPal"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <InvitePal isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/palz"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <PalzDetail isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":palId" element={<PalzDetail />} />
                </Route>

                <Route
                  path="/createNewExpense"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <CreateNewExpense isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/createNewBudgetGoal"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <CreateNewBudgetGoal isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/paywall"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <Paywall isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/billing"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <Billing isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/allExpenses"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <AllExpenses isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/currentExpenses"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <CurrentExpenses isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/expense/:expenseId"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <ExpenseDetail isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/expense/edit/:expenseId"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <EditExpense isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/manageSubscription"
                  element={
                    <ProtectedRoute user={userToken} redirectPath="/">
                      <ManageSubscription isDarkMode={isDarkMode} />
                    </ProtectedRoute>
                  }
                />

                <Route path="/logout" element={<LogOut />} />
              </Routes>
              <div className="mt-5" />
              {/*	<GeneralFooter />*/}
              <footer
                className={`${getThemeClasses()} tw-py-4 tw-px-6 tw-mt-auto tw-border-t ${
                  isDarkMode ? "tw-border-gray-800" : "tw-border-gray-200"
                }`}
              >
                <div className="tw-max-w-7xl tw-mx-auto">
                  <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center">
                    <div className="tw-mb-2 sm:tw-mb-0">
                      <script>document.write(new Date().getFullYear())</script>{" "}
                      © SavingPalz.
                    </div>
                    <div className="tw-hidden sm:tw-block">
                      Designed & Developed by Gurenter LLC
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
