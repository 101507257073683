import React from "react";
import { Link } from "react-router-dom";
import { BudgetGoalFunds } from "../../../../../types/budgetGoal";

const APPLE_EPOCH_UNIX_TIMESTAMP = 978307200; // Number of seconds between 1970 and 2001

interface BudgetGoalFundsGridProps {
  budgetGoalFunds: BudgetGoalFunds[];
}

const BudgetGoalFundsGrid: React.FC<BudgetGoalFundsGridProps> = ({
  budgetGoalFunds,
}) => {
  const filteredFunds = budgetGoalFunds.filter(
    (fund: BudgetGoalFunds) => fund.fund_date !== "0"
  ); //the API handles the ordering
  const formatAmount = (fund: BudgetGoalFunds) => {
    const amount = fund.fund_amt_added || fund.amount || 0;
    const isDecrease =
      fund.fund_source_name?.toLowerCase().includes("decrease") ||
      fund.source?.toLowerCase().includes("decrease");
    const displayAmount = isDecrease ? -Math.abs(amount) : amount;
    const formattedAmount = displayAmount.toFixed(2);
    const color = isDecrease ? "text-danger" : "text-success";
    const prefix = displayAmount >= 0 ? "+" : "";

    return (
      <div className={`fs-5 ${color}`} style={{ fontWeight: "bold" }}>
        {prefix}${formattedAmount}
      </div>
    );
  };

  return (
    <div className="d-flex flex-wrap justify-content-start">
      {filteredFunds.map((fund: BudgetGoalFunds, index: number) => {
        const fundDate = fund.timestamp || fund.fund_date;
        return (
          <div
            className="card card-height-100 m-2"
            key={fund.budget_goal_fund_id || fund.fund_id || index}
          >
            <div className="card-body">
              <li className="list-group-item">
                <div className="overflow-hidden">
                  <div className="no-padding card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0">
                          Fund Date{" "}
                          <b className="text">
                            {new Date(fundDate).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="overflow-hidden">
                        <h5 className="contact-name fs-13 mb-1">
                          {fund.fund_source_name || fund.source}
                        </h5>
                      </div>
                      <div className="flex-shrink-0 ms-2">
                        {formatAmount(fund)}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BudgetGoalFundsGrid;
