import React, { useState } from "react";
import { getIconUrl } from "../../../../constants/iconUrlMap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { base64ImageString } from "../../../../constants/images/defaultImgs";

const ExpenseGrid = ({ expenses, title = "Recent Expenses" }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("Expense Name");

  const filteredExpenses = expenses
    .filter((expense) =>
      expense.expense_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortOption) {
        case "Expense Name":
          return a.expense_name.localeCompare(b.expense_name);
        case "Amount":
          return a.expense_amt - b.expense_amt;
        case "Date":
          return new Date(a.record_time_stamp) - new Date(b.record_time_stamp);
        default:
          return 0;
      }
    });

  function getOrdinalSuffix(day) {
    if (day % 10 === 1 && day !== 11) {
      return day + "st";
    } else if (day % 10 === 2 && day !== 12) {
      return day + "nd";
    } else if (day % 10 === 3 && day !== 13) {
      return day + "rd";
    } else {
      return day + "th";
    }
  }

  return (
    <div className="card">
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
        <div className="flex-shrink-0">
          <NavDropdown
            title={`Sort by: ${sortOption}`}
            id="nav-dropdown"
            onSelect={(eventKey) => setSortOption(eventKey)}
          >
            <NavDropdown.Item eventKey="Expense Name">
              Expense Name
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="Amount">Amount</NavDropdown.Item>
            <NavDropdown.Item eventKey="Date">Date</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>

      <div className="card-body">
        <div className="search-box mb-3">
          <input
            type="text"
            className="form-control search"
            placeholder="Search for expense..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="ri-search-line search-icon"></i>
        </div>

        <div className="row px-4">
          {filteredExpenses.map((expense) => (
            <div key={expense.expense_id} className="col-xl-3 ">
              <div className="card card-height-100">
                <div className="card-body">
                  <li className="list-group-item" data-id="01">
                    <div className="d-flex align-items-start">
                      <div className="flex-shrink-0 me-3">
                        <div>
                          <img
                            className="image avatar-xs rounded-circle"
                            alt=""
                            src={getIconUrl(expense.expense_icon).url}
                          />
                        </div>
                      </div>

                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="contact-name fs-13 mb-1">
                          <Link
                            to={`/expense/${expense.expense_id}`}
                            className="link text-body"
                          >
                            {expense.expense_name}
                          </Link>
                        </h5>
                        <p className="contact-born text-muted mb-0">
                          {expense.expense_description}
                        </p>
                        {expense.day_due_each_month !== 0 && (
                          <p className="contact-born text-muted mb-0">
                            <a className="link text-body">
                              {`${getOrdinalSuffix(
                                expense.day_due_each_month
                              )} ${new Date(
                                expense.record_time_stamp
                              ).toLocaleString("default", {
                                month: "long",
                                year: "numeric",
                              })}`}
                            </a>
                          </p>
                        )}
                      </div>

                      <div className="flex-shrink-0 ms-2">
                        <div
                          className="fs-5 text"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {"-$"} {Number(expense.expense_amt)?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpenseGrid;
