import React from "react";
import { ChartData } from "chart.js";
import ExpenseBreakdownCard from "../../../../../reusable/cards/dashboard/expenseBreakdownCard";
import ExpenseGrid from "../../../../../reusable/cards/expense/expenseGrid";
import ExpensesByCategoryChart from "../expensesByCategoryChart";
import BasicStatCard from "../basicStatCard";
import LoaderView from "../../../../../reusable/loader/loaderView";
import { ExtendedExpenseModel } from "../../types";

interface PalzExpenseSectionProps {
  lottieAnimation: any; // Replace with specific type if available
  expenseChartData: ChartData<"doughnut">;
  isDarkMode: boolean;
  expensesByCategory: Record<string, number>;
  totalExpenses: number;
  totalTransactions: number;
  recurringExpenses: number;
  nonRecurringExpenses: number;
  expensesByRequestor: number;
  expensesByPal: number;
  combinedUserExpensesAndTransactions: ExtendedExpenseModel[] | null;
  isBudgetLoading: boolean;
  isExpensesLoading: boolean;
}

const PalzExpenseSection: React.FC<PalzExpenseSectionProps> = ({
  lottieAnimation,
  expenseChartData,
  isDarkMode,
  expensesByCategory,
  totalExpenses,
  totalTransactions,
  recurringExpenses,
  nonRecurringExpenses,
  expensesByRequestor,
  expensesByPal,
  combinedUserExpensesAndTransactions,
  isBudgetLoading,
  isExpensesLoading,
}) => {
  const hasValidExpenseData =
    combinedUserExpensesAndTransactions &&
    Array.isArray(combinedUserExpensesAndTransactions) &&
    combinedUserExpensesAndTransactions.length > 0 &&
    combinedUserExpensesAndTransactions[0]?.expense_name;

  return (
    <div>
      <div className="row">
        <div className="col-xl-3">
          {isExpensesLoading ? (
            <div className="card card-height-100">
              <div className="card-body d-flex justify-content-center align-items-center">
                <LoaderView />
              </div>
            </div>
          ) : (
            <ExpenseBreakdownCard
              lottieAnimation={lottieAnimation}
              expenseChartData={expenseChartData}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
        <div className="col-xl-3">
          <ExpensesByCategoryChart
            expensesByCategory={expensesByCategory}
            isLoading={isBudgetLoading || isExpensesLoading}
          />
        </div>
        <div className="col-xl-3">
          <div className="row">
            {isExpensesLoading ? (
              <div className="col-12">
                <div className="card card-height-100">
                  <div className="card-body d-flex justify-content-center align-items-center">
                    <LoaderView />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Expenses"
                    percentage={0}
                    total={totalExpenses}
                    icon="bx bx-wallet text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Transactions"
                    percentage={0}
                    total={totalTransactions}
                    icon="bx bx-transfer text-primary"
                    isMonetary={false}
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Recurring Expenses"
                    percentage={0}
                    total={recurringExpenses}
                    icon="bx bx-receipt text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Non-Recurring Expenses"
                    percentage={0}
                    total={nonRecurringExpenses}
                    icon="bx bx-receipt text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Expenses By Requestor"
                    percentage={0}
                    total={expensesByRequestor}
                    icon="bx bx-user text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Expenses By Pal"
                    percentage={0}
                    total={expensesByPal}
                    icon="bx bx-user text-primary"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        {isExpensesLoading ? (
          <div className="col-12">
            <div className="card">
              <div className="card-body d-flex justify-content-center align-items-center p-4">
                <LoaderView />
              </div>
            </div>
          </div>
        ) : (
          hasValidExpenseData && (
            <ExpenseGrid expenses={combinedUserExpensesAndTransactions} />
          )
        )}
      </div>
    </div>
  );
};

export default PalzExpenseSection;
