import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import MultiLineInput from "../../../reusable/form/multilineinput";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
import { fetchLatestBudget } from "../../../../services/budgets/budgetServiceManagement";
import { getIconUrl } from "../../../../constants/iconUrlMap";
import config from "../../../../constants/config";
import {
  updateExpense,
  fetchExpenseById,
} from "../../../../services/expenses/expenseServiceManagement";
import BudgetAllocationSelect, {
  BudgetSelectOption,
} from "../../../reusable/form/BudgetAllocationSelect";
import { devLog } from "utils/logger";
import LoaderView from "../../../reusable/loader/loaderView";
import MultiImageUploader from "../../../reusable/form/MultiImageUploader";
import { Badge, Button } from "react-bootstrap";
import {
  ExpenseModel,
  ExpenseUpdate,
  ReceiptModel,
  ReceiptUpload,
} from "../../../../types/expenses/expenseTypes";

interface Props {
  isDarkMode: boolean;
}

interface FormErrors {
  [key: string]: string;
}

interface SchemaType {
  [key: string]: any;
}

interface BudgetCategory {
  budget_id: string;
  budget_category_id: string;
  category_name: string;
  total_allocated_for_property: number;
  amount_left: number;
  category_icon?: string;
}

const EditExpense: React.FC<Props> = ({ isDarkMode }) => {
  const { expenseId } = useParams();
  const navigate = useNavigate();
  const pageTitle = "Edit Expense";

  const [expenseData, setExpenseData] = useState<ExpenseModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [budgetAllocationSelectorOptions, setBudgetAllocationSelectorOptions] =
    useState<BudgetSelectOption[]>([]);
  const [selectedBudgetAllocatedOption, setSelectedBudgetAllocatedOption] =
    useState<BudgetSelectOption | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});

  // Add new state for receipt management
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [base64Data, setBase64Data] = useState<
    Array<{ id: number; data: string }>
  >([]);
  const [imagesToUpload, setImagesToUpload] = useState<
    Array<{ base64_data: string; file_name: string }>
  >([]);
  const [attachmentsConfirmed, setAttachmentsConfirmed] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [itemToPreview, setItemToPreview] = useState<{
    base64_data: string;
    file_name: string;
  } | null>(null);
  const [receiptsToRemove, setReceiptsToRemove] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const schema: SchemaType = {
    day_due_each_month: Joi.number().required().label("Day Due Each Month"),
    expense_amt: Joi.number().required().label("Expense Amount"),
    expense_description: Joi.string().required().label("Expense Description"),
    expense_name: Joi.string().required().label("Expense Name"),
    has_insight: Joi.boolean().optional().label("Has Insight"),
    is_re_occuring: Joi.boolean().optional().label("Is Re Occuring"),
    record_time_stamp: Joi.number().allow(0).label("Record Time Stamp"),
    user_pal_id: Joi.string().required().label("User Pal Id"),
    expense_id: Joi.string().allow("").label("Expense Id"),
    budget_category_id: Joi.string().required().label("Budget Category"),
    currency_name: Joi.string().optional().label("Currency Name").allow(""),
    currency_symbol: Joi.string().optional().label("Currency Symbol").allow(""),
    receipt_uploads: Joi.array().optional().label("Receipt Uploads"),
    is_deleted: Joi.boolean().optional().label("Is Deleted").default(false),
    account_id: Joi.string().optional().label("Account Id").allow(""),
    expense_icon: Joi.string().optional().label("Expense Icon").allow(""),
    expense_category: Joi.string()
      .optional()
      .label("Expense Category")
      .allow(""),
    updated_time_stamp: Joi.date().optional().label("Updated Time Stamp"),
    created_at: Joi.date().optional().label("Created At"),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!expenseId) {
          toast.error("No expense ID provided");
          navigate("/currentExpenses");
          return;
        }

        const [expenseData, budgetData] = await Promise.all([
          fetchExpenseById(expenseId),
          fetchLatestBudget(),
        ]);

        if (expenseData) {
          // Create options from current budget categories
          const options =
            budgetData?.budget_categories?.map((category: BudgetCategory) => ({
              value: category.budget_category_id,
              label: category.category_name,
              amount_left: category.amount_left,
              total_allocated: category.total_allocated_for_property,
              icon: category.category_icon || "BudgetExpense",
            })) || [];
          setBudgetAllocationSelectorOptions(options);

          // Check if the expense's budget category exists in current budget
          const categoryExists = options.some(
            (opt: BudgetSelectOption) =>
              opt.value === expenseData.budget_category_id
          );

          // If category doesn't exist in current budget, clear it
          const updatedExpenseData = {
            ...expenseData,
            user_pal_id:
              expenseData.user_pal_id ||
              localStorage.getItem(config.user_id) ||
              "",
            budget_category_id: categoryExists
              ? expenseData.budget_category_id
              : undefined,
          };
          setExpenseData(updatedExpenseData);

          // Only set the selected budget category if it exists in current budget
          if (categoryExists) {
            const currentCategory = options.find(
              (opt: BudgetSelectOption) =>
                opt.value === expenseData.budget_category_id
            );
            if (currentCategory) {
              setSelectedBudgetAllocatedOption(currentCategory);
            }
          } else {
            setSelectedBudgetAllocatedOption(null);
            if (expenseData.budget_category_id) {
              toast.info(
                "Previous budget category is no longer available. Please select a category from the current budget."
              );
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load expense data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [expenseId, navigate]);

  const validate = (): FormErrors | null => {
    if (!expenseData) return null;

    const options = { abortEarly: false };
    const { error } = Joi.validate(expenseData, schema, options);
    if (!error) return null;

    const errors: FormErrors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    devLog(errors);
    return errors;
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }): string | null => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);
    return error ? error.details[0].message : null;
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    if (!expenseData) return;

    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    const errorMessage = validateProperty({ name, value: newValue });
    const newErrors = { ...errors };
    if (errorMessage) {
      newErrors[name] = errorMessage;
    } else {
      delete newErrors[name];
    }

    setExpenseData({
      ...expenseData,
      [name]: newValue,
    });
    setErrors(newErrors);
  };

  const handleBudgetAllocatedChange = (option: BudgetSelectOption | null) => {
    if (option && expenseData) {
      setSelectedBudgetAllocatedOption(option);
      setExpenseData({
        ...expenseData,
        budget_category_id: option.value,
      });
    }
  };

  const handlePreviewAttachment = (attachment: {
    base64_data: string;
    file_name: string;
  }) => {
    if (attachment.base64_data && attachment.file_name) {
      setItemToPreview({
        base64_data: attachment.base64_data,
        file_name: attachment.file_name,
      });
      setShowPreviewModal(true);
    }
  };

  const handleSelectionConfirm = async () => {
    if (selectedFiles.length > 0) {
      const uploads = base64Data.map((item, index) => ({
        base64_data: item.data,
        file_name: selectedFiles[index].name,
      }));

      await setImagesToUpload(uploads);
      await setAttachmentsConfirmed(true);
    }
  };

  const handleRemoveExistingReceipt = (uniqueFileName: string) => {
    if (expenseData?.receipt_uploads) {
      const updatedReceipts = expenseData.receipt_uploads.filter(
        (receipt: ReceiptModel) => receipt.unique_file_name !== uniqueFileName
      );
      setExpenseData({
        ...expenseData,
        receipt_uploads: updatedReceipts,
      });
      setReceiptsToRemove([...receiptsToRemove, uniqueFileName]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!expenseData || validate()) return;

    setIsSubmitting(true);
    try {
      const updateData: ExpenseUpdate = {
        user_pal_id:
          expenseData.user_pal_id || localStorage.getItem(config.user_id) || "",
        expense_id: expenseData.expense_id || "",
        expense_icon: expenseData.expense_icon,
        expense_name: expenseData.expense_name,
        expense_amt: expenseData.expense_amt,
        expense_description: expenseData.expense_description,
        is_re_occuring: expenseData.is_re_occuring,
        day_due_each_month: expenseData.day_due_each_month,
        budget_category_id: expenseData.budget_category_id,
        existing_receipt_uploads: (expenseData.receipt_uploads || []).filter(
          (receipt) =>
            !receiptsToRemove.includes(receipt.unique_file_name || "")
        ),
        new_receipt_uploads: imagesToUpload.map(
          (upload): ReceiptUpload => ({
            base64_data: upload.base64_data,
            file_name: upload.file_name,
            file_type: upload.file_name.split(".").pop() || "",
          })
        ),
        receipts_to_remove: receiptsToRemove,
      };

      const updatedExpense = await updateExpense(updateData);
      if (updatedExpense) {
        toast.success("Expense updated successfully");
        navigate(`/expense/${expenseId}`);
      }
    } catch (error) {
      console.error("Error updating expense:", error);
      toast.error("Failed to update expense");
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  if (isLoading) {
    return <LoaderView />;
  }

  if (!expenseData) {
    return (
      <div className="container-fluid">
        <PageTitleBox
          pageTitle={pageTitle}
          isDarkMode={isDarkMode}
          previousPageLink="/currentExpenses"
          previousPageTitle="Back to Expenses"
        />
        <div className="alert alert-info">Expense not found</div>
      </div>
    );
  }

  return (
    <div
      className="container-fluid"
      data-bs-theme={isDarkMode ? "dark" : "light"}
    >
      <PageTitleBox
        pageTitle={pageTitle}
        isDarkMode={isDarkMode}
        previousPageLink={`/expenses/${expenseId}`}
        previousPageTitle="Back to Expense Details"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                Edit Expense Details
              </h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="live-preview">
                  <div className="row gy-4">
                    <div className="col-xxl-6 col-md-6">
                      <Input
                        name="expense_name"
                        label="Expense Name"
                        value={expenseData.expense_name || ""}
                        onChange={handleInputChange}
                        error={errors.expense_name}
                        type="text"
                        placeholder="Enter expense name"
                        labelClass=""
                        isFocused={false}
                      />
                    </div>

                    <div className="col-xxl-6 col-md-6">
                      <Input
                        name="expense_amt"
                        label="Expense Amount"
                        value={expenseData.expense_amt || 0}
                        onChange={handleInputChange}
                        error={errors.expense_amt}
                        type="number"
                        placeholder="Enter amount"
                        labelClass=""
                        isFocused={false}
                      />
                    </div>

                    <div className="col-xxl-6 col-md-6">
                      <BudgetAllocationSelect
                        value={selectedBudgetAllocatedOption}
                        onChange={handleBudgetAllocatedChange}
                        options={budgetAllocationSelectorOptions}
                        formatCurrency={formatCurrency}
                        isDarkMode={isDarkMode}
                        expense_amount={expenseData.expense_amt || 0}
                      />
                    </div>

                    <div className="col-xxl-6 col-md-6">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_re_occuring"
                          name="is_re_occuring"
                          checked={expenseData.is_re_occuring || false}
                          onChange={handleInputChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_re_occuring"
                        >
                          Recurring Expense
                        </label>
                      </div>
                    </div>

                    {expenseData.is_re_occuring && (
                      <div className="col-xxl-6 col-md-6">
                        <div className="form-group">
                          <label htmlFor="day_due_each_month">
                            Monthly Payment Date
                          </label>
                          <select
                            className="form-select"
                            name="day_due_each_month"
                            value={expenseData.day_due_each_month || 0}
                            onChange={handleInputChange}
                          >
                            <option value="">When is this payment due?</option>
                            {Array.from({ length: 31 }, (_, i) => i + 1).map(
                              (day) => (
                                <option key={day} value={day}>
                                  {day +
                                    (day === 1
                                      ? "st"
                                      : day === 2
                                      ? "nd"
                                      : day === 3
                                      ? "rd"
                                      : "th")}{" "}
                                  day of the month
                                </option>
                              )
                            )}
                          </select>
                          {errors.day_due_each_month && (
                            <div className="invalid-feedback d-block">
                              {errors.day_due_each_month}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-xxl-12 col-md-12">
                      <MultiLineInput
                        name="expense_description"
                        label="Expense Description"
                        value={expenseData.expense_description || ""}
                        onChange={handleInputChange}
                        error={errors.expense_description}
                        placeholder="Enter description"
                        labelClass=""
                        numOfRows={3}
                        isRequired={true}
                        iconClassName=""
                        type="text"
                      />
                    </div>

                    <div className="col-xxl-12 col-md-12">
                      <div>
                        <label>Transaction Receipt(s)</label>
                        {/* Display existing receipts */}
                        {expenseData?.receipt_uploads &&
                          expenseData.receipt_uploads.length > 0 && (
                            <div className="mb-3">
                              <h6>Existing Receipts:</h6>
                              <div className="d-flex flex-wrap gap-2">
                                {expenseData.receipt_uploads.map(
                                  (receipt: ReceiptModel, index: number) => (
                                    <Badge
                                      key={index}
                                      bg="success"
                                      className="d-flex align-items-center gap-2 p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handlePreviewAttachment({
                                          base64_data: receipt.url || "",
                                          file_name:
                                            receipt.file_name ||
                                            "Unnamed Receipt",
                                        })
                                      }
                                    >
                                      <i className="ri-file-line"></i>
                                      {receipt.file_name &&
                                      receipt.file_name.length > 20
                                        ? `${receipt.file_name.substring(
                                            0,
                                            20
                                          )}...`
                                        : receipt.file_name ||
                                          "Unnamed Receipt"}
                                      <Button
                                        variant="link"
                                        className="p-0 ms-2 text-white"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveExistingReceipt(
                                            receipt.unique_file_name || ""
                                          );
                                        }}
                                      >
                                        <i className="ri-close-line"></i>
                                      </Button>
                                    </Badge>
                                  )
                                )}
                              </div>
                            </div>
                          )}

                        {/* Add new receipts */}
                        <div>
                          <h6>Add New Receipts:</h6>
                          <MultiImageUploader
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            base64Data={base64Data}
                            setBase64Data={setBase64Data}
                            imagesToUpload={imagesToUpload}
                            setImagesToUpload={setImagesToUpload}
                            uploadBtnText="Confirm Selection"
                            onConfirm={handleSelectionConfirm}
                          />
                          {attachmentsConfirmed &&
                            imagesToUpload?.length > 0 && (
                              <div className="mt-3">
                                <div className="d-flex flex-wrap gap-2">
                                  {imagesToUpload.map((file, index) => (
                                    <Badge
                                      key={index}
                                      bg="info"
                                      className="d-flex align-items-center gap-2 p-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handlePreviewAttachment(file)
                                      }
                                    >
                                      <i className="ri-file-line"></i>
                                      {file.file_name.length > 20
                                        ? `${file.file_name.substring(
                                            0,
                                            20
                                          )}...`
                                        : file.file_name}
                                    </Badge>
                                  ))}
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="ms-2"
                                    onClick={() => {
                                      setAttachmentsConfirmed(false);
                                      setImagesToUpload([]);
                                    }}
                                  >
                                    <i className="ri-refresh-line me-1"></i>
                                    Change Selection
                                  </Button>
                                </div>
                              </div>
                            )}
                          {!attachmentsConfirmed &&
                            selectedFiles.length > 0 && (
                              <div className="text-danger mt-1">
                                <small>
                                  Please confirm your selection using the
                                  "Confirm Selection" button above
                                </small>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => navigate(`/expenses/${expenseId}`)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary d-flex align-items-center gap-2"
                    disabled={!!validate() || isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditExpense;
