import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../reusable/form/Input";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";

import Joi from "joi-browser";
import MultiLineInput from "../../../reusable/form/multilineinput";
import PageTitleBox from "../../../reusable/portal/general/pageTitleBox";
//so you can copy from the previous budget
import {
  createBudgetAndCategories,
  deactivateOtherBudgets,
  fetchLatestBudget,
  updateAmountLeft,
  fetchAllPastBudgets,
} from "../../../../services/budgets/budgetServiceManagement";
import { getIconUrl, iconUrlMap } from "../../../../constants/iconUrlMap";
import { set } from "lodash";
import Select from "react-select";
import BudgetCardGrid from "../../../reusable/cards/budget/budgetCardGrid";
import CreateBudgetCategoryGrid from "../../../reusable/cards/budget/createBudgetCategoryGrid";
import config from "../../../../constants/config";
import { usePageTitle } from "../../../../utils/hooks/usePageTitle";
import {
  Budget,
  BudgetCategory,
  CreatorProfile,
} from "../../../../types/budget";
import IconSelector from "../../../reusable/form/IconSelector";
//future: the copy from previous budget
//Source of income -> budget -> budget categories -> expenses
//Suggest categories from the previous budget and system budget categories
//TODO: convert to tsx and also smart fill the category name and icon from the previous budget that matches, also have standard budget categories that can be used to reduce the number of re-entries and typos

interface CreateNewBudgetProps {
  isDarkMode: boolean;
}

const CreateNewBudget = ({ isDarkMode }: CreateNewBudgetProps) => {
  const pageTitle = "Create New Budget";
  usePageTitle({ title: pageTitle });
  const previousPageTitle = "Budget";
  const previousPageLink = "/budget";
  const [ignored, setIgnored] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // Previous budgets for copying
  const [previousBudgets, setPreviousBudgets] = useState<Budget[]>([]);
  const [showPreviousBudgets, setShowPreviousBudgets] = useState(true);
  const [selectedBudget, setSelectedBudget] = useState<Budget | null>(null);
  const [categoriesCopied, setCategoriesCopied] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  //merege budget and budget categories later when storing
  //profile info
  const first_name = localStorage.getItem(config.first_name);
  const last_name = localStorage.getItem(config.last_name);
  const email_address = localStorage.getItem(config.email);
  const user_id = localStorage.getItem(config.user_id);

  const [budgetFormData, setBudgetFormData] = useState<Partial<Budget>>({
    is_active_budget: true,
  });

  const [isActiveBudget, setIsActiveBudget] = useState(
    budgetFormData.is_active_budget
  );

  //managed as a list of objects
  const [budgetCategoriesFormData, setBudgetCategoriesFormData] = useState<
    BudgetCategory[]
  >([]);

  //temporary object to hold the new category
  const [newCategory, setNewCategory] = useState<Partial<BudgetCategory>>({
    amount_left: 0,
    amount_rolled_over_from_previous: 0,
    budget_category_id: "",
    budget_id: "",
    category_icon: "",
    category_name: "",
    month: 0,
    record_time_stamp: 0,
    system_budget_cat_id: "",
    total_allocated_for_property: 0,
    year: 0,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  //validate only the budget categories
  const schema = {
    budget_date: Joi.date().required().label("Budget Date"),
    budget_id: Joi.string().label("Budget Id").optional().allow(""),
    creator_profile: Joi.object().optional().label("Creator Profile"),
    email_address: Joi.string().label("Email Address").optional().allow(""),
    first_name: Joi.string().label("First Name").optional().allow(""),
    is_active_budget: Joi.boolean().label("Is Active Budget").optional(),
    last_name: Joi.string().label("Last Name").optional().allow(""),
    month: Joi.number().label("Month").optional(),
    record_time_stamp: Joi.number().label("Record Time Stamp").optional(),
    total_left: Joi.number().label("Total Left").optional(),
    total_sum: Joi.number().label("Total Sum").optional(),
    user_id: Joi.string().label("User Id").optional().allow(""),
    year: Joi.number().label("Year").optional(),
  };

  //need to find a way to validate the budget categories whih is a list of objects
  const budgetCategorySchema = {
    category_icon: Joi.string().required().label("Category Icon"),
    category_name: Joi.string().required().label("Category Name"),
    total_allocated_for_property: Joi.number()
      .required()
      .label("Total Allocated For Property"),
    //the rest of the fields are not required and are set by the system
    amount_left: Joi.number().label("Amount Left").optional(),
    amount_rolled_over_from_previous: Joi.number()
      .label("Amount Rolled Over From Previous")
      .optional(),
    budget_category_id: Joi.string()
      .label("Budget Category Id")
      .optional()
      .allow(""),
    budget_id: Joi.string().label("Budget Id").optional().allow(""),
    month: Joi.number().label("Month").optional(),
    record_time_stamp: Joi.number().label("Record Time Stamp").optional(),
    system_budget_cat_id: Joi.string()
      .label("System Budget Cat Id")
      .optional()
      .allow(""),
    year: Joi.number().label("Year").optional(),
  };

  const validate = (): Record<string, string> | null => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(budgetFormData, schema, options);

    const validationErrors: Record<string, string> = {};

    if (budgetCategoriesFormData.length === 0) {
      validationErrors.budgetCategories =
        "At least one budget category is required.";
    }

    if (!error && Object.keys(validationErrors).length === 0) return null;

    if (error) {
      for (let item of error.details) {
        validationErrors[item.path[0]] = item.message;
      }
    }

    return validationErrors;
  };

  const validateProperty = ({ name, value }: { name: string; value: any }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name as keyof typeof schema] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const errors = validate();

    if (errors) {
      for (const error in errors) {
        if (errors.hasOwnProperty(error)) {
          toast.error(errors[error]);
        }
      }
    }
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };

  const validateNewCategory = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(newCategory, budgetCategorySchema, options);
    if (!error) return null;

    const errors: Record<string, string> = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleChange = (input: { name: string; value: any }) => {
    const currentErrors = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) currentErrors[input.name] = errorMessage;
    else delete currentErrors[input.name];

    const data = { ...budgetFormData };
    if (input.name === "budget_date") {
      data.budget_date = input.value;
      data.month = input.value.getMonth() + 1;
      data.year = input.value.getFullYear();
    } else if (input.name === "is_re_occuring") {
      data.is_active_budget = (input.value as HTMLInputElement).checked;
    } else {
      (data as any)[input.name] = input.value;
    }
    setBudgetFormData(data);
    setErrors(currentErrors);
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const doSubmit = async () => {
    try {
      setIsSubmitting(true);
      const budget_id = uuidv4();

      if (budgetCategoriesFormData.length > 0) {
        //total budget
        const totalBudget = budgetCategoriesFormData.reduce(
          (acc, category) =>
            acc + Number(category.total_allocated_for_property),
          0
        );

        //update each category with the budget id and ensure each is a number
        let budgetCategoriesFormDataWithBudgetId = [];

        for (let category of budgetCategoriesFormData) {
          let updatedCategory = { ...category, budget_id: budget_id };

          // Ensure properties that should be numbers are numbers
          updatedCategory.amount_left = Number(updatedCategory.amount_left);
          updatedCategory.amount_rolled_over_from_previous = Number(
            updatedCategory.amount_rolled_over_from_previous
          );
          updatedCategory.total_allocated_for_property = Number(
            updatedCategory.total_allocated_for_property
          );
          updatedCategory.month = Number(updatedCategory.month);
          updatedCategory.record_time_stamp = Number(
            updatedCategory.record_time_stamp
          );
          updatedCategory.year = Number(updatedCategory.year);

          budgetCategoriesFormDataWithBudgetId.push(updatedCategory);
        }

        const budgetData = {
          budget_id: budget_id,
          creator_profile: {
            creator_profile_id: "",
            email_address: email_address,
            profile_img: "",
            first_name: first_name,
            last_name: last_name,
            user_id: user_id,
          },
          email_address: email_address,
          first_name: first_name,
          is_active_budget: isActiveBudget,
          last_name: last_name,
          month: Number(budgetFormData.month),
          record_time_stamp: Date.now(),
          total_left: Number(totalBudget),
          total_sum: Number(totalBudget),
          user_id: user_id,
          year: Number(budgetFormData.year),
          budget_categories: budgetCategoriesFormDataWithBudgetId,
        };

        //console.log('Budget Data:', budgetData);
        //submit to the server
        const response = await createBudgetAndCategories(budgetData);
        //console.log('Response:', response);
        if (!response.is_error) {
          //toast.success('Budget created successfully.');
          const deactivated = await deactivateOtherBudgets(budget_id);
          if (deactivated) {
            //console.log('Deactivated:', deactivated.server_message);
            toast.success(`${response.server_message}`);

            navigate("/budget");
          } else {
            toast.error("Budget could not be created.");
          }
        } else {
          toast.error("Budget could not be created.");
          return;
        }
      } else {
        toast.error("Please add at least one budget category.");
      }
    } catch (ex: unknown) {
      if (
        ex &&
        typeof ex === "object" &&
        "response" in ex &&
        ex.response &&
        typeof ex.response === "object" &&
        "status" in ex.response &&
        ex.response.status === 400 &&
        "data" in ex.response
      ) {
        const newErrors = { ...errors };
        newErrors.email = String(ex.response.data);
        setErrors(newErrors);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const [selectedOptionExpenseIcon, setSelectedOptionExpenseIcon] =
    useState(null);
  const [iconSelectOptions, setIconSelectOptions] = useState<
    Array<{ value: string; label: string; url: string }>
  >([]);

  const getIconOptions = () => {
    const options = Object.keys(iconUrlMap).map((key) => {
      const iconData = iconUrlMap[key as keyof typeof iconUrlMap];
      return { value: key, label: iconData.val, url: iconData.url };
    });
    setIconSelectOptions(options);
  };

  const handleExpenseChangeIcon = (_selectedExpenseIconOption: any) => {
    setSelectedOptionExpenseIcon(_selectedExpenseIconOption);
    handleChange({
      name: "expense_icon",
      value: _selectedExpenseIconOption ? _selectedExpenseIconOption.value : "",
    });
  };

  const updateCategory = (updatedCategory: BudgetCategory) => {
    setBudgetCategoriesFormData((prevCategories) =>
      prevCategories.map((category) =>
        category.budget_category_id === updatedCategory.budget_category_id
          ? updatedCategory
          : category
      )
    );
  };

  const deleteCategory = (categoryId: string) => {
    setBudgetCategoriesFormData((prevCategories) =>
      prevCategories.filter(
        (category) => category.budget_category_id !== categoryId
      )
    );
  };

  const copyBudgetCategories = (budget: any) => {
    // Prevent multiple clicks
    if (isCopying) {
      console.log("Copy operation already in progress");
      return;
    }

    // Check if budget has categories
    if (
      !budget ||
      !budget.budget_categories ||
      budget.budget_categories.length === 0
    ) {
      toast.error("No categories found in the selected budget");
      return;
    }

    // Set copying flag
    setIsCopying(true);

    // Instead of clearing existing categories, we'll merge them
    console.log("Copying categories from budget:", budget);
    console.log("Original categories:", budget.budget_categories);

    try {
      // Create new category objects with new IDs but keep the same data
      const copiedCategories = budget.budget_categories.map((category: any) => {
        // Make sure we're working with a clean object
        return {
          budget_category_id: uuidv4(), // Generate new ID
          budget_id: "", // Will be set when the budget is created
          category_name: category.category_name || "",
          category_icon: category.category_icon || "",
          total_allocated_for_property:
            Number(category.total_allocated_for_property) || 0,
          amount_left: Number(category.total_allocated_for_property) || 0, // Reset amount left to full allocation
          amount_rolled_over_from_previous: 0, // Reset rolled over amount
          month: budgetFormData.month || 0,
          year: budgetFormData.year || 0,
          record_time_stamp: Date.now(),
          system_budget_cat_id: category.system_budget_cat_id || "",
        };
      });

      console.log("Copied categories:", copiedCategories);

      // Merge with existing categories, avoiding duplicates by category name
      setBudgetCategoriesFormData((prevCategories) => {
        // Get existing category names for duplicate checking
        const existingCategoryNames = prevCategories.map((cat) =>
          cat.category_name.toLowerCase().trim()
        );

        // Filter out categories that already exist (case-insensitive)
        const newCategoriesToAdd = copiedCategories.filter(
          (cat: any) =>
            !existingCategoryNames.includes(
              cat.category_name.toLowerCase().trim()
            )
        );

        // If no new categories to add, show a message
        if (newCategoriesToAdd.length === 0) {
          toast.info(
            "All categories from the selected budget already exist in your current budget."
          );
          return prevCategories;
        }

        // Combine existing categories with new ones
        const mergedCategories = [...prevCategories, ...newCategoriesToAdd];

        // Show success message
        toast.success(
          `${newCategoriesToAdd.length} new budget categories added from the previous budget!`
        );

        return mergedCategories;
      });

      // Set the categoriesCopied flag to true
      setCategoriesCopied(true);

      // Force a re-render immediately
      setIgnored();

      // Log the result after a short delay and reset copying flag
      setTimeout(() => {
        console.log(
          "Current budget categories after copy:",
          budgetCategoriesFormData
        );
        // Reset copying flag
        setIsCopying(false);
      }, 100);

      // Hide the previous budgets section after copying
      setShowPreviousBudgets(false);

      // Scroll to the categories section
      const categoriesSection = document.getElementById(
        "budget-categories-section"
      );
      if (categoriesSection) {
        categoriesSection.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error copying budget categories:", error);
      toast.error("Error copying budget categories");
      setIsCopying(false);
    }
  };

  useEffect(() => {
    //future enhancements, get the latest budget and copy the categories and previous budget categories category name to auto fill name and icon
    setIsLoading(true);
    window.scrollTo(0, 0);

    setInitialBudgetMonth();
    fetchPreviousBudgets();
    getIconOptions();

    setIsLoading(false);
  }, []);

  // Set the selected budget when previousBudgets changes
  useEffect(() => {
    if (previousBudgets && previousBudgets.length > 0) {
      // Look for an active budget first
      const activeBudget = previousBudgets.find(
        (budget) => budget.is_active_budget
      );

      // If an active budget exists, select it; otherwise, select the first budget
      setSelectedBudget(activeBudget || previousBudgets[0]);
    }
  }, [previousBudgets]);

  const fetchPreviousBudgets = async () => {
    try {
      const allBudgets = await fetchAllPastBudgets();
      console.log("All fetched budgets:", allBudgets);

      // Sort budgets by date (newest first)
      const sortedBudgets = allBudgets.sort((a: any, b: any) => {
        const dateA = new Date(a.year, a.month - 1);
        const dateB = new Date(b.year, b.month - 1);
        return dateB.getTime() - dateA.getTime();
      });

      console.log("Sorted budgets:", sortedBudgets);

      // Get the last 3 budgets
      const lastThreeBudgets = sortedBudgets.slice(0, 3);
      console.log("Last three budgets:", lastThreeBudgets);

      setPreviousBudgets(lastThreeBudgets);

      // If no budgets found, hide the previous budgets section
      if (lastThreeBudgets.length === 0) {
        setShowPreviousBudgets(false);
      }
    } catch (error) {
      console.error("Error fetching previous budgets:", error);
      setShowPreviousBudgets(false);
    }
  };

  const setInitialBudgetMonth = async () => {
    const currentDate = new Date();
    setBudgetFormData({
      ...budgetFormData,
      budget_date: currentDate,
      month: currentDate.getMonth() + 1, // JavaScript months are 0-indexed
      year: currentDate.getFullYear(),
    });
  };

  const getMonthName = (monthNumber: number) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthNumber - 1]; // Subtract 1 because arrays are 0-indexed
  };

  const capitalize = (str: string) => {
    return str
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatOptionLabel = ({
    value,
    label,
    url,
  }: {
    value: string;
    label: string;
    url: string;
  }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px",
      }}
    >
      <img
        src={url}
        alt={label}
        style={{
          width: "24px",
          height: "24px",
          objectFit: "contain",
        }}
      />
    </div>
  );

  const [selectedCategoryForEdit, setSelectedCategoryForEdit] =
    useState<BudgetCategory | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditCategory = (category: BudgetCategory) => {
    setSelectedCategoryForEdit({ ...category });
    setShowEditModal(true);
  };

  // Add the edit modal component
  const renderEditModal = () => {
    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Budget Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCategoryForEdit && (
            <div className="row gy-3">
              <div className="col-12">
                <label>Category Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedCategoryForEdit.category_name}
                  onChange={(e) => {
                    setSelectedCategoryForEdit({
                      ...selectedCategoryForEdit,
                      category_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-12">
                <IconSelector
                  name="expense_icon"
                  label="Category Icon"
                  value={
                    iconSelectOptions.find(
                      (option) =>
                        option.value === selectedCategoryForEdit.category_icon
                    ) || null
                  }
                  onChange={(selectedOption) => {
                    setSelectedCategoryForEdit({
                      ...selectedCategoryForEdit,
                      category_icon: selectedOption ? selectedOption.value : "",
                    });
                  }}
                  isDarkMode={isDarkMode}
                />
              </div>
              <div className="col-12">
                <label>Amount Allocated</label>
                <div className="input-group">
                  <span className="input-group-text">$</span>
                  <input
                    type="number"
                    className="form-control"
                    value={selectedCategoryForEdit.total_allocated_for_property}
                    onChange={(e) => {
                      setSelectedCategoryForEdit({
                        ...selectedCategoryForEdit,
                        total_allocated_for_property: Number(e.target.value),
                        amount_left: Number(e.target.value), // Update amount_left to match
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => {
              if (selectedCategoryForEdit) {
                deleteCategory(selectedCategoryForEdit.budget_category_id);
                setShowEditModal(false);
              }
            }}
          >
            Delete Category
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              if (selectedCategoryForEdit) {
                updateCategory(selectedCategoryForEdit);
                setShowEditModal(false);
              }
            }}
          >
            Update Category
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleTooltipClick = (e: React.MouseEvent) => {
    try {
      e.preventDefault();
      const target = e.currentTarget;
      const title = target.getAttribute("title");

      if (!title) return; // No tooltip text

      // Remove any existing tooltips
      const existingTooltips = document.querySelectorAll(".custom-tooltip");
      existingTooltips.forEach((tooltip) => {
        if (tooltip && tooltip.parentNode) {
          document.body.removeChild(tooltip);
        }
      });

      // Create a simple tooltip-like popup
      const tooltip = document.createElement("div");
      tooltip.className = "custom-tooltip";
      tooltip.innerHTML = title;
      tooltip.style.position = "absolute";
      tooltip.style.backgroundColor = isDarkMode ? "#292e33" : "#fff";
      tooltip.style.color = isDarkMode ? "#e9ecef" : "#212529";
      tooltip.style.border = `1px solid ${isDarkMode ? "#3c3f42" : "#dee2e6"}`;
      tooltip.style.borderRadius = "4px";
      tooltip.style.padding = "8px 12px";
      tooltip.style.maxWidth = "250px";
      tooltip.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
      tooltip.style.zIndex = "9999";
      tooltip.style.fontSize = "14px";

      // Add arrow
      const arrow = document.createElement("div");
      arrow.style.position = "absolute";
      arrow.style.width = "10px";
      arrow.style.height = "10px";
      arrow.style.backgroundColor = isDarkMode ? "#292e33" : "#fff";
      arrow.style.border = `1px solid ${isDarkMode ? "#3c3f42" : "#dee2e6"}`;
      arrow.style.borderRight = "none";
      arrow.style.borderBottom = "none";
      arrow.style.bottom = "-5px";
      arrow.style.left = "50%";
      arrow.style.transform = "translateX(-50%) rotate(225deg)";
      tooltip.appendChild(arrow);

      // Position the tooltip
      document.body.appendChild(tooltip);
      const rect = target.getBoundingClientRect();

      // Calculate position, ensuring it stays on screen
      let top = rect.top - tooltip.offsetHeight - 5 + window.scrollY;
      let showBelow = false;

      if (top < 10) {
        top = rect.bottom + 10 + window.scrollY; // Show below if not enough space above
        showBelow = true;
      }

      let left =
        rect.left + rect.width / 2 - tooltip.offsetWidth / 2 + window.scrollX;
      if (left < 10) left = 10; // Ensure it's not off-screen left
      if (left + tooltip.offsetWidth > window.innerWidth - 10)
        left = window.innerWidth - tooltip.offsetWidth - 10; // Ensure it's not off-screen right

      tooltip.style.top = `${top}px`;
      tooltip.style.left = `${left}px`;

      // Adjust arrow position based on whether tooltip is above or below
      if (showBelow) {
        arrow.style.bottom = "auto";
        arrow.style.top = "-5px";
        arrow.style.transform = "translateX(-50%) rotate(45deg)";
      }

      // Add a click event to the document to remove the tooltip
      const removeTooltip = (event: MouseEvent) => {
        if (tooltip.parentNode) {
          document.body.removeChild(tooltip);
          document.removeEventListener("click", removeTooltip);
        }
      };

      // Remove the tooltip when clicking anywhere
      setTimeout(() => {
        document.addEventListener("click", removeTooltip);
      }, 100);

      // Also remove the tooltip after a delay
      setTimeout(() => {
        if (tooltip.parentNode) {
          document.body.removeChild(tooltip);
        }
      }, 3000);
    } catch (error) {
      console.error("Error showing tooltip:", error);
    }
  };

  return (
    <React.Fragment>
      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          <PageTitleBox
            pageTitle={pageTitle}
            isDarkMode={isDarkMode}
            previousPageTitle={previousPageTitle}
            previousPageLink={previousPageLink}
          />

          {/* Previous Budgets Section */}
          {showPreviousBudgets && previousBudgets.length > 0 && (
            <div className="row mb-4 content-align-right">
              <div className="col-lg-3">
                <div
                  style={{
                    border: `1px solid ${isDarkMode ? "#3c3f42" : "#dee2e6"}`,
                    borderRadius: "8px",
                    padding: "20px",
                    backgroundColor: isDarkMode ? "#292e33" : "#fff",
                  }}
                >
                  <h5
                    className="mb-3"
                    style={{
                      fontWeight: "500",
                      color: isDarkMode ? "#e9ecef" : "#333",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Looks like you have previous budgets</span>
                    {selectedBudget && selectedBudget.budget_categories && (
                      <div>
                        <span
                          className="badge bg-info me-2"
                          style={{ fontSize: "12px" }}
                        >
                          {selectedBudget.budget_categories.length} categories
                        </span>
                        {selectedBudget.is_active_budget && (
                          <span
                            className="badge bg-success"
                            style={{ fontSize: "12px" }}
                          >
                            Active
                          </span>
                        )}
                      </div>
                    )}
                  </h5>

                  <div className="mb-3" style={{ position: "relative" }}>
                    <select
                      className="form-select"
                      value={
                        selectedBudget
                          ? selectedBudget.budget_id
                          : previousBudgets[0]
                          ? previousBudgets[0].budget_id
                          : ""
                      }
                      onChange={(e) => {
                        const selected = previousBudgets.find(
                          (budget) => budget.budget_id === e.target.value
                        );
                        setSelectedBudget(selected || null);
                      }}
                      style={{
                        padding: "12px 16px",
                        borderRadius: "6px",
                        border: `1px solid ${
                          isDarkMode ? "#3c3f42" : "#dee2e6"
                        }`,
                        backgroundColor: isDarkMode ? "#31363c" : "#f8f9fa",
                        fontSize: "14px",
                        appearance: "none",
                        width: "100%",
                        color: isDarkMode ? "#adb5bd" : "#495057",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      aria-label="Select a previous budget"
                    >
                      {previousBudgets.map((budget, index) => {
                        // Calculate the number of categories and total amount
                        const categoryCount = budget.budget_categories
                          ? budget.budget_categories.length
                          : 0;
                        const totalAmount = budget.budget_categories
                          ? budget.budget_categories.reduce(
                              (sum, cat) =>
                                sum +
                                Number(cat.total_allocated_for_property || 0),
                              0
                            )
                          : 0;

                        // Check if this is the selected budget
                        const isSelected = selectedBudget
                          ? selectedBudget.budget_id === budget.budget_id
                          : index === 0;

                        // Check if this is an active budget
                        const isActive = budget.is_active_budget;

                        return (
                          <option
                            key={index}
                            value={budget.budget_id}
                            style={{
                              fontWeight: isSelected ? "bold" : "normal",
                              backgroundColor: isSelected
                                ? isDarkMode
                                  ? "#2f343a"
                                  : "#e9ecef"
                                : "transparent",
                            }}
                          >
                            {getMonthName(budget.month)} {budget.year}'s Budget
                            ({categoryCount} categories, $
                            {totalAmount.toFixed(2)})
                            {isActive ? " (Active)" : ""}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: isDarkMode ? "#adb5bd" : "inherit",
                      }}
                    >
                      <i className="mdi mdi-chevron-down"></i>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      const budgetToCopy = selectedBudget || previousBudgets[0];
                      copyBudgetCategories(budgetToCopy);
                    }}
                    disabled={isCopying}
                    style={{
                      padding: "10px 16px",
                      borderRadius: "6px",
                      fontSize: "14px",
                      backgroundColor: isDarkMode ? "#405189" : "#3b5998",
                      border: "none",
                      opacity: isCopying ? 0.7 : 1,
                      cursor: isCopying ? "not-allowed" : "pointer",
                      fontWeight: "500",
                      transition: "all 0.2s ease",
                      color: "#fff",
                    }}
                  >
                    {isCopying ? "Copying..." : "Copy"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Step-by-Step Budget Creation Guide */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    <i className="mdi mdi-information-outline me-2 text-info"></i>
                    How to Create a Budget
                  </h4>
                  <div className="d-flex flex-wrap mt-3">
                    <div className="d-flex align-items-start me-4 mb-3">
                      <div
                        className="badge bg-primary rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                          marginTop: "2px",
                        }}
                      >
                        1
                      </div>
                      <div>
                        <h6 className="mb-1">Add Budget Categories</h6>
                        <p className="text-muted mb-0">
                          First, add categories for your budget below
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start me-4 mb-3">
                      <div
                        className="badge bg-primary rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                          marginTop: "2px",
                        }}
                      >
                        2
                      </div>
                      <div>
                        <h6 className="mb-1">Set Budget Details</h6>
                        <p className="text-muted mb-0">
                          Choose month and active status
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start mb-3">
                      <div
                        className="badge bg-primary rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                          marginTop: "2px",
                        }}
                      >
                        3
                      </div>
                      <div>
                        <h6 className="mb-1">Create Your Budget</h6>
                        <p className="text-muted mb-0">
                          Click "Create Budget" to finalize
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Budget Categories Section - Moved to top for better flow */}
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="card" id="budget-categories-section">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <span className="badge bg-primary rounded-circle me-2">
                      1
                    </span>
                    Add Budget Categories
                    <span className="ms-2 fs-6 text-muted fw-normal">
                      (Total: $
                      {budgetCategoriesFormData
                        .reduce(
                          (acc, category) =>
                            acc +
                            Number(category.total_allocated_for_property || 0),
                          0
                        )
                        .toFixed(2)}
                      )
                    </span>
                  </h4>
                  <div className="flex-shrink-0">
                    {categoriesCopied && (
                      <span className="badge bg-success">
                        <i className="mdi mdi-check me-1"></i>
                        Categories copied successfully
                      </span>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-link text-muted ms-2"
                      onClick={handleTooltipClick}
                      title="Add categories to organize your budget. Each category represents a type of expense."
                    >
                      <i className="mdi mdi-help-circle-outline"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="live-preview">
                    <div className="row gy-4">
                      <div className="col-xxl-3 col-md-6">
                        <div>
                          <label>
                            Category Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={newCategory.category_name}
                            onChange={(e) => {
                              setNewCategory({
                                ...newCategory,
                                category_name: e.target.value,
                              });
                            }}
                            placeholder="e.g., Groceries, Utilities"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-3 col-md-6">
                        <div>
                          <IconSelector
                            name="expense_icon"
                            label="Category Icon"
                            required={true}
                            value={
                              iconSelectOptions.find(
                                (option) =>
                                  option.value === newCategory.category_icon
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              setNewCategory({
                                ...newCategory,
                                category_icon: selectedOption
                                  ? selectedOption.value
                                  : "",
                              });
                            }}
                            isDarkMode={isDarkMode}
                          />
                        </div>
                      </div>
                      <div className="col-xxl-3 col-md-6">
                        <div>
                          <label>
                            Amount Allocated
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                              type="number"
                              className="form-control"
                              name="total_allocated_for_property"
                              value={newCategory.total_allocated_for_property}
                              onClick={(
                                e: React.MouseEvent<HTMLInputElement>
                              ) => {
                                const target = e.target as HTMLInputElement;
                                if (target.value === "0") {
                                  target.value = "";
                                }
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setNewCategory({
                                  ...newCategory,
                                  total_allocated_for_property: Number(
                                    e.target.value
                                  ),
                                });
                              }}
                              placeholder="0.00"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-md-6 d-flex align-items-end">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            const errors = validateNewCategory();
                            if (errors) {
                              // Show error message
                              for (const error in errors) {
                                if (errors.hasOwnProperty(error)) {
                                  toast.error(errors[error]);
                                }
                              }
                            } else {
                              // Create a copy of newCategory and add the new properties
                              const newCategoryWithAdditionalProps: BudgetCategory =
                                {
                                  amount_left:
                                    Number(
                                      newCategory.total_allocated_for_property
                                    ) || 0,
                                  amount_rolled_over_from_previous: 0,
                                  budget_category_id: uuidv4(),
                                  budget_id: "",
                                  category_icon:
                                    newCategory.category_icon || "",
                                  category_name:
                                    newCategory.category_name || "",
                                  month: budgetFormData.month || 0,
                                  record_time_stamp: Date.now(),
                                  system_budget_cat_id: "",
                                  total_allocated_for_property:
                                    Number(
                                      newCategory.total_allocated_for_property
                                    ) || 0,
                                  year: budgetFormData.year || 0,
                                };

                              // Add the new category to budgetCategoriesFormData
                              setBudgetCategoriesFormData([
                                ...budgetCategoriesFormData,
                                newCategoryWithAdditionalProps,
                              ]);

                              setNewCategory({
                                amount_left: 0,
                                amount_rolled_over_from_previous: 0,
                                budget_category_id: "",
                                budget_id: "",
                                category_icon: "",
                                category_name: "",
                                month: 0,
                                record_time_stamp: 0,
                                system_budget_cat_id: "",
                                total_allocated_for_property: 0,
                                year: 0,
                              });
                            }
                          }}
                        >
                          <i className="mdi mdi-plus-circle me-1"></i>
                          Add Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Display added categories */}
                {budgetCategoriesFormData &&
                  budgetCategoriesFormData.length > 0 &&
                  budgetCategoriesFormData[0].category_name !== "" && (
                    <div className="card-footer bg-light">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="mb-0">Added Categories</h5>
                        <div className="text-muted small">
                          <i className="mdi mdi-information-outline me-1"></i>
                          Click on any category to edit or delete
                        </div>
                      </div>

                      <div className="row">
                        {budgetCategoriesFormData.map((category, index) => {
                          const amountBudgeted =
                            category.total_allocated_for_property || 0;

                          return (
                            <div
                              key={index}
                              className="col-xl-3 col-md-4 col-sm-6 mb-3"
                            >
                              <div
                                className="card card-height-100 shadow-sm h-100"
                                style={{
                                  cursor: "pointer",
                                  borderColor: isDarkMode
                                    ? "#3c3f42"
                                    : "#dee2e6",
                                  backgroundColor: isDarkMode
                                    ? "#292e33"
                                    : "#fff",
                                  transition: "transform 0.2s, box-shadow 0.2s",
                                }}
                                onClick={() => {
                                  handleEditCategory(category);
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.transform =
                                    "translateY(-3px)";
                                  e.currentTarget.style.boxShadow =
                                    "0 5px 15px rgba(0,0,0,0.1)";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.transform =
                                    "translateY(0)";
                                  e.currentTarget.style.boxShadow = "";
                                }}
                              >
                                <div className="card-body p-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-shrink-0 me-3">
                                      <div className="avatar-sm">
                                        <span
                                          className="avatar-title rounded-circle"
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? "rgba(255, 255, 255, 0.1)"
                                              : "rgba(0, 0, 0, 0.05)",
                                            padding: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={
                                              getIconUrl(category.category_icon)
                                                .url
                                            }
                                            alt={category.category_name}
                                            style={{
                                              width: "24px",
                                              height: "24px",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>

                                    <div className="flex-grow-1 overflow-hidden">
                                      <h5 className="fs-14 mb-1 text-truncate">
                                        {category.category_name}
                                      </h5>
                                      <p className="text-muted mb-0">
                                        ${Number(amountBudgeted).toFixed(2)}
                                      </p>
                                    </div>

                                    <div className="flex-shrink-0">
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-sm btn-light"
                                          type="button"
                                        >
                                          <i className="mdi mdi-pencil"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          {/* Budget Details Section */}
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <span className="badge bg-primary rounded-circle me-2">
                      2
                    </span>
                    Budget Details
                  </h4>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-link text-muted"
                      onClick={handleTooltipClick}
                      title="Set the month for your budget and whether it should be active."
                    >
                      <i className="mdi mdi-help-circle-outline"></i>
                    </button>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        <div className="col-xxl-6 col-md-6">
                          <div>
                            <label
                              htmlFor="budget_date"
                              style={{ display: "block" }}
                            >
                              Budget Month & Year
                              <span className="text-danger">*</span>
                              <span
                                className="d-block text-muted fs-6 fw-normal mt-1"
                                style={{ fontSize: "12px" }}
                              >
                                Select the month and year this budget will apply
                                to
                              </span>
                            </label>
                            <DatePicker
                              name="budget_date"
                              className="form-control"
                              selected={
                                budgetFormData.budget_date
                                  ? new Date(budgetFormData.budget_date)
                                  : new Date()
                              }
                              onChange={(date: Date | null) =>
                                handleChange({
                                  name: "budget_date",
                                  value: date || new Date(),
                                })
                              }
                              dateFormat="MMMM yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              showFourColumnMonthYearPicker
                              placeholderText="Select month and year"
                              popperClassName={
                                isDarkMode ? "react-datepicker-dark" : ""
                              }
                              popperPlacement="bottom-start"
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ]}
                              customInput={
                                <input
                                  className="form-control"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "#31363c"
                                      : "#fff",
                                    color: isDarkMode ? "#e9ecef" : "inherit",
                                    border: `1px solid ${
                                      isDarkMode ? "#3c3f42" : "#ced4da"
                                    }`,
                                  }}
                                />
                              }
                            />
                            <style>
                              {`
                              .react-datepicker-dark .react-datepicker {
                                background-color: #292e33;
                                border-color: #3c3f42;
                              }
                              .react-datepicker-dark .react-datepicker__header {
                                background-color: #31363c;
                                border-bottom-color: #3c3f42;
                              }
                              .react-datepicker-dark .react-datepicker__month-text {
                                color: #e9ecef;
                              }
                              .react-datepicker-dark .react-datepicker__month-text:hover {
                                background-color: #405189;
                              }
                              .react-datepicker-dark .react-datepicker__month--selected {
                                background-color: #405189;
                              }
                              .react-datepicker-dark .react-datepicker__navigation-icon::before {
                                border-color: #adb5bd;
                              }
                              .react-datepicker-dark .react-datepicker__current-month {
                                color: #e9ecef;
                              }
                              `}
                            </style>
                          </div>
                        </div>

                        <div className="col-xxl-6 col-md-6">
                          <div>
                            <label
                              htmlFor="is_re_occuring"
                              style={{ display: "block" }}
                            >
                              Is An Active Budget
                              <button
                                type="button"
                                className="btn btn-sm btn-link text-muted p-0 ms-1"
                                onClick={handleTooltipClick}
                                title="Only one budget can be active at a time. The active budget will be used for tracking expenses."
                              >
                                <i className="mdi mdi-help-circle-outline"></i>
                              </button>
                            </label>
                            <div className="form-check form-switch form-switch-right form-switch-md">
                              <label
                                htmlFor="is_re_occuring"
                                className="form-label text-muted"
                              >
                                {isActiveBudget ? "Yes" : "No"}
                              </label>
                              <input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="is_re_occuring"
                                name="is_re_occuring"
                                checked={budgetFormData.is_active_budget}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleChange({
                                    name: "is_re_occuring",
                                    value: e.target,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {budgetCategoriesFormData.length === 0 && (
                          <div className="text-warning">
                            <i className="mdi mdi-alert-circle me-1"></i>
                            Please add at least one category before creating
                            your budget
                          </div>
                        )}
                      </div>
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => navigate("/currentBudget")}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!!validate() || isSubmitting}
                          className="btn btn-success"
                          type="submit"
                        >
                          {isSubmitting ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Creating...
                            </>
                          ) : (
                            <>
                              <span className="badge bg-primary rounded-circle me-2">
                                3
                              </span>
                              Create Budget
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderEditModal()}
    </React.Fragment>
  );
};

export default CreateNewBudget;
