import React, { useState, useEffect } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ExpenseBreakdownCard = ({
  expenseChartData,
  isDarkMode,
  lottieAnimation,
}) => {
  const [showAllLegends, setShowAllLegends] = useState(false);
  const [chartType, setChartType] = useState("pie");
  const [processedData, setProcessedData] = useState(expenseChartData);
  const [showFullScreen, setShowFullScreen] = useState(false);

  // Process data when it changes
  useEffect(() => {
    if (!expenseChartData || !expenseChartData.labels) return;

    // Group expenses with the same name
    const groupedData = expenseChartData.labels.reduce((acc, label, index) => {
      const value = expenseChartData.datasets[0].data[index];
      const color = expenseChartData.datasets[0].backgroundColor[index];
      const borderColor = expenseChartData.datasets[0].borderColor[index];

      // Use the label as the key for grouping
      if (!acc[label]) {
        acc[label] = {
          value,
          color,
          borderColor,
        };
      } else {
        // Sum up the values for the same label
        acc[label].value += value;
      }
      return acc;
    }, {});

    // Convert grouped data back to chart format
    const groupedLabels = Object.keys(groupedData);
    const groupedValues = groupedLabels.map(
      (label) => groupedData[label].value
    );
    const groupedColors = groupedLabels.map(
      (label) => groupedData[label].color
    );
    const groupedBorderColors = groupedLabels.map(
      (label) => groupedData[label].borderColor
    );

    // Sort data by values in descending order
    const sortedIndices = groupedValues
      .map((_, index) => index)
      .sort((a, b) => groupedValues[b] - groupedValues[a]);

    const sortedLabels = sortedIndices.map((index) => groupedLabels[index]);
    const sortedValues = sortedIndices.map((index) => groupedValues[index]);
    const sortedColors = sortedIndices.map((index) => groupedColors[index]);
    const sortedBorderColors = sortedIndices.map(
      (index) => groupedBorderColors[index]
    );

    setProcessedData({
      labels: sortedLabels,
      datasets: [
        {
          ...expenseChartData.datasets[0],
          data: sortedValues,
          backgroundColor: sortedColors,
          borderColor: sortedBorderColors,
        },
      ],
    });
  }, [expenseChartData]);

  // Check if we have many legend items
  const hasManyItems =
    processedData && processedData.labels && processedData.labels.length > 8;

  const commonChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 12,
          padding: 10,
          color: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
          font: { size: 12 },
          usePointStyle: true,
          pointStyle: "circle",
        },
        maxItems: showAllLegends ? 100 : 8,
        maxHeight: showAllLegends ? 200 : 80,
      },
      tooltip: {
        backgroundColor: isDarkMode
          ? "rgba(0, 0, 0, 0.8)"
          : "rgba(255, 255, 255, 0.9)",
        titleColor: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
        bodyColor: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
        borderColor: isDarkMode
          ? "rgba(255, 255, 255, 0.1)"
          : "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        padding: 12,
        displayColors: true,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `$${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  const barChartOptions = {
    ...commonChartOptions,
    indexAxis: "y",
    plugins: {
      ...commonChartOptions.plugins,
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          color: isDarkMode ? "white" : "#6c757d",
          callback: (value) => `$${value}`,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? "white" : "#6c757d",
        },
      },
    },
  };

  const pieChartOptions = {
    ...commonChartOptions,
    cutout: "65%",
    radius: "100%",
  };

  const fullScreenChartOptions = {
    ...(chartType === "pie" ? pieChartOptions : barChartOptions),
    plugins: {
      ...commonChartOptions.plugins,
      legend:
        chartType === "pie"
          ? {
              position: "right",
              align: "center",
              labels: {
                boxWidth: 12,
                padding: 20,
                color: isDarkMode ? "rgba(255, 255, 255, 0.9)" : "#6c757d",
                font: { size: 14 },
                usePointStyle: true,
                pointStyle: "circle",
              },
              maxHeight: "100%",
            }
          : {
              display: false,
            },
    },
  };

  return (
    <>
      <div className="card card-height-50 shadow-sm">
        <div className="card-header align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Monthly Expenses Breakdown
          </h4>
          <div className="flex-shrink-0 d-flex align-items-center">
            {hasManyItems && (
              <>
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  onClick={() => setShowAllLegends(!showAllLegends)}
                >
                  {showAllLegends ? "Show Less" : "View All"}
                </button>
                <button
                  className="btn btn-sm btn-outline-primary me-2"
                  onClick={() =>
                    setChartType(chartType === "pie" ? "bar" : "pie")
                  }
                >
                  Switch to {chartType === "pie" ? "Bar" : "Pie"} Chart
                </button>
              </>
            )}
            <Button
              variant="light"
              size="sm"
              className="btn-icon"
              onClick={() => setShowFullScreen(true)}
              title="Full Screen"
            >
              <i className="bx bx-fullscreen fs-4"></i>
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div
            className="compact-chart-container"
            style={{
              maxHeight: showAllLegends && hasManyItems ? "450px" : "350px",
            }}
          >
            {processedData &&
            processedData.labels.length > 0 &&
            processedData.datasets.some(
              (dataset) => dataset.data.length > 0
            ) ? (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div
                  className="compact-chart"
                  style={{
                    width: "100%",
                    height: showAllLegends && hasManyItems ? "220px" : "280px",
                    margin: "0 auto",
                  }}
                >
                  {chartType === "pie" ? (
                    <Doughnut data={processedData} options={pieChartOptions} />
                  ) : (
                    <Bar data={processedData} options={barChartOptions} />
                  )}
                </div>
              </div>
            ) : (
              <div className="row compact-animation">
                <Lottie options={lottieAnimation} height={250} width={250} />
                <div className="text-center">
                  <h3>No Expenses Found for the current Period.</h3>
                  <span>An active budget is required.</span>
                </div>
                <div className="mt-3 text-center">
                  <Link to={"/createNewExpense"} className="btn btn-success">
                    Create new Expense
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showFullScreen}
        onHide={() => setShowFullScreen(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Expense Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "70vh", position: "relative" }}>
            {chartType === "pie" ? (
              <Doughnut data={processedData} options={fullScreenChartOptions} />
            ) : (
              <Bar data={processedData} options={fullScreenChartOptions} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseBreakdownCard;
