import React from "react";
import Select from "react-select";
import BudgetOptionLabel from "./BudgetOptionLabel";
import BudgetAmountDisplay from "./BudgetAmountDisplay";
import BudgetPreview from "./BudgetPreview";

export interface BudgetSelectOption {
  value: string;
  label: string;
  amount_left: number;
  total_allocated: number;
  icon?: string;
}

interface BudgetAllocationSelectProps {
  value: BudgetSelectOption | null;
  onChange: (option: BudgetSelectOption | null) => void;
  options: BudgetSelectOption[];
  formatCurrency: (amount: number) => string;
  isDarkMode?: boolean;
  expense_amount?: number;
}

const colors = {
  primary: "#0d6efd",
  darkBg: "#212529",
  darkHover: "#2c3034",
  darkBorder: "#495057",
  lightBorder: "#ced4da",
  white: "white",
  black: "black",
  muted: "#6c757d",
  lightHover: "#f8f9fa",
  lightActive: "#e9ecef",
};

const getSelectStyles = (isDarkMode: boolean) => ({
  option: (base: any, state: any) => ({
    ...base,
    padding: "8px 12px",
    backgroundColor: isDarkMode
      ? state.isSelected
        ? colors.primary
        : state.isFocused
        ? colors.darkHover
        : colors.darkBg
      : state.isSelected
      ? colors.primary
      : state.isFocused
      ? colors.lightHover
      : colors.white,
    color: isDarkMode
      ? colors.white
      : state.isSelected
      ? colors.white
      : colors.black,
    cursor: "pointer",
    "&:active": {
      backgroundColor: isDarkMode
        ? colors.primary
        : state.isSelected
        ? colors.primary
        : colors.lightActive,
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    minHeight: "42px",
    backgroundColor: isDarkMode ? colors.darkBg : colors.white,
    borderColor: isDarkMode
      ? state.isFocused
        ? colors.primary
        : colors.darkBorder
      : state.isFocused
      ? colors.primary
      : colors.lightBorder,
    boxShadow: state.isFocused ? `0 0 0 1px ${colors.primary}` : "none",
    "&:hover": {
      borderColor: colors.primary,
    },
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: isDarkMode ? colors.darkBg : colors.white,
    border: isDarkMode ? `1px solid ${colors.darkBorder}` : base.border,
    boxShadow: isDarkMode ? "0 2px 4px rgba(0, 0, 0, 0.3)" : base.boxShadow,
  }),
  input: (base: any) => ({
    ...base,
    color: isDarkMode ? colors.white : colors.black,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: isDarkMode ? colors.white : colors.black,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: colors.muted,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: colors.muted,
    "&:hover": {
      color: isDarkMode ? colors.white : colors.black,
    },
  }),
});

const getSelectTheme = (isDarkMode: boolean) => (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colors.primary,
    primary75: colors.primary,
    primary50: isDarkMode ? colors.darkHover : colors.lightActive,
    primary25: isDarkMode ? colors.darkHover : colors.lightHover,
    neutral0: isDarkMode ? colors.darkBg : colors.white,
    neutral5: isDarkMode ? colors.darkHover : colors.lightHover,
    neutral10: isDarkMode ? colors.darkHover : colors.lightHover,
    neutral20: isDarkMode ? colors.darkBorder : colors.lightBorder,
    neutral30: isDarkMode ? colors.darkBorder : colors.lightBorder,
    neutral40: colors.muted,
    neutral50: colors.muted,
    neutral60: colors.muted,
    neutral70: isDarkMode ? colors.lightActive : colors.darkBorder,
    neutral80: isDarkMode ? colors.white : colors.black,
    neutral90: isDarkMode ? colors.white : colors.black,
  },
});

const BudgetAllocationSelect: React.FC<BudgetAllocationSelectProps> = ({
  value,
  onChange,
  options,
  formatCurrency,
  isDarkMode = false,
  expense_amount = 0,
}) => {
  const customBudgetOptionLabel = (option: BudgetSelectOption) => (
    <BudgetOptionLabel {...option} formatCurrency={formatCurrency} />
  );

  return (
    <div>
      <label>Choose Budget Category</label>
      <Select<BudgetSelectOption>
        value={value}
        onChange={onChange}
        options={options}
        formatOptionLabel={customBudgetOptionLabel}
        placeholder="Select budget allocation"
        styles={getSelectStyles(isDarkMode)}
        theme={getSelectTheme(isDarkMode)}
      />
      {value && (
        <>
          <BudgetAmountDisplay
            amount_left={value.amount_left}
            total_allocated={value.total_allocated}
            formatCurrency={formatCurrency}
          />
          {expense_amount > 0 && (
            <BudgetPreview
              amount_left={value.amount_left}
              total_allocated={value.total_allocated}
              expense_amount={expense_amount}
              formatCurrency={formatCurrency}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BudgetAllocationSelect;
