import React, { useEffect, useState } from "react";
import config from "../../../constants/config";
import LoaderView from "../../reusable/loader/loaderView";
import { fetchPalByPrimaryPalId } from "../../../services/palz/palzManagement";
import ApprovedPalGrid from "./sub/approvedPalGrid";
import friends_animation from "../../reusable/animations/lottie/friends_animation.json";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import PageTitleBox from "../../reusable/portal/general/pageTitleBox";
import NotAllowedOverlay from "../../reusable/subscription/notAllowed/notAllowedOverlay";

const ApprovedPalz = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const storedUserId = localStorage.getItem(config.user_id);
  const pageTitle = "All Approved Palz";
  const [allApprovedPalz, setAllApprovedPalz] = useState([
    {
      access_right: {
        access_id: "",
        can_comment: false,
        can_edit_budget_goal: false,
        can_edit_expense: false,
        can_edit_income: false,
        can_link_their_budget: false,
        can_view_budget: true,
        can_view_budget_goal: true,
        can_view_expense: true,
        can_view_income: true,
        pal_id: "",
        record_time_stamp: 0,
      },
      date_link_created: "",
      email_address: "",
      expiration_date: 0,
      has_access: false,
      invite_code: "",
      pal_first_name: "",
      pal_id: "",
      pal_last_name: "",
      pal_profile_image: "",
      primary_pal_id: "",
      requestor_first_name: "",
      requestor_last_name: "",
      requestor_profile_image: "",
    },
  ]);

  const emptyPalzLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: friends_animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    getAllApprovedPalz();
  }, []);

  const getAllApprovedPalz = async () => {
    //get all approved palz
    const allApprovedPalz = await fetchPalByPrimaryPalId();
    //console.log(allApprovedPalz);
    setAllApprovedPalz(allApprovedPalz);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && <LoaderView />}
      {/*/Loader */}

      {/* Grid with all approved palz */}

      <div data-bs-theme={isDarkMode ? "dark" : "light"}>
        <div className="container-fluid">
          {allApprovedPalz && allApprovedPalz.length < 1 && (
            <PageTitleBox pageTitle={pageTitle} />
          )}
          <div className="row">
            <div className="col-xl-4">
              {/* <ExpenseBreakdownCard
                expenseChartData={expenseChartData}
                isDarkMode={isDarkMode}
                    />*/}
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  {/*}  <SpendingCard
                    mostSpentCategory={mostSpentCategory}
                    percentageChange={percentageChange}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/*} <BudgetLeftCard
                    leftToBudget={leftToBudget}
                    totalBudget={totalBudget}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/*} <TotalSpendCard
                    totalSpentPercentageChange={totalSpentPercentageChange}
                    totalSpent={totalSpent}
                    secondLatestBudgetSummary={secondLatestBudgetSummary}
                    />*/}
                </div>
                <div className="col-md-6">
                  {/* <SavingCard
                    mostSavedCategory={mostSavedCategory}
                    percentageChange={percentageChangeInSavings}
                    />*/}
                </div>
              </div>
            </div>
          </div>
          {/* end col*/}
        </div>{" "}
        {/* end row*/}
        <div className="row">
          {/* Expense Transaction Grid */}
          {allApprovedPalz &&
          allApprovedPalz.length > 0 &&
          allApprovedPalz[0]?.email_address ? (
            <NotAllowedOverlay message="You need to be a paid user to access this feature">
              <ApprovedPalGrid
                allApprovedPalz={allApprovedPalz}
                storedUserId={storedUserId}
              />
            </NotAllowedOverlay>
          ) : (
            <>
              {" "}
              <div className="col-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row align-items-end">
                      <div className="row p-3">
                        <Lottie
                          options={emptyPalzLottieOptions}
                          height={400}
                          width={400}
                        />

                        <div className="text-center">
                          <h3> No palz seen as yet 🥺</h3>
                          <span>Your Palz are on their way.</span>
                        </div>
                        <div className="mt-3 text-center">
                          <Link
                            to={"/inviteNewPal"}
                            className="btn btn-success"
                          >
                            Invite a Pal
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* end col */}
        </div>
        {/* end row */}
        {/* container-fluid */}
      </div>
      {/* End Page-content */}

      {/* End Page-content */}
    </React.Fragment>
  );
};

export default ApprovedPalz;
