import React from "react";
import { ChartData } from "chart.js";
import BudgetCardGrid from "../../../../../reusable/cards/budget/budgetCardGrid";
import BasicStatCard from "../basicStatCard";
import BudgetByCategoryChart from "../budget/budgetByCategoryChart";
import BudgetBreakdownCard from "../../../../../reusable/cards/dashboard/BudgetBreakdownCard";
import LoaderView from "../../../../../reusable/loader/loaderView";

interface BudgetCategory {
  category_name: string;
  // Add other properties as needed
}

interface BudgetBreakdown {
  budget_categories: BudgetCategory[];
  // Add other properties as needed
}

interface PalzBudgetSectionProps {
  lottieAnimation: any; // Replace with specific type if available
  budgetExpenseChartData: ChartData<"doughnut">;
  isDarkMode: boolean;
  budgetByCategory: Record<string, number>;
  totalBudgetCategories: number;
  totalBudgetCategoriesLeft: number;
  totalBudgetCategoriesSpent: number;
  totalBudgetCategoryByRequestor: number;
  totalBudgetCategoryByPal: number;
  combinedUserBudgetBreakdown: BudgetBreakdown | null;
  isLoading: boolean;
}

const PalzBudgetSection: React.FC<PalzBudgetSectionProps> = ({
  lottieAnimation,
  budgetExpenseChartData,
  isDarkMode,
  budgetByCategory,
  totalBudgetCategories,
  totalBudgetCategoriesLeft,
  totalBudgetCategoriesSpent,
  totalBudgetCategoryByRequestor,
  totalBudgetCategoryByPal,
  combinedUserBudgetBreakdown,
  isLoading,
}) => {
  const hasValidBudgetData =
    combinedUserBudgetBreakdown &&
    Array.isArray(combinedUserBudgetBreakdown.budget_categories) &&
    combinedUserBudgetBreakdown.budget_categories.length > 0 &&
    combinedUserBudgetBreakdown.budget_categories[0]?.category_name;

  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          {isLoading ? (
            <div className="card card-height-100">
              <div className="card-body d-flex justify-content-center align-items-center">
                <LoaderView />
              </div>
            </div>
          ) : (
            <BudgetBreakdownCard
              lottieAnimation={lottieAnimation}
              budgetExpenseChartData={budgetExpenseChartData}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
        <div className="col-xl-3">
          <BudgetByCategoryChart
            budgetByCategory={budgetByCategory}
            isLoading={isLoading}
          />
        </div>
        <div className="col-xl-3">
          <div className="row">
            {isLoading ? (
              <div className="col-12">
                <div className="card card-height-100">
                  <div className="card-body d-flex justify-content-center align-items-center">
                    <LoaderView />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Budget Categories"
                    percentage={0}
                    total={totalBudgetCategories}
                    icon="bx bx-wallet text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Budget Categories Left"
                    percentage={0}
                    total={totalBudgetCategoriesLeft}
                    icon="bx bx-transfer text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Total Budget Categories Spent"
                    percentage={0}
                    total={totalBudgetCategoriesSpent}
                    icon="bx bx-receipt text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Budget By Requestor"
                    percentage={0}
                    total={totalBudgetCategoryByRequestor}
                    icon="bx bx-receipt text-primary"
                  />
                </div>
                <div className="col-md-6">
                  <BasicStatCard
                    title="Budget By Pal"
                    percentage={0}
                    total={totalBudgetCategoryByPal}
                    icon="bx bx-user text-primary"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <div className="col-12">
            <div className="card">
              <div className="card-body d-flex justify-content-center align-items-center p-4">
                <LoaderView />
              </div>
            </div>
          </div>
        ) : (
          hasValidBudgetData && (
            <BudgetCardGrid
              latestBudget={combinedUserBudgetBreakdown}
              storedUserId={localStorage.getItem("userId") || ""}
            />
          )
        )}
      </div>
    </>
  );
};

export default PalzBudgetSection;
